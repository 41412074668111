<template lang="pug">
RouterView
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>
<style lang="postcss">
:root {
  font-size: 80%;
}

html {
  @apply h-full w-full;
  box-sizing: border-box;
}

body {
  @apply h-full w-full;
}

#app {
  @apply h-full w-full text-text-default bg-background-default;
}
/* why do we need that? */
*,
*:before,
*:after {
  @apply antialiased p-0 m-0;

  box-sizing: inherit;
  font-family: "Poppins", Arial, sans-serif;
}

/*input:not([type="checkbox"]),
textarea,
select,
button {
  color: #000;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select,
button {
  cursor: pointer;
}*/

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #767676;
  border: 0px none #ffffff;
  border-radius: 100px;
}
::-webkit-scrollbar-thumb:hover {
  background: #929292;
}
::-webkit-scrollbar-thumb:active {
  background: #767676;
}
::-webkit-scrollbar-track {
  background: #474747;
  border: 0px none #ffffff;
  border-radius: 100px;
}
::-webkit-scrollbar-track:hover {
  background: #454545;
}
::-webkit-scrollbar-track:active {
  background: #454545;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.pac-container {
  padding-top: 5px;
  margin-top: -5px;
  background-color: #343434;
  border-radius: 0 0 6px 6px;
  border: none;
  box-shadow: none;
}

.pac-item {
  font-family: "Roboto", Arial, sans-serif;
  height: 40px;
  padding: 5px;
  border: none;
}

.pac-item-query {
  color: #fff;
}

.pac-matched {
  color: #ffa900;
}

.pac-item:hover {
  background-color: #242424;
}
</style>
