<template lang="pug">
.flex.flex-col
  span.no-organizer-selected(v-if="!selectedOrganizerId") Bitte wähle einen Organizer aus.
  EventTable(v-if="selectedOrganizerId", :organizer-id="selectedOrganizerId")
</template>

<script setup lang="ts">
import EventTable from "../../../../domain/Event/Table/EventTable.vue";
import { useStore } from "vuex";
import { computed } from "vue";
import { storeKey } from "../../../../infrastructure/store/store";

const store = useStore(storeKey);
const selectedOrganizerId = computed(() => store.getters.organizer);
</script>

<style lang="postcss" scoped>
.no-organizer-selected {
  @apply w-full text-center text-lg mt-5;
}
</style>
