<template lang="pug">
.container
  label.mr-4 Organizer:
  .drop-down-wrapper
    input(
      ref="organizerInput",
      v-model="input",
      :placeholder="loading ? 'Loading...' : 'Organizer'",
      @focusin="indicatorState = true",
      @input="onInput()"
    )
    .data(v-if="indicatorState")
      .data-item(
        v-for="organizer in organizers",
        :key="organizer.id"
        @click="onSelect(organizer)"
      ) {{ organizer.publicName }}
  .drop-down
    .drop-down-indicator(
      ref="dropDownIndicator",
      @click="indicatorState = !indicatorState"
    )
</template>

<script lang="ts">
import { useQuery } from "@vue/apollo-composable";
import {computed, defineComponent, ref, watch} from "vue";
import { OrganizerResult, OrganizerSelectOrganizer } from "./types";
import { GetAllOrganizer } from "./queries";
import { useStore } from "vuex";
import { storeKey } from "../../../infrastructure/store/store";

export default defineComponent({
  name: "OrganizerSelect",
  emits: ["select"],
  setup() {
    const query = useQuery<OrganizerResult>(GetAllOrganizer);

    const loading = query.loading;
    let selected = ref("loading");

    watch(loading, (current) => {
      if (!current) {
        selected.value = "placeholder";
      }
    });
    return {
      queryResult: computed(() => query.result.value?.Organizer ?? []),
      loading,
      error: query.error,
      selected: selected,
      store: useStore(storeKey),
    };
  },
  data: () => ({
    input: "",
    indicatorState: false,
  }),
  computed: {
    organizers(): OrganizerSelectOrganizer[] {
      const tokens = this.input.split(" ");

      return this.queryResult.filter((organizer) =>
        tokens.every((token) =>
          organizer.publicName.toLowerCase().includes(token.toLowerCase())
        )
      );
    },
  },
  mounted() {
    window.addEventListener("click", this.onWindowClick);
  },
  unmounted() {
    window.removeEventListener("click", this.onWindowClick);
  },
  methods: {
    onSelect(organizer: OrganizerSelectOrganizer) {
      this.input = organizer.publicName;
      this.indicatorState = false;
      this.$emit("select", organizer.id);
    },
    onInput() {
      this.indicatorState = true;
    },
    onWindowClick(event: MouseEvent) {
      if (
        event.target !== this.$refs.organizerInput &&
        event.target !== this.$refs.dropDownIndicator
      ) {
        this.indicatorState = false;
      }
    },
  },
});
</script>

<style scoped>
.container {
  @apply flex items-center;
  @apply relative;

  .drop-down-wrapper {
    @apply relative;

    & > input {
      @apply rounded h-8 px-3;

      background-color: #343434;
    }

    .data {
      @apply absolute top-8 w-full max-h-60 overflow-auto;
      @apply z-10;

      tab-index: "0";

      background-color: #343434;

      &-item {
        @apply py-2 px-4;

        tab-index: "0";

        &:hover {
          @apply bg-background-contrast;
        }
      }
    }
  }

  .drop-down {
    @apply p-2 -ml-6 -mt-1;

    &:hover {
      @apply cursor-pointer;
    }

    .drop-down-indicator {
      @apply h-2 w-2 border-r-2 border-b-2 border-background-subliminal;

      transform: rotate(45deg);
    }
  }
}
</style>
