import gql from "graphql-tag";

export const authenticatedArea_organizer = gql`
  query frontend_authenticatedArea_getOrganizer($id: uuid!) {
    Organizer_by_pk(id: $id) {
      id
      referralLink
      referralCredits
      OrganizerAccountBalance {
        balance
      }
      EventSeries {
        id
      }
    }
  }
`;

export const authenticatedArea_adminEventStatistics = gql`
  query frontend_authenticatedArea_adminEventStatistics(
    $id: uuid!
    $startOfMonth: timestamptz!
    $startOfYear: timestamptz!
  ) {
    month: Event_aggregate(
      where: { createdBy: { _eq: $id }, createdAt: { _gte: $startOfMonth } }
    ) {
      aggregate {
        count
      }
    }
    year: Event_aggregate(
      where: { createdBy: { _eq: $id }, createdAt: { _gte: $startOfYear } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const authenticatedArea_createReferralLink = gql`
  mutation frontend_authenticatedArea_createReferralLink($organizerId: uuid!) {
    createReferralLinkForOrganizer(organizerId: $organizerId) {
      referralLink
    }
  }
`;
