<template lang="pug">
button.button(
  :class="{ ...styleIdClasses }",
  :disabled="disabled"
  @click="$emit('click', $event)",
)
  slot
</template>

<script lang="ts">
import { defineComponent, toRef } from "vue";
import { STYLE_IDS_PROP_DEF, useStyleIdClasses } from "../utils/style-ids";

export default defineComponent({
  name: "UiButton",
  props: {
    disabled: { type: Boolean, required: false, default: false },
    styleIds: STYLE_IDS_PROP_DEF, // TODO: create a subclass for the button implement the general interface
  },
  emits: ["click"],
  setup(props) {
    return { styleIdClasses: useStyleIdClasses(toRef(props, "styleIds")) };
  },
});
</script>

<style scoped>
.button {
  @apply text-sm border-none rounded text-text-default;

  &:disabled {
    @apply cursor-default opacity-40;
  }
}

.style-primary {
  @apply bg-primary;
}

.style-action {
  @apply bg-action;
}

.button:not(.style-size-sm) {
  @apply py-3 px-14;
}

.style-highlight {
  @apply bg-background-highlight;
}

.style-subliminal {
  @apply bg-background-subliminal;
}

.style-size-sm {
  @apply py-3 px-5;
}

.style-primary-border {
  @apply border border-double border-primary !important;
}

.style-subliminal-border {
  @apply border border-double border-subliminal !important;
}
</style>
