import gql from "graphql-tag";

export const loadOrganizerById = gql`
  query frontend_organizer_loadOrganizerById($organizerId: uuid!) {
    Organizer_by_pk(id: $organizerId) {
      city
      eMail
      id
      name
      postalCode
      publicName
      streetName
      streetNumber
      supervisorId
      contact
      verified
      selfManaged
      OrganizerUser_aggregate {
        aggregate {
          count
        }
      }
      OrganizerUser {
        OrganizerAccount {
          id
          email
        }
      }
    }
  }
`;

export const updateOrganizerById = gql`
  mutation frontend_organizer_updateOrganizerById(
    $id: uuid!
    $name: String!
    $publicName: String!
    $eMail: String!
    $postalCode: String!
    $city: String!
    $streetName: String!
    $streetNumber: String!
    $verified: Boolean!
    $supervisorId: uuid
    $contact: String
    $selfManaged: Boolean!
  ) {
    update_Organizer_by_pk(
      pk_columns: { id: $id }
      _set: {
        city: $city
        eMail: $eMail
        name: $name
        postalCode: $postalCode
        verified: $verified
        supervisorId: $supervisorId
        streetName: $streetName
        publicName: $publicName
        streetNumber: $streetNumber
        contact: $contact
        selfManaged: $selfManaged
      }
    ) {
      id
      name
      city
      eMail
      postalCode
      verified
      supervisorId
      streetName
      publicName
      streetNumber
      contact
      selfManaged
    }
  }
`;

export const deleteOrganizerById = gql`
  mutation frontend_organizer_deleteOrganizerById($organizerId: uuid!) {
    delete_Organizer_by_pk(id: $organizerId) {
      id
    }
  }
`;

export const organizer_loadAdminUsers = gql`
  query frontend_organizer_loadAdminUsers {
    AdminUser {
      id
      firstName
      lastName
    }
  }
`;

export const inviteOrganizerMutation = gql`
  mutation frontend_organizer_invite($organizerId: uuid!, $email: String!) {
    inviteOrganizer(organizerId: $organizerId, email: $email) {
      affected_rows
    }
  }
`;

export const resendPasswordMail = gql`
  mutation frontend_organizer_update_resend_password_mail($organizerId: uuid!) {
    resendOrganizerAccountRegistrationMail(organizerId: $organizerId) {
      id
    }
  }
`;
