import gql from "graphql-tag";

export const adminOverview_loadAdmins = gql`
  query frontend_adminOverview_loadAdmins(
    $orderBy: [AdminUser_order_by!]!
    $limit: Int!
    $offset: Int!
  ) {
    AdminUser(order_by: $orderBy, limit: $limit, offset: $offset) {
      id
      firstName
      lastName
      email
      createdAt
      updatedAt
      Role {
        role
      }
    }
    AdminUser_aggregate {
      aggregate {
        count
      }
    }
  }
`;
