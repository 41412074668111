import infoIcon from "../../assets/icons/circle-info.svg";
import warningIcon from "../../assets/icons/triangle-exclamation-solid.svg";

type messageIconsType = {
  [key: string]: string;
};

export const messageIcons: messageIconsType = {
  info: infoIcon,
  warning: warningIcon,
};
