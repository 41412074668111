<template lang="pug">
.input
  .component-wrapper(:class="{ 'animate-pulse': skeleton && loading }")
    input.component(
      v-if="tag === 'input'",
      :type="type",
      some-change="2kliuhdsf"
      :placeholder="placeholder",
      :value="inputValue",
      :class="{ ...styleIdClasses, 'border-background-highlight': errorMessage, 'border-background-contrast': !errorMessage, 'cursor-not-allowed': disabled }",
      :disabled="disabled",
      :name="fieldName",
      :readonly="readonly",
      v-bind="$attrs",
      @input="onInput",
      @blur="onBlur"
    )
    textarea.component(
      v-if="tag === 'textarea'",
      :name="fieldName",
      :value="inputValue",
      :disabled="disabled",
      v-bind="$attrs",
      :class="{ ...styleIdClasses, 'border-background-highlight': errorMessage, 'border-background-contrast': !errorMessage, 'cursor-not-allowed': disabled }",
      @blur="onBlur"
      @input="onInput",
    )
    .number-counter(v-if="counter")
      p(v-if="inputValue") {{ inputValue.length }} / {{ maxLength }}
      p(v-else) 0 / {{ maxLength }}
  LoadingAnimation.loading-animation(
    v-if="loading && !skeleton",
    :is-checked="false",
    :is-primary="false"
  )
  .error-indicator(v-if="errorMessage")
    .indicator !
    .error-message {{ errorMessage }}
  .icon-container(v-if="hasIconSlot")
    .icon
      slot(name="icon")
</template>

<script lang="ts">
import {defineComponent, onBeforeUpdate, Ref, ref, toRef, toRefs, watch} from "vue";
import { useField } from "vee-validate";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation.vue";
import { STYLE_IDS_PROP_DEF, useStyleIdClasses } from "../utils/style-ids";

export default defineComponent({
  name: "UiTextInput",
  components: { LoadingAnimation },
  props: {
    tag: { type: String, required: false, default: "input" },
    type: { type: String, required: false, default: "text" },
    placeholder: { type: String, required: false, default: "" },
    loading: { type: Boolean, required: false, default: false },
    modelValue: { type: String, required: false, default: "" },
    disabled: { type: Boolean, required: false, default: false },
    readonly: { type: Boolean, required: false, default: false },
    name: { type: String, required: false, default: "default" },
    counter: { type: Boolean, required: false, default: false },
    validationRules: { type: Object, required: false, default: () => ({}) },
    maxLength: { type: Number, required: false, default: 200 },
    skeleton: { type: Boolean, required: false, default: false },
    styleIds: STYLE_IDS_PROP_DEF,
  },
  emits: ["update:modelValue"],
  setup(props, { slots }) {
    const { validationRules, name, modelValue } = toRefs(props);

    let returnValue = {
      styleIdClasses: useStyleIdClasses(toRef(props, "styleIds")),
      fieldName: name,
      errorMessage: ref("") as Ref<string | undefined>,
      inputValue: ref(""),
    };

    if (Object.keys(validationRules.value).length > 0) {
      const { errorMessage, value: fieldValue } = useField(
        name,
        validationRules,
        {
          initialValue: modelValue,
        }
      );

      returnValue.errorMessage = errorMessage;
      returnValue.inputValue = fieldValue;
    } else {
      returnValue.errorMessage = ref(undefined);
      returnValue.inputValue = modelValue;
    }

    watch(modelValue, (current) => {
      // we need to handle different cases here
      // if we have validation rules the inputValue is a computed
      // ref, if we do not have validation rules it is just a proxy
      if (Object.keys(validationRules.value).length > 0) {
        returnValue.inputValue.value = current;
      } else {
        returnValue.inputValue = ref(current);
      }
    });

    const hasIconSlot = ref(false);
    onBeforeUpdate(() => {
      if (slots.icon) {
        hasIconSlot.value = true;
      }
    })

    return {
      ...returnValue,
      hasIconSlot,
    };
  },
  methods: {
    // TODO: fix
    onInput(payload: Event) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (payload.target.value.length <= this.maxLength) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$emit("update:modelValue", payload.target.value);
      } else {
        // TODO: evaluate
        // question here is if we want to permit further writing into the text field
        // if the text reaches the specified length or do we just highlight it red
        // and disable the form execution
        // this.inputValue = this.inputValue.substring(0, this.maxLength);
      }
    },
    onBlur() {
      // Make sure that the text value is trimmed when the focus of the input field is lost
      this.$emit("update:modelValue", this.inputValue.trim());
    },
  },
});
</script>

<style lang="postcss" scoped>
.input {
  @apply flex justify-start relative flex-grow;

  .component-wrapper {
    @apply flex flex-grow flex-col relative;

    & > textarea {
      min-height: 10rem;
      max-height: 20rem;
    }

    .component {
      @apply py-2 px-3;
      @apply text-text-default text-sm bg-background-input;
      @apply flex-grow;
      flex: 100;

      &:focus {
        @apply outline-none;
        background-size: 100% 2px, 100% 1px;
      }
    }

    .number-counter {
      @apply flex justify-end items-center;
      @apply mt-0.5;
      @apply text-sm;
    }
  }

  .loading-animation {
    @apply absolute top-2 right-2;
    flex: 1;
  }

  .error-indicator {
    @apply flex justify-center items-center relative;

    .indicator {
      @apply h-5 w-5 bg-background-highlight;
      @apply rounded-xl;
      @apply flex justify-center items-center;

      /* todo: find better way to centralize vertically in parent */
      @apply absolute right-2 top-2;

      &:hover {
        /* next div sibling */
        & + div {
          @apply block;
        }
      }
    }

    .error-message {
      @apply absolute -top-9 right-0;
      @apply hidden;
      @apply w-60 px-4 py-1;
      @apply bg-background-highlight text-background-bright;
      @apply rounded-lg;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  /* color of bg-background-contrast is used here */
  -webkit-box-shadow: 0 0 0 30px #171717 inset;
}

.style-rounded-border {
  @apply rounded-md;
}

.style-subliminal {
  @apply bg-background-subliminal;
}

.style-hover-primary {
  @apply border;
  &:focus,
  &:hover {
    @apply border-primary;
  }
}

/* TODO: rename */
.style-login {
  &:focus {
    @apply outline-none;
    background-size: 100% 2px, 100% 1px;
  }

  @apply bg-background-contrast !important;
  /* how to translate the fixed width? */
  /* width: 300px; */

  /*
   *  this is a special animation which is not used anywhere else
   *  so we will just use plain css here to avoid the overhead to introduce
   *  this animation to tailwind
   */
  background-size: 0 2px, 100% 1px;
  background-image: linear-gradient(
      to bottom,
      theme("colors.primary"),
      theme("colors.primary")
    ),
    linear-gradient(to bottom, white, white);
  background-position: 50% 100%, 50% 100%;
  background-repeat: no-repeat;
  transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
}

.icon-container {
  @apply ml-0;
  @apply flex justify-center items-center;

  .icon {
    @apply ml-2 p-1;
  }
}
</style>
