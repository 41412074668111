<template lang="pug">
.flex
  EventTable(:eventSeriesId="eventSeriesId", v-if="eventSeriesId")
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { validate } from "uuid";
import EventTable from "../../Event/Table/EventTable.vue";

export default defineComponent({
  name: "EventSeriesOverview",
  components: { EventTable },
  computed: {
    // TODO: what is the value of this?
    eventSeriesId() {
      const id = this.$route.params.id as string;
      if (validate(id)) {
        return id;
      } else {
        return "";
      }
    },
  },
});
</script>

<style scoped></style>
