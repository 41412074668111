<template lang="pug">
.container
  .flex.justify-between.items-center
    .menu
      p(
        :class="{ 'text-primary': activeNavigationItem === 'carousel' }",
        @click="activeNavigationItem = 'carousel'"
      ) Carousel
      p(
        :class="{ 'text-primary': activeNavigationItem === 'pipeline' }",
        @click="activeNavigationItem = 'pipeline'"
      ) Pipeline
    .flex.items-center.gap-x-10
      UiTextInput(
        v-if="activeNavigationItem === 'pipeline'",
        placeholder="Suche",
        style-ids="rounded-border hover-primary",
        @update:model-value="onSearchInput"
      )
  .content
    .carousel(v-if="activeNavigationItem === 'carousel'")
      .left
        ScrapedEventForm(
          v-model="event",
          :loading="loading",
          @on-submit="onSubmit"
        )
      .right
        EventPreviewArea(:event="event", :loading="loading")
    .pipeline(v-if="activeNavigationItem === 'pipeline'")
      UiTable(
        :data="scrapedEvents",
        :loading="loading",
        :total="total",
        :paginated="true",
        :current-page="page",
        :rows-per-page="rowsPerPage",
        @update:rows-per-page="rowsPerPage = $event",
        @page-change="page = $event",
        @cell-click="onTableCellClick",
        @sort="onSort"
      )
        UiTableColumn(
          v-slot="props",
          label="Event Name",
          position="left",
          :sortable="true",
          field="title"
        ) {{ props.row.title }}

        UiTableColumn(
          v-slot="props",
          label="Event Series",
          position="left",
          :sortable="false",
          field="test"
        ) {{ props.row.EventSeries.name }}

        UiTableColumn(
          v-slot="props",
          label="Organizer",
          position="left",
          :sortable="false",
          field="test"
        ) {{ props.row.Organizer.name }}

        UiTableColumn(
          v-slot="props",
          label="Date",
          position="left",
          :sortable="true",
          field="startTime"
        ) {{ new Date(props.row.startTime).toLocaleString() }}
</template>

<script lang="ts">
import EventPreviewArea from "../../../../../domain/Event/Preview/EventPreviewArea.vue";
import UiTable from "../../../../../ui/Table/UiTable.vue";
import UiTableColumn from "../../../../../ui/Table/UiTableColumn.vue";
import { ScraperCreateEvent, ScraperGetNextEventQuery } from "./queries";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { computed, defineComponent, ref, watch } from "vue";
import ScrapedEventForm from "../../../../../domain/ScrapedEvent/Form/ScrapedEventForm.vue";
import useGoogleAPI from "../../../../../composables/useGoogleAPI";
import UiTextInput from "../../../../../ui/TextInput/UiTextInput.vue";

export interface ScrapedEvent {
  id: string;
  title: string;
  primaryTagId: string;
  secondaryTags: { id: string; name: string }[];
  description: string;
  location: number[];
  locationAlias: string;
  cover?: string;
  url: string;
  startTime: string;
  endTime?: string;
  image: string;
  ticketLink?: string;
  old: any;
  Organizer: {
    id: string;
    name: string;
    EventSeries: {
      id: string;
      name: string;
    }[];
  };
  EventSeries: {
    id: string;
    name: string;
  };
  eventSeriesId: string;
}

export default defineComponent({
  name: "ScraperOverview",
  components: {
    UiTextInput,
    ScrapedEventForm,
    UiTableColumn,
    UiTable,
    EventPreviewArea,
  },
  setup() {
    const page = ref(1);
    const limit = ref(10);
    const search = ref("");
    const orderBy = ref({
      startTime: "asc",
    });

    const { result, loading, error, refetch } = useQuery<{
      getNextScrapedEvents: {
        total: number;
        event: ScrapedEvent[];
      };
    }>(
      ScraperGetNextEventQuery,
      () => ({
        offset: limit.value * (page.value - 1),
        limit: limit.value,
        // orderBy: orderBy.value,
        // search: search.value,
      }),
      () => ({
        fetchPolicy: "cache-and-network",
      }),
    );

    const mutate = useMutation(ScraperCreateEvent).mutate;
    const activeNavigationItem = ref<"carousel" | "pipeline">("carousel");
    const { maps } = useGoogleAPI();

    // we need a default event for the inputs to have a
    // v-model ref value
    const event = ref<ScrapedEvent>({
      id: "",
      title: "",
      primaryTagId: "",
      secondaryTags: [],
      description: "",
      location: [],
      locationAlias: "",
      url: "",
      startTime: "",
      cover: "",
      endTime: undefined,
      ticketLink: undefined,
      image: "",
      old: undefined,
      EventSeries: {
        id: "",
        name: "",
      },
      eventSeriesId: "",
      Organizer: {
        id: "",
        name: "",
        EventSeries: [],
      },
    });

    const resetEvent = () => {
      event.value = {
        id: "",
        title: "",
        primaryTagId: "",
        secondaryTags: [],
        description: "",
        location: [],
        locationAlias: "",
        url: "",
        startTime: "",
        cover: "",
        endTime: undefined,
        ticketLink: undefined,
        image: "",
        old: undefined,
        EventSeries: {
          id: "",
          name: "",
        },
        eventSeriesId: "",
        Organizer: {
          id: "",
          name: "",
          EventSeries: [],
        },
      };
    };

    const nextScrapedEvent = ref<any>(null);

    watch(result, (current) => {
      nextScrapedEvent.value = current?.getNextScrapedEvents.event[0];
    });

    // as the query is executed outside of the scope of this component
    // the event object needs to be updated as soon as the query is finished
    // therefore all values from the scraped events as well as the default
    // values are applied to the event object
    watch(nextScrapedEvent, (current) => {
      if (current) {
        resetEvent();
        event.value = {
          ...event.value,
          ...current,
          eventSeriesId: current.EventSeries.id,
          /*endTime: addOffsetToDate(
            current.startTime,
            import.meta.env.DEFAULT_EVENT_DURATION as number
          ).toISOString(),*/
        };

        // resolve the location to an alias with Google Maps
        // only fetch the locationAlias if it is not already set and if coordinates are defined
        if (!event.value.locationAlias && event.value.location.length > 0) {
          maps
            .resolveCoordinates(
              event.value.location[0],
              event.value.location[1],
            )
            .then((alias) => (event.value.locationAlias = alias));
        }
      }
    });

    const onSubmit = () => {
      mutate(
        {
          id: event.value.id,
          title: event.value.title,
          primaryTagId: event.value.primaryTagId,
          secondaryTags: event.value.secondaryTags,
          description: event.value.description,
          location: {
            type: "Point",
            coordinates: event.value.location,
          },
          locationAlias: event.value.locationAlias,
          cover: event.value.cover,
          url: event.value.url,
          startTime: event.value.startTime,
          endTime: event.value.endTime,
          ticketLink: event.value.ticketLink,
          eventSeriesId: event.value.eventSeriesId,
        },
        {},
      )
        .then(() => {
          resetEvent();
          refetch();
        })
        .catch();
    };

    const onTableCellClick = (scrapedEvent: any) => {
      nextScrapedEvent.value = scrapedEvent;
      activeNavigationItem.value = "carousel";
    };

    function onSort(column, direction) {
      orderBy.value = {
        [column]: direction,
      };
    }

    let timer;
    function onSearchInput(value) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        search.value = value;
      }, 500);
    }

    return {
      scrapedEvents: computed(() => result.value?.getNextScrapedEvents.event),
      total: computed(() => result.value?.getNextScrapedEvents.total),
      event,
      loading,
      result,
      error,

      page,
      rowsPerPage: limit,
      activeNavigationItem,
      search,

      onSubmit,
      onTableCellClick,
      onSort,
      onSearchInput,
    };
  },
});
</script>

<style scoped>
.container {
  @apply flex flex-col flex-grow gap-y-4;
  @apply p-5;

  .menu {
    @apply flex gap-x-12 py-4;
    @apply text-lg;

    > p {
      @apply cursor-pointer;
    }
  }

  .content {
    .carousel {
      @apply flex justify-center items-start;

      .left {
        @apply flex-grow;
      }

      .right {
        @apply flex-grow;
      }
    }
  }
}
</style>
