<template lang="pug">
main
  .device
    .front
      .screen
        .screen__view
          slot
        .screen__front
          .screen__front-speaker
          .screen__front-camera
      .front__line
      .front__line.front__line-second
    .phoneButtons.phoneButtons-right
    .phoneButtons.phoneButtons-left
    .phoneButtons.phoneButtons-left2
    .phoneButtons.phoneButtons-left3
    .back
</template>

<script>
import { defineComponent } from "vue";

// credits to https://codepen.io/hosseinshabani/pen/RvrWRp
export default defineComponent({
  name: "IPhoneFrame",
});
</script>

<style scoped>
main {
  @apply flex flex-1 justify-center items-center flex-row-reverse;
}

.device {
  @apply flex z-10;
  width: 18em;
  height: 36em;
  transform: rotate(0deg);
}

.back {
  @apply flex flex-1 absolute top-0 right-0;
  margin: 0.2em;
  border-radius: 3em;
  z-index: 1;
  width: calc(100% - 0.6em);
  height: calc(100% - 0.6em);
}

.front {
  @apply flex flex-1 relative z-10 overflow-hidden;
  background-color: #292c2d;
  border-radius: 3em;
  margin: 0.2em;
}
.screen {
  @apply flex flex-1 relative z-10 m-1.5;
  background-color: #070808;
  border-radius: 3em;
}
.front__line {
  @apply h-2 w-full absolute;
  background: linear-gradient(to right, #515455, #454748, #515455);
  z-index: 8;
  top: 10%;

  &-second {
    top: 90%;
  }
}

.screen__view {
  @apply w-full m-2	relative;
}

.screen__front {
  @apply mt-2 w-6/12 h-7 top-0 pb-1.5 absolute z-50;
  @apply flex justify-center items-center box-border;
  background-color: #070808;
  border-radius: 0 0 0.9em 0.9em;
  right: 25%;
  z-index: 999;

  &-speaker {
    @apply h-1.5 rounded;
    background: #272727;
    width: 28%;
  }

  &-camera {
    @apply h-1.5 w-1.5 ml-2 -mr-3;
    background: #272727;
    border-radius: 50%;
  }
}

.phoneButtons {
  @apply w-4 h-24 rounded-sm absolute;
  z-index: 2;
  background: linear-gradient(to bottom, #212324, #2b2e31, #212324);

  &-right {
    @apply right-0;
    top: 30%;
  }

  &-left {
    @apply h-12 left-0;
    top: 15%;
  }

  &-left2 {
    @apply h-20 left-0;
    top: 25%;
  }

  &-left3 {
    @apply h-20 left-0;
    top: calc(25% + 6em);
  }
}
</style>
