<template lang="pug">
UiModal.event-series-modal
  template(#header)
    span.headline Für welche Veranstaltungsreihe soll das Event erstellt werden?
  template(#content)
    .list-container
      .event-series-item(
        v-for="eventSeries in eventSeriesList",
        :key="eventSeries.id"
        @click="eventSeriesSelected(eventSeries.id)"
      )
        EventSeriesLogo(:event-series-id="eventSeries.id")
        span.event-series-item-description {{ eventSeries.name }}
      .event-series-item.event-series-item-dashed(
        @click="newEventSeriesClicked"
      )
        span.create-event-series-symbol +
        span.create-event-series-description Veranstaltungsreihe hinzufügen
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import UiModal from "../../../ui/Modal/UiModal.vue";
import { useQuery } from "@vue/apollo-composable";
import { EventSeriesDataResult } from "../../../infrastructure/store/types";
import { authenticatedAreaFetchEventSeries } from "../../../infrastructure/store/states/queries";
import store from "../../../infrastructure/store/store";
import EventSeriesLogo from "../Logo/EventSeriesLogo.vue";
import { useToast } from "vue-toastification";
export default defineComponent({
  name: "SelectOrCreateEventSeriesModal",
  components: { UiModal, EventSeriesLogo },
  emits: ["actionClicked"],
  setup() {
    const organizerId = ref("");
    const activeOrganizer = ref(store.getters.organizer);
    const toast = useToast();

    organizerId.value = activeOrganizer.value;

    const { result } = useQuery<EventSeriesDataResult>(
      authenticatedAreaFetchEventSeries,
      () => ({
        id: organizerId.value,
      })
    );

    return {
      eventSeriesList: computed(() => result.value?.Organizer_by_pk ?? []),
      toast,
    };
  },
  methods: {
    eventSeriesSelected(eventSeriesId: string) {
      this.$router
        .push({ path: "/event-series/" + eventSeriesId + "/events/create" })
        .catch(() => this.toast("Fehler beim Navigieren"));
      this.$emit("actionClicked");
    },
    newEventSeriesClicked() {
      this.$router
        .push({ path: "/event-series/create" })
        .catch(() => this.toast("Fehler beim Navigieren"));
      this.$emit("actionClicked");
    },
  },
});
</script>

<style scoped>
.event-series-modal:deep .modal {
  width: 48.75rem;
  min-height: 28rem;
  max-height: 36rem;
  @apply p-0;
}

.event-series-modal:deep .modal-body {
  @apply w-full h-full m-0 p-6 overflow-y-auto flex-col;
}
/* Required to display bottom padding when the modal-body is scrollable */
.event-series-modal:deep .modal-body::after {
  flex: 0 0 0;
  content: "";
}

.list-container {
  @apply flex flex-wrap gap-7 w-full;
}

.event-series-item {
  @apply flex flex-col items-center justify-center p-2.5 text-center;
  @apply cursor-pointer rounded-2xl border-background-subliminal;
  border-width: 1px;
  @apply w-40 h-40;
  &:hover {
    @apply border-primary;
  }

  &-dashed {
    @apply border-dashed;
  }
  &-description {
    @apply text-xs mt-3;
  }
}

.create-event-series-symbol {
  @apply text-5xl;
}
.create-event-series-description {
  @apply text-xs;
}

.headline {
  @apply text-lg font-bold;
}
</style>
