<template lang="pug">
.secondary-tag(
  :id="id",
  :key="id",
  @click="$emit('click')",
  :class="{ clickable: isClickable }"
) {{ name }}
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SecondaryTagVisualization",
  props: {
    name: { type: String, required: true, default: "123" },
    id: { type: String, required: false, default: undefined },
    isClickable: { type: Boolean, required: false, default: true },
  },
  emits: ["click"],
});
</script>

<style scoped>
.secondary-tag {
  @apply flex justify-center items-center;
  @apply font-bold text-background-bright bg-primary text-xs;
  @apply px-3 py-1 rounded-2xl mx-1 mb-3;
}

.clickable:hover {
  @apply bg-background-highlight cursor-pointer;
}
</style>
