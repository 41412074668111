import gql from "graphql-tag";

export const event_create = gql`
  mutation frontend_createEvent_create(
    $cover: String!
    $description: String!
    $endTime: timestamptz
    $eventSeriesId: uuid!
    $location: geography!
    $locationAlias: String!
    $primaryTagId: uuid!
    $secondaryTags: [CreatePublicEventSecondaryTag!]!
    $startTime: timestamptz!
    $ticketLink: String
    $title: String!
  ) {
    createPublicEvent(
      event: {
        cover: $cover
        description: $description
        eventSeriesId: $eventSeriesId
        location: $location
        locationAlias: $locationAlias
        primaryTagId: $primaryTagId
        secondaryTags: $secondaryTags
        startTime: $startTime
        endTime: $endTime
        title: $title
        ticketLink: $ticketLink
      }
    ) {
      id
      eventSeriesId
      coverId
      primaryTagId
      title
      description
      location
      locationAlias
      startTime
      endTime
      ticketLink
      cancelled
      createdAt
      updatedAt
      SecondaryTags {
        SecondaryTag {
          id
          name
        }
      }
    }
  }
`;

export const get_event_series_name = gql`
  query frontend_createEvent_getEventSeriesName($id: uuid!) {
    EventSeries_by_pk(id: $id) {
      name
    }
  }
`;
