import { onBeforeRouteLeave } from "vue-router";
import { Ref } from "vue";

export function useBeforeRouteLeaveAlert(hasChanges?: Ref<boolean>) {
  onBeforeRouteLeave((to, from, next) => {
    if (hasConfirmedToLeaveCurrentRoute()) {
      next();
    } else {
      next(false);
    }
  });

  const confirmToLeaveRouteAlert = () => {
    return window.confirm(
      "Seite wirklich verlassen? Vorgenommene Änderungen werden nicht gespeichert."
    );
  };

  const hasConfirmedToLeaveCurrentRoute = () => {
    let showConfirmToLeaveRouteAlert = true;
    if (hasChanges && !hasChanges.value) {
      showConfirmToLeaveRouteAlert = false;
    }
    if (showConfirmToLeaveRouteAlert) {
      return confirmToLeaveRouteAlert();
    } else {
      return true;
    }
  };
}
