import axios from "axios";

export const loadImageAsBase64String = async (url: string) => {
  const imageBlob = await axios.get(url, {
    responseType: "blob",
  });

  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(
        (reader.result as string).replace(
          "application/octet-stream",
          "image/webp"
        )
      );
    };
    reader.readAsDataURL(imageBlob.data);
  });
};
