<template lang="pug">
.loading
  img.logo(src="../../assets/logos/logo.png", alt="Pingr")
  .flex.flex-row.items-center.justify-center
    LoadingAnimation(:is-primary="false", :is-checked="false")
    p.ml-5 Loading ...
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoadingAnimation from "../../ui/LoadingAnimation/LoadingAnimation.vue";

export default defineComponent({
  name: "InitialLoadingPage",
  components: { LoadingAnimation },
});
</script>

<style lang="postcss" scoped>
.loading {
  @apply h-full w-full;
  @apply flex flex-col items-center justify-center;
}
.logo {
  @apply max-h-36 w-auto h-auto mb-10;
}
</style>
