import gql from "graphql-tag";

export const uiDropZone_processImage = gql`
  mutation frontend_uiDropZone_processImage($data: String!, $type: ImageType!) {
    processImage(image: { data: $data, type: $type }) {
      data
    }
  }
`;

export const eventSeriesForm_create = gql`
  mutation frontend_createEventSeries_create(
    $name: String!
    $organizerId: uuid!
    $description: String!
    $logo: String!
    $banner: String!
    $facebookLink: String
    $websiteLink: String
    $instagramLink: String
    $images: [GalleryImageInput!]
  ) {
    createEventSeries(
      eventSeries: {
        name: $name
        organizerId: $organizerId
        description: $description
        facebookLink: $facebookLink
        websiteLink: $websiteLink
        instagramLink: $instagramLink
        logo: $logo
        banner: $banner
        images: $images
      }
    ) {
      id
    }
  }
`;

export const eventSeriesForm_loadEventSeriesInitial = gql`
  query frontend_eventSeriesForm_loadEventSeriesInitial($id: uuid!) {
    EventSeries_by_pk(id: $id) {
      id
      name
      description
      facebookLink
      instagramLink
      websiteLink
      logoId
      bannerId
      GalleryImages {
        imageId
      }
    }
  }
`;

export const eventSeriesForm_getEventSeriesImageUrl = gql`
  query frontend_eventSeriesForm_getEventSeriesImageUrl(
    $eventSeriesId: uuid!
    $imageId: uuid!
  ) {
    getEventSeriesImageUrl(
      data: { imageId: $imageId, eventSeriesId: $eventSeriesId }
    )
  }
`;

export const eventSeriesForm_loadEventSeriesLogo = gql`
  query frontend_eventSeriesForm_loadEventSeriesLogo($eventSeriesId: uuid!) {
    loadLogo(logo: { eventSeriesId: $eventSeriesId }) {
      url
    }
  }
`;

export const eventSeriesForm_loadEventSeriesBanner = gql`
  query frontend_eventSeriesForm_getEventSeriesBannerUrl(
    $eventSeriesId: uuid!
  ) {
    getEventSeriesBannerUrl(eventSeriesId: $eventSeriesId) {
      url
    }
  }
`;
