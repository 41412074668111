<template lang="pug">
.flex.mx-4.my-4.relative
  .absolute.right-0
    UiTextInput(
      placeholder="Suche nach Veranstalter",
      style-ids="hover-primary rounded-border"
      @input="onSearchInput",
    )
  //- TODO: do not use main tag!
  .organizer-table
    UiTable(
      :data="organizerList",
      :loading="loading",
      :total="count",
      :default-sort="['publicName', 'asc']",
      :current-page="paginationOffset + 1",
      :rows-per-page="rowsPerPage",
      @page-change="pageChange",
      @update:rows-per-page="rowsPerPageChanged",
      @sort="setSortDirection",
      @click="(organizer: any) => updateOrganizerClicked(organizer.id)"
    )
      template(#caption)
        span.caption {{ title }}

      UiTableColumn(
        v-slot="props",
        label="Veranstalter Name",
        position="left",
        :sortable="true",
        field="publicName"
      ) {{ props.row.publicName }}

      UiTableColumn(
        v-slot="props",
        label="Zuständiger Mitarbeiter",
        position="left",
        :sortable="true",
        field="Supervisor.lastName"
      ) {{ props.row.Supervisor ? `${props.row.Supervisor.firstName} ${props.row.Supervisor.lastName}` : "-" }}

      UiTableColumn(
        v-slot="props",
        label="Anzahl Eventreihen",
        position="left",
        :sortable="true",
        field="EventSeries_aggregate.count"
      ) {{ props.row.EventSeries_aggregate.aggregate.count }}

      UiTableColumn(
        v-slot="props"
        label="Anzahl Upcoming Events",
        position="left",
      ) {{ getUpcomingEventsCount(props.row) }}

      UiTableColumn(
        v-slot="props",
        label="Letzter Login",
        position="left",
        :sortable="false"
      ) {{ props.row.lastLogin }}

      UiTableColumn(
        v-slot="props",
        label="Verified",
        position="left",
        :sortable="true",
        field="verified"
      ) {{ props.row.verified }}

      UiTableColumn(position="centered")
        template.flex.items-center(#default)
        template(#header)
          button.add-organizer-button(@click="addOrganizerClicked") + Add Organizer
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { useToast } from "vue-toastification";
import { loadOrganizerLastLoginTime, loadOrganizerOverview } from "./queries";
import {
  OrganizerOverviewDto,
  OrganizerOverviewLastLoginResponse,
  OrganizerOverviewQueryResponse,
} from "./types";
import { formatDate } from "../../../utils/dateFormatter";
import UiTable from "../../../ui/Table/UiTable.vue";
import UiTableColumn from "../../../ui/Table/UiTableColumn.vue";
import UiTextInput from "../../../ui/TextInput/UiTextInput.vue";
import { debounce } from "lodash";

export default defineComponent({
  name: "OrganizerOverview",
  components: {
    UiTextInput,
    UiTable,
    UiTableColumn,
  },
  setup() {
    let orderBy = ref<{ [key: string]: string }>({ publicName: "asc" });
    const offset = ref(0);
    const rowsPerPage = ref(10);
    const lastLoginsQueryEnabled = ref(false);
    const organizerId = ref([] as string[]);
    const time = new Date();
    const searchTerm = ref("");

    const { result, loading, error, onResult } =
      useQuery<OrganizerOverviewQueryResponse>(
        loadOrganizerOverview,
        () => {
          const variables = {
            timeNow: time,
            orderBy: orderBy.value,
            limit: rowsPerPage.value,
            offset: offset.value * rowsPerPage.value,
          };

          if (searchTerm.value) {
            return {
              ...variables,
              where: {
                _or: [
                  { eMail: { _like: `%${searchTerm.value}%` } },
                  { publicName: { _like: `%${searchTerm.value}%` } },
                  {
                    Supervisor: {
                      _or: {
                        firstName: { _like: `%${searchTerm.value}%` },
                        lastName: { _like: `%${searchTerm.value}%` },
                      },
                    },
                  },
                  { name: { _like: `%${searchTerm.value}%` } },
                  { contact: { _like: `%${searchTerm.value}%` } },
                ],
              },
            };
          }

          return variables;
        },
        () => ({
          fetchPolicy: "cache-and-network",
          debounce: 10000,
        }),
      );

    onResult((result) => {
      if (!result.loading) {
        organizerId.value = result.data.Organizer.map(
          (organizer) => organizer.id,
        );
        lastLoginsQueryEnabled.value = true;
      }
    });

    const { result: lastLoginsResult, loading: lastLoginsLoading } =
      useQuery<OrganizerOverviewLastLoginResponse>(
        loadOrganizerLastLoginTime,
        () => ({
          ids: organizerId.value,
        }),
        () => ({
          enabled: lastLoginsQueryEnabled.value,
          fetchPolicy: "cache-and-network",
        }),
      );

    const setSortDirection = (field: string, sortDirection: any) => {
      const [last, ...paths] = field.split(".").reverse();
      orderBy.value = paths.reduce((acc, el) => ({ [el]: acc }), {
        [last]: sortDirection,
      });
    };

    const setOffset = (newOffset: number) => {
      offset.value = newOffset;
    };

    const setRowsPerPage = (newRowsPerPage: number) => {
      rowsPerPage.value = newRowsPerPage;
    };

    const organizerList = computed(() => {
      return result.value?.Organizer.map((organizer) => {
        const lastLogin =
          lastLoginsResult.value?.getOrganizerLastLogin.lastLogins.filter(
            (ll) => ll.id === organizer.id,
          )[0]?.lastLogin;

        return {
          ...organizer,
          lastLogin: lastLogin
            ? formatDate(lastLogin, "dd.MM.yyyy HH:mm") + " Uhr"
            : "Keine Daten vorhanden",
        };
      });
    });

    // debounce the search input
    const onSearchInput = debounce((event: any) => {
      searchTerm.value = event.target.value;
    }, 300);

    return {
      organizerList,
      onSearchInput,
      count: computed(
        () => result.value?.Organizer_aggregate.aggregate.count || 0,
      ),
      loading: loading || lastLoginsLoading,
      lastLoginsLoading,
      error: error,
      paginationOffset: offset,
      rowsPerPage,
      setSortDirection,
      lastLogins: computed(
        () => lastLoginsResult.value?.getOrganizerLastLogin.lastLogins || [],
      ),
      setOffset,
      setRowsPerPage,
      toast: useToast(),
      searchTerm,
    };
  },
  data() {
    return {
      title: "ALL ORGANIZER",
      sortDirection: ["publicName", "asc"],
    };
  },
  methods: {
    pageChange(pageNumber: number) {
      this.setOffset(pageNumber - 1);
    },
    updateOrganizerClicked(organizerId: string) {
      this.$router.push("/organizer/update/" + organizerId);
    },
    addOrganizerClicked() {
      // TODO: named routes!!!
      this.$router
        .push({ path: "/organizer/create" })
        // TODO: log error to sentry
        .catch((err) => this.toast("Fehler beim Navigieren"));
    },
    rowsPerPageChanged(rowsPerPage: number) {
      this.setRowsPerPage(rowsPerPage);
    },
    parseDate(date: string) {
      return formatDate(date, "dd.MM.yyyy HH:mm");
    },
    getUpcomingEventsCount(organizer: OrganizerOverviewDto) {
      let upcomingEventsCount = 0;
      if (organizer.EventSeries.length > 0) {
        for (const eventSeries of organizer.EventSeries) {
          upcomingEventsCount += eventSeries.Events_aggregate.aggregate.count;
        }
      }
      return upcomingEventsCount;
    },
  },
});
</script>

<style lang="postcss" scoped>
.caption {
  @apply float-left text-base font-black mb-5;
}
.organizer-table {
  @apply flex w-full;
}

.add-organizer-button {
  @apply text-primary font-bold;
}

.button-context {
  @apply font-black text-xl;
}
.cancel-event-item {
  @apply text-background-highlight;
}
</style>
