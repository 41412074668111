import gql from "graphql-tag";

export const loadPublicEventById = gql`
  query frontend_event_loadPublicEventById($eventId: uuid!) {
    Event_by_pk(id: $eventId) {
      title
      startTime
      primaryTagId
      eventSeriesId
      locationAlias
      location
      cancelled
      id
      endTime
      description
      coverId
      ticketLink
      SecondaryTags {
        SecondaryTag {
          id
          name
        }
      }
      EventSeries {
        id
        name
      }
    }
  }
`;

export const eventForm_loadEventCover = gql`
  query frontend_eventForm_getEventCoverUrl($eventId: uuid!) {
    getPublicEventCoverUrl(eventId: $eventId) {
      url
    }
  }
`;

// TODO: scoped naming
export const event_update = gql`
  mutation frontend_updateEvent_update(
    $description: String!
    $startTime: timestamptz!
    $endTime: timestamptz!
    $cover: String
    $id: uuid!
    $location: geography!
    $locationAlias: String!
    $primaryTagId: uuid!
    $secondaryTagIds: [uuid!]!
    $ticketLink: String
    $title: String!
  ) {
    updatePublicEvent(
      where: { id: $id }
      _set: {
        cover: $cover
        description: $description
        location: $location
        locationAlias: $locationAlias
        primaryTagId: $primaryTagId
        secondaryTags: $secondaryTagIds
        startTime: $startTime
        endTime: $endTime
        title: $title
        ticketLink: $ticketLink
      }
    ) {
      id
      eventSeriesId
      primaryTagId
      coverId
      title
      description
      startTime
      endTime
      location
      locationAlias
      cancelled
      ticketLink
      updatedAt
      createdAt
      SecondaryTags {
        SecondaryTag {
          id
          name
        }
      }
    }
  }
`;

export const eventForm_cancelPublicEvent = gql`
  mutation frontend_eventForm_cancelPublicEvent($id: uuid!) {
    cancelPublicEvent(id: $id) {
      id
      cancelled
    }
  }
`;
