<template lang="pug">
.drop-zone(
  ref="dropZone"
  :class="{ 'border-background-highlight': hasErrors || showInactiveErrorMessage || showTemporalError, 'border-background-subliminal': !hasErrors && !showInactiveErrorMessage && !showTemporalError, breathing: breathe, 'cursor-not-allowed': disabled, 'cursor-pointer': success || hasErrors }",
  @drop="onDrop($event)",
  @dragover="onDragOver($event)",
  @dragenter="onDragEnter",
  @dragleave="onDragLeave",
  @click="(success || hasErrors) && onDropZoneClick()",
)
  input.hidden(
    v-for="(file, i) of files",
    :ref="(el) => (fileInputs[index] = el)",
    :key="i",
    :name="name.length > 0 ? name : String(index)",
    type="file",
    :accept="accepts",
    @change="onFileSelected"
  )
  slot(
    v-if="!loading && !success && !hasErrors && !showInactiveErrorMessage && !showTemporalError"
    name="default",
  )
    span Bilder per Drag & Drop hochladen
    .call-to-action(
      :tabindex="disabled ? -1 : 0",
      :class="disabled ? ['cursor-not-allowed'] : ['cursor-pointer']"
      @click="onDropZoneClick",
      @keyup.enter="onDropZoneClick",
      @keyup.space="onDropZoneClick",
    ) oder vom Computer auswählen

  .errors(v-if="hasErrors || showInactiveErrorMessage || showTemporalError")
    .error-message(v-if="showTemporalError") {{ temporalErrorMessage }}

    slot(
      v-if="showInactiveErrorMessage && !loading"
      name="inactiveErrorMessage",
    )
      .error-message Maximale Anzahl an Elementen erreicht

    .error-message(v-if="hasErrors && !showTemporalError") {{ nextError }}

  .loading(
    v-if="(loading || success) && !showInactiveErrorMessage && !showTemporalError && !hasErrors"
  )
    p(v-if="success") {{ fileName }}
    LoadingAnimation.loading-animation(
      :is-primary="false",
      :is-checked="success"
    )
</template>

<script lang="ts">
import {
  ComponentPublicInstance,
  computed,
  defineComponent,
  PropType,
  Ref,
  ref,
  toRefs,
  warn,
  watch,
} from "vue";
import { UiDropZoneProcessImageResponse } from "./types";
import { useField } from "vee-validate";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation.vue";
import { uiDropZone_processImage } from "./queries";
import { useMutation } from "@vue/apollo-composable";

export default defineComponent({
  name: "UiDropZone",
  components: { LoadingAnimation },
  props: {
    type: {
      type: String as PropType<
        | "EVENT_SERIES_BANNER"
        | "EVENT_SERIES_LOGO"
        | "EVENT_SERIES_IMAGE"
        | "EVENT_COVER"
      >,
      required: true,
    },
    accepts: {
      type: String,
      required: false,
      default: ".jpg,.jpeg,.png",
    },
    name: { type: String, required: false, default: "" },
    label: { type: String, required: false, default: "" },
    modelValue: {
      type: [Array, String] as PropType<
        { imageId?: string; imageUri?: string }[] | string
      >,
      required: false,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    validationRules: { type: Object, required: false, default: () => ({}) },
    limit: { type: Number, required: false, default: 1 },
    minRequired: { type: Number, required: false, default: -1 },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const {
      validationRules,
      limit,
      name,
      label,
      modelValue,
      type,
      minRequired,
    } = toRefs(props);

    if (
      limit.value === 1 &&
      Object.keys(validationRules.value).length > 0 &&
      name.value === ""
    ) {
      warn(
        "Please provide inputName if you provide limit = 1 and validation rules"
      );
    }

    if (
      minRequired.value !== -1 &&
      Object.keys(validationRules.value).length > 0 &&
      Object.keys(validationRules.value).includes("required")
    ) {
      warn(
        "If the minRequired prop is specified, the required field of the validationRules prop is not considered."
      );
    }

    if (
      minRequired.value !== -1 &&
      (minRequired.value < 0 || minRequired.value > limit.value)
    ) {
      warn(
        "minRequired prop must be greater than or equal to 0 and less than or equal to the limit value."
      );
    }

    let files = ref(new Array(limit.value));

    for (let i = 0; i < limit.value; i++) {
      let fieldLabel = undefined;
      if (label.value !== "") {
        if (limit.value > 1) {
          fieldLabel = `${label.value} ${i}`;
        }
      }

      const adjustedValidationRules = { ...validationRules.value };
      delete adjustedValidationRules.required;
      if (minRequired.value !== -1) {
        if (i + 1 <= minRequired.value) {
          adjustedValidationRules.required = true;
        }
      } else {
        adjustedValidationRules.required = true;
      }

      const { errors, value, setErrors, setValue, meta } = useField(
        limit.value === 1 ? name : `${i}`,
        adjustedValidationRules,
        {
          label: fieldLabel,
        }
      );

      /** each file is holding two file attributes
       *    - droppedFile: The file which was dropped to the drop zone
       *    - processedFile: The file which was processed by the backend (webp format)
       *                     It is used to determine duplicates without uploading to the backend
       */
      files.value[i] = {
        errors,
        processedFile: value as Ref<string>,
        droppedFile: ref(""),
        fileName: "",
        setErrors,
        meta,
        setValue,
      };
    }

    const hasErrors = computed(() => {
      return (
        !loading.value && files.value.some((file) => file.errors.length > 0)
      );
    });

    const nextError = computed(() => {
      const transformed = files.value.map((file) => file.errors);
      const errors = transformed.find((error) => error.length > 0);
      return errors ? errors[errors.length - 1] : "";
    });

    const index = computed(() => {
      if (limit.value > 1) {
        for (let i = 0; i < files.value.length; i++) {
          if (!files.value[i].processedFile) return i;
        }
      }
      return limit.value - 1;
    });

    const fileName = computed(() => {
      for (let i = files.value.length - 1; i >= 0; i--) {
        if (files.value[i].fileName !== "") {
          return files.value[i].fileName;
        }
      }
      return "";
    });

    const arrayLength = computed(() => {
      return files.value.filter((file) => file.processedFile).length;
    });

    const acceptMoreImages = computed(() => {
      // ToDo: find a good method to determine that all images are set
      return limit.value > 1 ? arrayLength.value === limit.value : false;
    });

    // sync modelValue initially with files array
    if (!Array.isArray(modelValue.value) && modelValue.value) {
      files.value[0].setValue(modelValue.value);
    } else {
      for (let i = 0; i < modelValue.value.length; i++) {
        const model = modelValue.value as {
          imageId?: string;
          imageUri?: string;
        }[];
        if (model[i].imageId) {
          files.value[i].setValue(model[i].imageId);
        } else {
          files.value[i].setValue(model[i].imageUri);
        }
      }
    }

    watch(
      modelValue,
      (current, previous) => {
        if (Array.isArray(current) && Array.isArray(previous)) {
          // TODO: investigate
          if (
            current.length === 1 &&
            previous.length === 1 &&
            current[0] === "" &&
            previous[0] === ""
          )
            return;

          // only watch for the removing case
          if (previous.length > current.length) {
            // rearrange the file list (any index larger than the length of the current list indicates a removed
            // or non-existent file)
            for (let i = 0; i < files.value.length; i++) {
              if (current[i] && current[i].imageUri) {
                files.value[i].setValue(current[i].imageUri);
              } else {
                files.value[i].setValue("");
                files.value[i].processedFile = "";
                files.value[i].fileName = "";
                files.value[i].droppedFile = "";
              }
            }

            // In case the modelValue got reinitialized with less urls, all files will be deleted, because they are
            // unknown to this component. So if the amount of valid files in this component is smaller than the current
            // modelValue, sync down the model value into the component.
            let validFileCount = 0;
            for (const file of files.value) {
              if (file.processedFile) {
                validFileCount += 1;
              }
            }
            if (validFileCount < current.length) {
              for (let i = 0; i < current.length; i++) {
                files.value[i].setValue(current[i].imageUri);
              }
            }

            // sync down into component
          } else {
            for (let i = 0; i < current.length; i++) {
              files.value[i].setValue(current[i].imageUri);
            }
          }
        } else {
          files.value[0].setValue(current);
        }
      },
      {
        deep: true,
      }
    );

    const { loading, mutate, onDone } =
      useMutation<UiDropZoneProcessImageResponse>(uiDropZone_processImage, {
        variables: {
          type: type.value,
        },
      });

    const success = ref(false);

    onDone((result) => {
      files.value[index.value].setValue(result.data!.processImage.data);

      if (Array.isArray(modelValue.value)) {
        emit(
          "update:modelValue",
          files.value
            .filter((file) => file.processedFile)
            .map((file) => ({ imageUri: file.processedFile }))
        );
        success.value = true;

        // do not reset if it is the last image
        if (index.value + 1 !== limit.value) {
          // reset state after 3000 seconds if multiple files can be dropped
          setTimeout(() => {
            success.value = false;
          }, 3000);
        }
      } else {
        emit("update:modelValue", files.value[0].processedFile);
        success.value = true;
      }
    });

    return {
      processImage: mutate,
      files,
      success,
      loading,
      acceptMoreImages,
      fileName,
      hasErrors,
      nextError,
      index,
    };
  },
  data: () => ({
    breathe: false,
    counter: 0,
    fileInputs: [] as Array<ComponentPublicInstance | Element | null>,
    showInactiveErrorMessage: false,
    temporalErrorMessage: "",
    showTemporalError: false,
  }),
  methods: {
    onDrop(event: DragEvent) {
      event.preventDefault();

      this.counter = 0;
      this.breathe = false;

      if (this.loading) {
        this.showTemporalErrorMessage(
          "Bitte warten Sie bis das Bild verarbeitet wurde!",
          1500
        );
        return;
      } else if (this.acceptMoreImages) {
        this.showInactiveError();
        return;
      }

      if (event.dataTransfer && event.dataTransfer.files) {
        if (event.dataTransfer.files.length === 0) return;
        this.processFile(event.dataTransfer.files[0]);
      }
    },
    onDragOver(event: DragEvent) {
      event.preventDefault();
    },
    onDragEnter() {
      if (!this.acceptMoreImages) {
        this.counter++;
        this.breathe = true;
      }
    },
    onDragLeave() {
      if (!this.acceptMoreImages) {
        this.counter--;
        if (this.counter === 0) {
          this.breathe = false;
        }
      }
    },
    processFile(file: File) {
      const reader = new FileReader();

      reader.onerror = () => {
        this.showTemporalErrorMessage(
          "Während der Verarbeitung der Datei ist ein Fehler aufgetreten",
          2500
        );
      };

      reader.onload = () => {
        // check for duplicates
        if (this.files.some((file) => file.droppedFile === reader.result)) {
          this.showTemporalErrorMessage("Bild bereits vorhanden!");
          return;
        }

        // check data type
        let valid = false;
        for (const dataType of this.accepts.split(",")) {
          if (
            file.name.toLocaleLowerCase().includes(dataType.toLocaleLowerCase())
          ) {
            valid = true;
          }
        }

        if (!valid) {
          this.showTemporalErrorMessage("Ungültiger Datentyp", 2500);
          return;
        }

        this.files[this.index].fileName = file.name;
        this.files[this.index].droppedFile = reader.result;

        this.processImage({ data: reader.result });
      };

      reader.readAsDataURL(file);
    },
    onFileSelected(event: Event) {
      if (event && event.target) {
        const inputElement = event.target as HTMLInputElement;
        if (inputElement.files && inputElement.files.length) {
          this.processFile(inputElement.files[0]);
        }
      }
    },
    showTemporalErrorMessage(message: string, timeout?: number) {
      this.temporalErrorMessage = message;
      this.showTemporalError = true;
      setTimeout(
        () => {
          this.showTemporalError = false;
        },
        timeout ? timeout : 3000
      );
    },
    showInactiveError(timeout?: number) {
      this.showInactiveErrorMessage = true;
      setTimeout(
        () => {
          this.showInactiveErrorMessage = false;
        },
        timeout ? timeout : 3000
      );
    },
    onDropZoneClick() {
      if (this.disabled) return;
      (this.fileInputs[this.index] as HTMLInputElement).click();
    },
  },
});
</script>

<style scoped>
.drop-zone {
  @apply flex flex-col justify-center items-center w-full;
  @apply border border-dashed;
  @apply bg-background-contrast;
  @apply text-sm text-center;
  @apply px-6 py-6 rounded-md;
  @apply h-auto min-w-min w-full;

  .call-to-action {
    @apply underline text-primary font-bold;
  }

  .errors {
    @apply flex justify-center items-center;

    .error-message {
      @apply text-background-highlight text-lg;
    }
  }
  .loading {
    @apply flex justify-center items-center;

    & > p {
      @apply mr-6;
    }
    .loading-animation {
      @apply place-content-center;
    }
  }
}

.breathing {
  animation: breathing 0.5s linear infinite normal;

  @keyframes breathing {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(0.98);
    }

    100% {
      transform: scale(1);
    }
  }
}
</style>
