// import i18n from "@/infrastructure/i18n";
// initialize firebase before importing AuthListener
import { defineValidationRules } from "./utils/validations";
import { onAuthStateChanged } from "firebase/auth";
import store, { storeKey } from "./infrastructure/store/store";
import router from "./infrastructure/router";
import parseJwt from "./infrastructure/authentication/utils/parseJWT";
import Toast, { PluginOptions, POSITION } from "vue-toastification";
import { createApp } from "vue";
import apollo, { client } from "./infrastructure/api/graphql/apollo";
import App from "./application/App.vue";
import "./ui/main.css";
import "vue-toastification/dist/index.css";
import { provideApolloClient, useQuery } from "@vue/apollo-composable";
import { firebaseAuthCallback_fetchAdmin } from "./infrastructure/api/graphql/queries";
import Logger from "./utils/logger";
import FirebaseFactory from "./infrastructure/api/firebase/firebase.factory";
import * as Sentry from "@sentry/vue";

provideApolloClient(client);
Logger.configure();

// define validations
defineValidationRules();

// TODO: extract into own file !!!
onAuthStateChanged(FirebaseFactory.getAuth(), async (user) => {
  if (user) {
    await store.dispatch("setUser", user);
    const token = await user.getIdToken(true);
    const decodedToken = parseJwt(token);
    // TODO: use allowed roles array instead!
    if (
      decodedToken["https://hasura.io/jwt/claims"]["x-hasura-default-role"] ===
      "admin_user"
    ) {
      // fetch the user
      const { onResult } = useQuery(firebaseAuthCallback_fetchAdmin, {
        id: decodedToken["https://hasura.io/jwt/claims"]["x-hasura-user-id"],
      });
      onResult(async (result) => {
        if (result.loading) {
          return;
        }
        if (!result.error) {
          await store.dispatch(
            "setUserId",
            decodedToken["https://hasura.io/jwt/claims"]["x-hasura-user-id"]
          );
          for (const role of result.data.AdminUser_by_pk.Role) {
            switch (role.role) {
              case "EventManager": {
                await store.dispatch("setIsEventManager", true);
                break;
              }
              case "OrganizerManager": {
                await store.dispatch("setIsOrganizerManager", true);
                break;
              }
              case "AdminManager": {
                await store.dispatch("setIsAdminManager", true);
                break;
              }
              case "TagManager": {
                await store.dispatch("setIsTagManager", true);
                break;
              }
              case "ScraperManager": {
                await store.dispatch("setIsScraperManager", true);
              }
            }
            await store.dispatch("setInitialLoadingDone");
            router.replace({ path: "/dashboard" });
          }
        } else {
          throw result.error;
        }
      });
    } else if (
      decodedToken["https://hasura.io/jwt/claims"]["x-hasura-default-role"] ===
      "admin"
    ) {
      await store.dispatch("setIsEventManager", true);
      await store.dispatch("setIsTagManager", true);
      await store.dispatch("setIsAdminManager", true);
      await store.dispatch("setIsOrganizerManager", true);
      await store.dispatch("setIsScraperManager", true);
      await store.dispatch("setInitialLoadingDone");
      await router.replace({ path: "/dashboard" });
    } else {
      // normal user
      await store.dispatch("setUserId", "");
      await store.dispatch("setIsEventManager", false);
      await store.dispatch("setIsTagManager", false);
      await store.dispatch("setIsAdminManager", false);
      await store.dispatch("setIsOrganizerManager", false);
      await store.dispatch("setIsScraperManager", false);
      await store.dispatch(
        "setOrganizer",
        decodedToken["https://hasura.io/jwt/claims"]["x-hasura-organizer-id"]
      );
      await store.dispatch("setInitialLoadingDone");
      await router.replace({ path: "/dashboard" });
    }
  } else {
    await store.dispatch("setUser", null);
    await store.dispatch("setInitialLoadingDone");
    await router.replace({ path: "/login" });
  }
});

const toastOptions: PluginOptions = {
  position: POSITION.TOP_RIGHT,
  newestOnTop: true,
  timeout: 3000,
  maxToasts: 5,
  pauseOnFocusLoss: true,
};

const app = createApp(App)
  .use(store, storeKey)
  .use(apollo)
  .use(Toast, toastOptions);

// only activate in internal and production builds

if (
  import.meta.env.VITE_ENVIRONMENT === "internal" ||
  import.meta.env.VITE_ENVIRONMENT === "production"
) {
  // TODO: maybe extract?
  // init sentry
  // TODO: configure more precisely
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    debug: import.meta.env.VITE_SENTRY_DEBUG,
    // release: "" -> set automatically,
    maxBreadcrumbs: 200,
    autoSessionTracking: true,
    environment: import.meta.env.VITE_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration({ router}),
        /*tracePropagationTargets: [
          "localhost",
          "https://internal.app.pingr.de",
          "https://app.pingr.de",
          /^\//,
        ],*/
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

app.use(router);

router.replace({ name: "initial-loading" });

app.mount("#app");
