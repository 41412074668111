<template lang="pug">
.frame
  .card-container(@click="$emit('clicked')")
    .banner-container
      img.banner(v-if="event.cover", :src="event.cover")
      .banner(v-else)
        .banner-text-wrapper
          span.banner-text Event Card Banner
    PrimaryTagIcon.primary-icon(
      v-if="event.primaryTagId",
      :id="event.primaryTagId"
    )
    div(v-if="event.primaryTagId")
      img(
        alt="marker icon",
        src="../../../assets/icons/triangle_orange.svg",
        style="position: absolute; top: calc(25% - 6px); width: 12px; height: 12px; left: calc(50% - 6px)"
      )
      PrimaryTagIcon(
        :id="event.primaryTagId",
        style="position: absolute; top: calc(25% - 36px); left: calc(50% - 18px)"
      )
    .information
      .start-time-wrapper
        img.clock-icon(
          alt="Clock Icon",
          src="../../..//assets/icons/clock@2x.png"
        )
        span.start-time {{ event.startTime ? parseDate(event.startTime) : "Start Time" }}
      p.event-name {{ event.title ? (event.title.length > 20 ? event.title.substring(0, 20) + "..." : event.title) : "Event Name" }}
      p.event-series-title {{ event.EventSeries ? event.EventSeries.name : "Event Series Name" }}
      p.location {{ event.locationAlias ? event.locationAlias : "Event Location" }}
      .actions
        .going
          img.action-icon(src="../../../assets/eventCard/calendar.png")
          p SAVE
        .invite
          img.action-icon(src="../../../assets/eventCard/invite.png")
          p INVITE
        .share
          img.action-icon(src="../../../assets/eventCard/share.png")
          p SHARE
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { formatDate } from "../../../utils/dateFormatter";
import { EventPreviewDto } from "./types";
import PrimaryTagIcon from "../../PrimaryTag/Icon/PrimaryTagIcon.vue";

export default defineComponent({
  name: "EventCardPreview",
  components: { PrimaryTagIcon },
  props: {
    event: {
      type: Object as PropType<EventPreviewDto>,
      required: true,
      default: () => ({} as EventPreviewDto),
    },
  },
  emits: ["clicked"],
  methods: {
    parseDate(date: Date | string) {
      if (typeof date == "string") {
        return formatDate(date, "EEEEEE, dd.MM.yyyy HH:mm");
      } else {
        return formatDate(date.toISOString(), "EEEEEE, dd.MM.yyyy HH:mm");
      }
    },
  },
});
</script>

<style scoped>
.frame {
  @apply flex flex-row items-end justify-center text-center relative;
  @apply w-full h-full overflow-auto bg-cover;
  border-radius: 2.6em;
  background-image: url("../../../assets/images/preview-background.webp");
}

.card-container {
  @apply flex flex-col rounded-xl;
  @apply shadow-xl cursor-pointer h-60 mb-10;
  background-color: #2d2d2d;

  width: 95%;
}

.information {
  @apply flex flex-col items-center text-center;
  @apply px-2.5 mt-1.5 mb-9;
}

.banner-container {
  @apply w-full h-32;
}

.banner {
  @apply w-full h-full;
  border-radius: 12px 12px 0 0;
  background-color: #212121;

  &-text-wrapper {
    @apply flex items-center justify-center h-full;
  }

  &-text {
    @apply font-bold;
  }
}

.start-time-wrapper {
  @apply flex flex-row items-center justify-center;
}

.clock-icon {
  @apply mr-2 h-2 w-2;
}

.start-time {
  font-size: 0.65rem;
  color: #9f9f9f;
}

.event-name {
  @apply font-bold text-base leading-none;
}

.location,
.event-series-title {
  @apply text-center p-0 m-0;
  font-size: 0.65rem;
  color: #9f9f9f;
}

.primary-icon {
  @apply absolute justify-center h-9 w-9;
  margin-bottom: -30px;
  left: 16px;
  bottom: 138px;
}

.actions {
  @apply absolute flex flex-row justify-between items-center rounded-xl h-12;
  margin: 10px auto;
  background: white;
  bottom: 6px;
  width: 85%;
}

.going > p,
.invite > p,
.share > p {
  @apply font-bold mt-0.5;
  font-size: 0.65rem;
  color: black;
}

.going {
  @apply ml-2.5;
}

.share {
  @apply mr-2.5;
}

.going,
.invite,
.share {
  @apply flex flex-col justify-center items-center w-full;
}

.action-icon {
  @apply w-3.5 h-3.5 mt-1;
}
</style>
