export default class Logger {
  private static logger: Console;

  public static configure() {
    this.logger = console;
  }

  public static debug(message: string) {
    this.logger.debug(message);
  }

  public static info(message: string) {
    this.logger.info(message);
  }
  public static warn(message: string) {
    this.logger.warn(message);
  }
  public static error(message: string) {
    this.logger.error(message);
  }
}
