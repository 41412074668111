<template lang="pug">
UiLoadingOverlay(v-if="isCreating || isUpdating") {{ mode === "create" ? "Die Eventreihe wird erstellt..." : "Die Eventreihe wird aktualisiert..." }}
.event-series-form
  .left
    form.content(autocomplete="off", @submit="onSubmit")
      .header
        p Benenne deine Eventreihe

      label.label Name der Eventreihe *
      UiTextInput.section-input(
        v-model="eventSeries.name",
        style-ids="rounded-border hover-primary",
        name="name",
        :skeleton="true",
        :loading="initialLoading",
        maxlength="100",
        :validation-rules="{ required: true }"
      )
      .secondary-tag-wrapper
        .secondary-tags
          SecondaryTagVisualization(
            v-for="tag in secondaryTags",
            :key="tag.id",
            :name="tag.name",
            @click="secondaryTags = secondaryTags.filter((secondary) => secondary.id !== tag.id)"
          )
        div
          .error-message(v-if="secondaryTagErrorMessage") {{ secondaryTagErrorMessage }}
      .divider
      .header
        p Beschreibe deine Eventreihe

      label.label Beschreibung *
      UiTextInput.section-input(
        tag="textarea",
        style-ids="rounded-border hover-primary",
        name="description",
        maxlength="5000",
        :max-length="5000",
        :skeleton="true",
        :loading="initialLoading",
        v-model="eventSeries.description",
        :validation-rules="{ required: true }"
      )
      .divider
      .header
        p Social Media Informationen
      label.label Facebook
      UiTextInput.section-input(
        style-ids="rounded-border hover-primary",
        v-model="eventSeries.facebookLink",
        name="facebookLink",
        :skeleton="true",
        :loading="initialLoading",
        :validation-rules="{ url: true }"
      )
      label.label Instagram
      UiTextInput.section-input(
        style-ids="rounded-border hover-primary",
        v-model="eventSeries.instagramLink",
        name="instagramLink",
        :skeleton="true",
        :loading="initialLoading",
        :validation-rules="{ url: true }"
      )
      label.label Webseite
      UiTextInput.section-input(
        style-ids="rounded-border hover-primary",
        v-model="eventSeries.websiteLink",
        name="websiteLink",
        :skeleton="true",
        :loading="initialLoading",
        :validation-rules="{ url: true }"
      )
      .divider
      .header
        p Lade dein Design hoch!

      label.label Logo *
      UiDropZone(
        name="logo",
        v-model="eventSeries.logoUri",
        type="EVENT_SERIES_LOGO",
        :validation-rules="{ required: true }"
      )
      label.label Banner *
      UiDropZone(
        name="banner",
        v-model="eventSeries.bannerUri",
        type="EVENT_SERIES_BANNER",
        :validation-rules="{ required: true }"
      )
      label.label Bilder (min. 1, max. 3) *
      UiDropZone(
        name="image",
        label="image",
        v-model="eventSeries.galleryImages",
        type="EVENT_SERIES_IMAGE",
        :limit=3,
        :min-required=1
      )
      .image-error-message {{ imageErrorMessage }}
      .images
        EventSeriesImagePreview(
          v-for="(image, index) in eventSeries.galleryImages",
          :key="index",
          :image="image",
          @onDelete="eventSeries.galleryImages = eventSeries.galleryImages.filter((img) => img.imageUri !== image.imageUri)"
        )
      UiButton.button(
        style-ids="primary",
        :disabled="Object.keys(errors).length > 0 || isSubmitting"
      ) {{ mode === "create" ? "Create" : "Update" }}
  .right
    .preview-area-container
      IPhoneFrame.device-frame
        EventSeriesAppPreview(v-bind="eventSeries")
</template>

<script lang="ts">
import {computed, defineComponent, reactive, Ref, ref, toRaw, toRefs, watch} from "vue";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { useStore } from "vuex";
import { useForm } from "vee-validate";
import { useRouter } from "vue-router";
import { TYPE, useToast } from "vue-toastification";
import { storeKey } from "../../../infrastructure/store/store";
import UiTextInput from "../../../ui/TextInput/UiTextInput.vue";
import UiButton from "../../../ui/Button/UiButton.vue";
import UiDropZone from "../../../ui/DropZone/UiDropZone.vue";
import SecondaryTagVisualization from "../../SecondaryTag/Visualization/SecondaryTagVisualization.vue";
import { EventSeriesGalleryImage } from "../types/GalleryImage";
import {
  EventSeriesFormBannerResponse,
  EventSeriesFormCreateResponse,
  EventSeriesFormEventSeries,
  EventSeriesFormGalleryImagesResponse,
  EventSeriesFormLogoResponse,
  EventSeriesFormSecondaryTag,
  EventSeriesFormUpdateResponse,
} from "./types";
import { eventSeriesForm_create } from "../../../ui/DropZone/queries";
import {
  eventSeriesForm_loadEventSeriesBanner,
  eventSeriesForm_loadEventSeriesImageUrls,
  eventSeriesForm_loadEventSeriesInitial,
  eventSeriesForm_loadEventSeriesLogo,
  eventSeriesForm_update,
} from "./queries";
import { authenticatedAreaFetchEventSeries } from "../../../infrastructure/store/states/queries";
import EventSeriesImagePreview from "./../ImagePreview/index.vue";
import EventSeriesAppPreview from "../AppPreview/AppPreview.vue";
import IPhoneFrame from "../../../ui/IPhoneFrame/IPhoneFrame.vue";
import UiLoadingOverlay from "../../../ui/LoadingOverlay/LoadingOverlay.vue";
import { useBeforeRouteLeaveAlert } from "../../../utils/beforeRouteLeaveAlertMixin";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";

export default defineComponent({
  name: "EventSeriesForm",
  components: {
    UiLoadingOverlay,
    EventSeriesImagePreview,
    SecondaryTagVisualization,
    UiDropZone,
    EventSeriesAppPreview,
    UiButton,
    UiTextInput,
    IPhoneFrame,
  },
  props: {
    eventSeriesId: { type: String, required: false, default: "" },
  },
  setup(props) {
    // is always passed by the router
    const { eventSeriesId } = toRefs(props);
    const showRouteLeaveAlert = ref(false);
    useBeforeRouteLeaveAlert(showRouteLeaveAlert);

    // initialize variables
    const router = useRouter();
    const mode = ref(router.currentRoute.value.fullPath.split("/")[2]);
    const store = useStore(storeKey);
    const toast = useToast();
    let loadingProgress: Ref<number | undefined> = ref(undefined);
    let error;
    let queryResult;
    let eventSeriesLoading = ref(false);
    let imageError = ref("");
    const activeOrganizerId = store.getters.organizer;
    const eventSeries = reactive({
      id: "",
      name: "",
      bannerUri: "",
      logoUri: "",
      bannerId: "",
      logoId: "",
      instagramLink: "",
      facebookLink: "",
      websiteLink: "",
      description: "",
      galleryImages: [] as EventSeriesGalleryImage[],
    });
    let initialEventSeries = cloneDeep(eventSeries);
    const galleryImageQueryParameter = ref({
      eventSeriesId: "",
      imageId1: "",
      imageId2: "",
      imageId3: "",
    });

    // refs for activating/deactivating queries
    const loadEventSeries = ref(false);
    const loadLogo = ref(false);
    const loadBanner = ref(false);
    const loadGalleryImages = ref(false);

    // mutations
    // create EventSeries
    const { mutate: createEventSeries, loading: isCreating } =
      useMutation<EventSeriesFormCreateResponse>(eventSeriesForm_create);
    // update EventSeries
    const { mutate: updateEventSeries, loading: isUpdating } =
      useMutation<EventSeriesFormUpdateResponse>(eventSeriesForm_update);

    // queries
    // event series
    const {
      loading,
      result: eventSeriesResult,
      onResult: onEventSeriesResult,
    } = useQuery<{
      EventSeries_by_pk: EventSeriesFormEventSeries;
    }>(
      eventSeriesForm_loadEventSeriesInitial,
      () => ({
        id: eventSeriesId.value,
      }),
      () => ({ enabled: loadEventSeries.value })
    );

    // TODO: maybe transform them into one query?!
    // logo
    const {
      result: logoResult,
      onResult: onLogoResult,
      loading: logoLoading,
    } = useQuery<EventSeriesFormLogoResponse>(
      eventSeriesForm_loadEventSeriesLogo,
      () => ({ eventSeriesId: eventSeries.id }),
      () => ({ enabled: loadLogo.value })
    );
    // banner
    const {
      result: bannerResult,
      onResult: onBannerResult,
      loading: bannerLoading,
    } = useQuery<EventSeriesFormBannerResponse>(
      eventSeriesForm_loadEventSeriesBanner,
      () => ({ eventSeriesId: eventSeries.id }),
      () => ({ enabled: loadBanner.value })
    );
    // galleryImages
    const {
      result: galleryImagesResult,
      onResult: onGalleryImagesResult,
      loading: galleryImagesLoading,
    } = useQuery<EventSeriesFormGalleryImagesResponse>(
      eventSeriesForm_loadEventSeriesImageUrls,
      // is only safe because we enforce 3 images
      () => ({
        eventSeriesId: eventSeries.id,
        imageId1: galleryImageQueryParameter.value.imageId1,
        imageId2: galleryImageQueryParameter.value.imageId2,
        imageId3: galleryImageQueryParameter.value.imageId3,
      }),
      () => ({ enabled: loadGalleryImages.value })
    );

    const eventSeriesResponse = computed(() => eventSeriesResult.value?.EventSeries_by_pk);
    const logoResponse = computed(() => logoResult.value?.loadLogo.url);
    const bannerResponse = computed(() => bannerResult.value?.getEventSeriesBannerUrl);
    const galleryImagesResponse = computed(() => galleryImagesResult.value);

    // eslint-disable-next-line vue/no-ref-as-operand
    eventSeriesLoading = loading;

    // watcher
    // watches for event series id change and updates mode variable
    watch(eventSeriesId, () => {
      mode.value = router.currentRoute.value.fullPath.split("/")[2];
      if (mode.value === "update") {
        loadEventSeries.value = true;
      }
    });
    // watches the mode and initializes queries
    watch(mode, (current) => {
      // warn if mode cannot be parsed or is not valid
      if (current !== "create" && current !== "update") {
        console.warn(
          'cannot parse mode from router; valid modes are "create" and "update"'
        );
      }

      // reset the view
      if (current === "create") {
        loadEventSeries.value = false;
        loadLogo.value = false;
        loadBanner.value = false;
        loadGalleryImages.value = false;

        // TODO: validation kicks in. NOT GOOD!
        Object.assign(eventSeries, {
          id: "",
          name: "",
          bannerUri: "",
          logoUri: "",
          bannerId: "",
          logoId: "",
          instagramLink: "",
          facebookLink: "",
          websiteLink: "",
          description: "",
          galleryImages: [] as EventSeriesGalleryImage[],
        });
      } else if (current === "update") {
        loadEventSeries.value = true;
      }
    });

    const handleEventSeriesResult = (entity: EventSeriesFormEventSeries) => {
      eventSeries.id = entity.id;
      eventSeries.name = entity.name;
      eventSeries.description = entity.description;
      eventSeries.facebookLink = entity.facebookLink ? entity.facebookLink : "";
      eventSeries.instagramLink = entity.instagramLink
        ? entity.instagramLink
        : "";
      eventSeries.websiteLink = entity.websiteLink ? entity.websiteLink : "";
      eventSeries.galleryImages = entity.GalleryImages;
      eventSeries.logoId = entity.logoId;
      eventSeries.bannerId = entity.bannerId;
      initialEventSeries = cloneDeep(eventSeries);

      loadLogo.value = true;
      loadBanner.value = true;

      // Since the galleryImageQueryParameter object may already be initialized, it must be set to its default value
      // each time.
      galleryImageQueryParameter.value = {
        eventSeriesId: "",
        imageId1: "",
        imageId2: "",
        imageId3: "",
      };

      galleryImageQueryParameter.value["eventSeriesId"] = eventSeries.id;
      for (const [index, image] of eventSeries.galleryImages.entries()) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        galleryImageQueryParameter.value[`imageId${index + 1}`] = image.imageId;
      }
      loadEventSeries.value = false;

      loadGalleryImages.value = true;
    };
    const handleLogoResult = (uri: string) => {
      eventSeries.logoUri = uri;
      initialEventSeries.logoUri = uri;
      loadLogo.value = false;
    };
    const handleBannerResult = (url: string) => {
      eventSeries.bannerUri = url;
      initialEventSeries.bannerUri = url;
      loadBanner.value = false;
    };
    const handleGalleryImagesResult = (
      result: EventSeriesFormGalleryImagesResponse
    ) => {
      eventSeries.galleryImages = [];
      if (galleryImageQueryParameter.value.imageId1) {
        eventSeries.galleryImages.push({
          imageId: galleryImageQueryParameter.value.imageId1,
          imageUri: result.image1.url,
        });
      }
      if (galleryImageQueryParameter.value.imageId2) {
        eventSeries.galleryImages.push({
          imageId: galleryImageQueryParameter.value.imageId2,
          imageUri: result.image2.url,
        });
      }
      if (galleryImageQueryParameter.value.imageId3) {
        eventSeries.galleryImages.push({
          imageId: galleryImageQueryParameter.value.imageId3,
          imageUri: result.image3.url,
        });
      }
      initialEventSeries = cloneDeep(eventSeries);
      loadGalleryImages.value = false;
    };

    // handle result coming from cache
    if (eventSeriesResponse.value) {
      handleEventSeriesResult(eventSeriesResponse.value);
    }

    if (logoResponse.value) {
      handleLogoResult(logoResponse.value);
    }

    if (bannerResponse.value) {
      handleBannerResult(bannerResponse.value);
    }
    if (galleryImagesResponse.value) {
      handleGalleryImagesResult(galleryImagesResponse.value);
    }

    // handle result coming from the server
    onEventSeriesResult((result) => {
      if (!eventSeriesLoading.value) {
        handleEventSeriesResult(result.data.EventSeries_by_pk);
      }
    });

    onLogoResult((result) => {
      if (!logoLoading.value) {
        handleLogoResult(result.data.loadLogo.url);
      }
    });
    onGalleryImagesResult((result) => {
      if (!galleryImagesLoading.value) {
        handleGalleryImagesResult(result.data);
      }
    });
    onBannerResult((result) => {
      if (!bannerLoading.value) {
        handleBannerResult(result.data.getEventSeriesBannerUrl.url);
      }
    });

    // init loading states
    if (mode.value === "update") {
      loadEventSeries.value = true;
    }

    // form validation
    const { handleSubmit, isSubmitting, errors } = useForm();
    const onSubmit = handleSubmit(() => {
      showRouteLeaveAlert.value = false;
      if (mode.value === "create") {
        createEventSeries(
          {
            name: eventSeries.name,
            // ToDo: add secondary tags
            // safe to use as the menu won't show the create
            // option if the organizer is not set
            organizerId: activeOrganizerId,
            description: eventSeries.description,
            // check if the strings are empty, if yes set them to null
            facebookLink: eventSeries.facebookLink.trim()
              ? eventSeries.facebookLink
              : null,
            instagramLink: eventSeries.instagramLink.trim()
              ? eventSeries.instagramLink
              : null,
            websiteLink: eventSeries.websiteLink.trim()
              ? eventSeries.websiteLink
              : null,
            logo: eventSeries.logoUri,
            banner: eventSeries.bannerUri,
            images: eventSeries.galleryImages.map((galleryImage) => ({
              data: galleryImage.imageUri,
            })),
          },
          {
            refetchQueries: [
              {
                query: authenticatedAreaFetchEventSeries,
                variables: { id: activeOrganizerId },
              },
            ],
          }
        )
          .then(() => {
            store.dispatch("setActiveNavigationItem", "dashboard");
            router.back();
          })
          .catch((error) => {
            toast("Fehler beim Erstellen der Event-Reihe", {
              type: TYPE.ERROR,
            });
            throw new Error(error);
          });
      } else if (mode.value === "update") {
        updateEventSeries({
          id: eventSeriesId.value,
          name: eventSeries.name,
          // ToDo: add secondary tags
          description: eventSeries.description,
          facebookLink: eventSeries.facebookLink,
          instagramLink: eventSeries.instagramLink,
          websiteLink: eventSeries.websiteLink,
          logo: eventSeries.logoUri,
          banner: eventSeries.bannerUri,
          images: eventSeries.galleryImages.map((galleryImage) => ({
            data: galleryImage.imageUri,
          })),
        })
          .then(() => {
            store.dispatch("setActiveNavigationItem", "dashboard");
            router.back();
          })
          .catch((error) => {
            toast("Fehler beim Speichern der Event-Reihe", {
              type: TYPE.ERROR,
            });
            throw new Error(error);
          });
      }
    });

    watch(eventSeries, () => {
      if (!isEqual(initialEventSeries, eventSeries)) {
        showRouteLeaveAlert.value = true;
      }
    });

    return {
      isCreating,
      isUpdating,
      eventSeries,
      errors,
      onSubmit,
      isSubmitting,
      mode,
      initialLoading:
        eventSeriesLoading ||
        logoLoading ||
        bannerLoading ||
        galleryImagesLoading,
      loadingProgress,
      queryResult,
      loadGalleryImages,
      galleryImageQueryParameter,
      error,
      imageError,
    };
  },
  data: () => ({
    bannerLoading: false,
    bannerSuccess: false,
    logoLoading: false,
    logoSuccess: false,
    imageLoading: false,
    imageSuccess: false,
    galleryImageId: 1,
    secondaryTags: [] as EventSeriesFormSecondaryTag[],
    secondaryTagErrorMessage: "",
    imageErrorMessage: "",
    // TODO: typing
    images: [] as any[],
  }),
  methods: {
    onSecondaryTagSelect(tag: EventSeriesFormSecondaryTag) {
      if (!this.secondaryTags.includes(tag)) {
        this.secondaryTags.push(tag);
      } else {
        this.secondaryTagErrorMessage = `${tag.name} has already been taken...`;
        setTimeout(() => {
          this.secondaryTagErrorMessage = "";
        }, 2500);
      }
    },
  },
});
</script>

<style scoped>
.event-series-form {
  @apply h-full w-full;
  @apply flex;
  @apply overflow-hidden;

  .left {
    @apply w-1/2;
    @apply border-r border-border-default;
    @apply overflow-auto;

    .content {
      @apply grid grid-flow-row;
      @apply p-8;
      @apply gap-x-24 gap-y-4;

      & > * {
        @apply self-center;
      }
      grid-template-columns: 3fr 8fr;

      .header {
        @apply text-lg font-bold;

        grid-area: auto / 1 / auto / span 2;
      }

      .label {
        @apply text-sm;
        grid-area: auto / 1 / auto / 1;
      }

      .divider {
        @apply h-0.5 bg-background-contrast mt-2;

        grid-area: auto / 1 / auto / span 2;
      }

      .secondary-tag-wrapper {
        @apply flex flex-col items-start flex-wrap;

        grid-area: 4 / 2 / 4 / 2;

        .error-message {
          @apply text-background-highlight;
        }
        .secondary-tags {
          @apply flex flex-wrap;

          .secondary-tag {
            @apply flex justify-center items-center;
            @apply font-bold text-background-bright bg-primary text-sm;
            @apply px-5 py-0.5 rounded-2xl mx-2 mb-3;

            &:hover {
              @apply bg-background-highlight cursor-pointer;
            }
          }
        }
      }

      .image-error-message {
        @apply text-background-highlight;

        margin: 0 auto;
        grid-area: auto / 1 / auto / span 2;
      }

      .images {
        @apply w-full flex items-center justify-around mt-4;

        grid-area: auto / 1 / auto / span 2;
        .image {
          @apply border p-1;
        }
      }
      .button {
        @apply mt-4;
        grid-area: auto / 1 / auto / span 2;
      }
    }
  }

  .right {
    @apply w-1/2;
    @apply flex flex-row justify-center items-center;
    @apply overflow-auto;

    .preview {
      @apply w-full h-full;
    }
  }
}

.preview-area-container {
  @apply w-full h-full flex flex-col justify-center items-center;
}

.device-frame {
  @apply pb-3;
}
</style>
