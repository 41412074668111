<template lang="pug">
transition(name="fade")
  article.message(v-show="isActive")
    section.message-body(v-if="$slots.default", :class="[type]")
      .media
        .media-left
          img.media-icon(:src="icon", :class="['media-icon-' + type]")
        .media-content
          slot
        .media-close-button
          a.close(v-if="closable", @click="close")
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { messageIcons } from "./messageIcons";

export default defineComponent({
  name: "UiMessage",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    title: { type: String, required: false, default: "" },
    closable: {
      type: Boolean,
      default: true,
    },
    message: { type: String, required: false, default: "" },
    type: {
      type: String,
      required: false,
      default: "info",
      validator: (type: string) => {
        return ["info", "warning"].includes(type);
      },
    },
  },
  emits: ["click"],
  data() {
    return {
      isActive: this.active,
      newIconSize: this.iconSize || this.size || "is-large",
    };
  },
  computed: {
    icon() {
      return messageIcons[this.type];
    },
  },
  watch: {
    active(value) {
      this.isActive = value;
    },
  },
  methods: {
    close() {
      this.isActive = false;
    },
    click() {
      this.$emit("click");
    },
  },
});
</script>

<style scoped>
article {
  display: block;
}

.message {
  @apply bg-background-contrast rounded text-base;
  &-body {
    @apply rounded border-solid border-l-4;
    padding: 1.25rem 0.25rem 1.25rem 1.5rem;
    color: #ccc;
  }
}

.info {
  border-color: #3e8ed0;
}

.warning {
  @apply border-primary;
}

.media {
  @apply items-center flex flex-row;
}

.media-left {
  @apply mr-6;
}

.media-icon {
  width: 24px;
  min-width: 24px;
  /* height of area */
  height: 24px;
  &-info {
    filter: invert(49%) sepia(39%) saturate(810%) hue-rotate(165deg)
      brightness(96%) contrast(89%);
  }
  &-warning {
    filter: invert(68%) sepia(37%) saturate(3950%) hue-rotate(2deg)
      brightness(106%) contrast(106%);
  }
}

.close {
  @apply flex opacity-70 ml-2.5;
  width: 24px;
  height: 24px;
}
.close:hover {
  @apply opacity-100;
}
.close:before,
.close:after {
  /*
  3px = (height of area - height of a single bar of the cross) / 2
  3px = (24px - 18px) / 2
  Used to center the cross within the container
  */
  margin-top: 3px;
  content: " ";
  /* height of a single bar of the cross */
  height: 18px;
  width: 1px;
  background-color: #ccc;
}
.close:before {
  @apply ml-2.5;
  margin-right: -1px;
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
.media-close-button {
  @apply ml-auto;
}
</style>
