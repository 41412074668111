<script lang="ts">
import { DefineComponent, defineComponent, h, inject } from "vue";
import { toCssDimension } from "../utils/helpers";
import UiTable from "./UiTable.vue";

/**
 * @displayName Table Column
 */
export default defineComponent({
  name: "UiTableColumn",
  props: {
    label: {
      type: String,
      default: "",
    },
    field: {
      type: String,
      default: undefined,
    },
    width: {
      type: [Number, String],
      default: undefined,
    },
    numeric: Boolean,
    /**
     * Optional, position of column content
     * @values centered, right
     */
    position: {
      type: String,
      default: "left",
      validator: (value: string) => {
        // The value must match one of these strings
        return ["left", "centered", "right"].includes(value);
      },
    },
    sortable: Boolean,
    sticky: Boolean,
    thAttrs: {
      type: Function,
      default: () => ({}),
    },
    /** Adds native attributes to td :td-attrs="(row, column)" => ({})" */
    tdAttrs: {
      type: Function,
      default: () => ({}),
    },
  },
  setup() {
    const uiTable = inject<DefineComponent<typeof UiTable>>("uiTable");
    return { uiTable: uiTable };
  },
  data() {
    return {
      newKey: undefined,
      isTableColumn: true,
    };
  },
  computed: {
    style() {
      return {
        width: toCssDimension(this.width),
      };
    },
    hasDefaultSlot() {
      return this.$slots.default;
    },
    hasHeaderSlot() {
      return this.$slots.header;
    },
  },
  created() {
    if (!this.uiTable) {
      throw new Error("You should wrap UiTableColumn on a UiTable");
    }
    this.newKey = this.uiTable._nextSequence();
    this.uiTable._addColumn(this);
  },
  beforeUnmount() {
    if (this.uiTable) {
      this.uiTable._removeColumn(this);
    }
  },
  render() {
    // renderless
    return h("span", { "data-id": this.newKey }, this.label);
  },
});
</script>
