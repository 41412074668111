<template lang="pug">
.auto-complete
  .input
    UiTextInput(
      v-model="input",
      v-bind="$attrs",
      :loading="loading",
      :skeleton="true",
      :style-ids="styleIds",
      :placeholder="placeholder",
      :validation-rules="validationRules",
      :name="name",
      :disabled="disabled"
      @keydown.enter.prevent,
      @focusin="$emit('focusin')",
      @focusout="$emit('focusout')",
    )
      // This forwards the icon slot to the TextInput component
      template(v-for="(_, slot) of $slots", #[slot]="scope")
        slot(:name="slot", v-bind="scope")
  .suggestions(v-if="showSuggestions")
    .suggestion-drop-down
      slot
      slot(name="footer")
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UiTextInput from "../TextInput/UiTextInput.vue";
import { STYLE_IDS_PROP_DEF } from "../utils/style-ids";

export default defineComponent({
  name: "UiAutoComplete",
  components: { UiTextInput },
  props: {
    styleIds: STYLE_IDS_PROP_DEF,
    placeholder: { type: String, required: false, default: "" },
    showSuggestions: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    modelValue: { type: String, required: false, default: "" },
    disabled: { type: Boolean, required: false, default: false },
    name: {
      type: String,
      required: false,
      default: "default",
    },
    validationRules: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  emits: ["update:modelValue", "focusin", "focusout"],
  data() {
    return {
      input: this.modelValue,
    };
  },
  watch: {
    input() {
      this.$emit("update:modelValue", this.input);
    },
    modelValue() {
      this.input = this.modelValue;
    },
  },
  methods: {},
});
</script>

<style scoped>
.suggestions {
  @apply flex flex-col relative;

  .suggestion-drop-down {
    @apply absolute w-full shadow-md z-10;
  }
}
</style>
