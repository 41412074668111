<template lang="pug">
.ui-context-menu__item(
  role="listitem"
  @click="selectItem"
)
  slot
</template>

<script lang="ts">
import { DefineComponent, defineComponent, inject } from "vue";
import UiContextMenu from "./UiContextMenu.vue";

export default defineComponent({
  name: "UiContextMenuItem",
  configField: "contextMenu",
  emits: ["click"],
  setup() {
    const contextMenu =
      inject<DefineComponent<typeof UiContextMenu>>("uiContextMenu");
    return { contextMenu };
  },
  created() {
    if (!this.contextMenu) {
      throw new Error("You should wrap uiContextMenuItem on a UiContextMenu");
    }
  },
  methods: {
    selectItem() {
      this.$emit("click");
    },
  },
});
</script>

<style scoped>
.ui-context-menu__item {
  cursor: pointer;
  padding: 0.375rem 1rem;
  &:hover {
    @apply text-primary;
  }
}
</style>
