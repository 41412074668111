<template lang="pug">
.input
  .component-wrapper(:class="{ 'animate-pulse': loading }")
    select.component(
      v-model="inputValue",
      :disabled="disabled",
      :class="{ ...styleIdClasses, 'cursor-not-allowed': disabled, 'cursor-pointer': !disabled }",
      v-bind="$attrs"
    )
      template(v-if="placeholder")
        option(:value="null", disabled) {{ placeholder }}

      slot
  .error-indicator(v-if="errorMessage")
    .indicator !
    .error-message {{ errorMessage }}
</template>

<script lang="ts">
import { defineComponent, Ref, ref, toRefs, watch } from "vue";
import { useField } from "vee-validate";
import { STYLE_IDS_PROP_DEF, useStyleIdClasses } from "../utils/style-ids";

export default defineComponent({
  name: "UiSelect",
  props: {
    rounded: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    modelValue: {
      type: [String, Number, Boolean, Object, Array],
      required: false,
      default: null,
    },
    hideArrow: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: false,
      default: "default",
    },
    validationRules: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: { type: Boolean, required: false, default: false },
    styleIds: STYLE_IDS_PROP_DEF,
  },
  emits: ["change", "update:modelValue"],
  setup(props, { emit }) {
    const { validationRules, name, modelValue, styleIds } = toRefs(props);
    let returnValue = {
      styleIdClasses: useStyleIdClasses(styleIds).value,
      fieldName: name.value,
      errorMessage: ref(undefined) as Ref<string | undefined>,
      inputValue: ref(null) as
        | Ref<string | number | boolean | unknown[]>
        | Record<string, any>,
    };

    const validationRulesPassed = () => {
      return (
        validationRules.value && Object.keys(validationRules.value).length > 0
      );
    };

    if (validationRulesPassed()) {
      const { errorMessage, value: fieldValue } = useField(
        name,
        validationRules,
        {
          initialValue: modelValue,
        }
      );

      returnValue.errorMessage = errorMessage;
      returnValue.inputValue = fieldValue;
    } else {
      returnValue.inputValue = ref(modelValue.value);
    }

    watch(modelValue, (current) => {
      returnValue.inputValue.value = current;
    });

    watch(returnValue.inputValue, (current) => {
      emit("update:modelValue", current);
      emit("change", current);
    });

    return returnValue;
  },
  computed: {
    styleClasses() {
      return {
        "rounded-md": true,
        "hide-arrow": this.hideArrow,
        "border-background-highlight": this.errorMessage,
        border: this.errorMessage,
      };
    },
  },
});
</script>

<style scoped>
.input {
  @apply flex justify-start relative flex-grow;

  .component-wrapper {
    @apply flex flex-col flex-grow relative;

    .component {
      @apply py-2 px-3;
      @apply text-text-default text-sm bg-background-input rounded-md;
      @apply flex-grow appearance-none;
      flex: 100;

      &:focus {
        @apply outline;
        outline-width: 1px;
        /* TODO outline-primary does not work, but should work according to the documentation */
        outline-color: #ffa900;
      }
    }
  }

  .hide-arrow {
    /* TODO: do we need the vendor prefixes as autoprefixer should add them */
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
  }
}

.error-indicator {
  @apply flex justify-center items-center relative;

  .indicator {
    @apply h-5 w-5 bg-background-highlight;
    @apply rounded-xl;
    @apply flex justify-center items-center;

    /* todo: find better way to centralize vertically in parent */
    @apply absolute right-2 top-2;

    &:hover {
      /* next div sibling */
      & + div {
        @apply block;
      }
    }
  }

  .error-message {
    @apply absolute -top-9 right-0;
    @apply hidden;
    @apply w-60 px-4 py-1;
    @apply bg-background-highlight text-background-bright;
    @apply rounded-lg;
  }
}

.style-hover-primary {
  @apply border;
  &:hover {
    @apply border-primary;
  }
}

.style-hover-primary:not(.border-background-highlight):not(.style-hover-primary:hover) {
  @apply border-transparent;
}
</style>
