<template lang="pug">
.ui-drop(
  ref="dropdown",
  :class="{ 'ui-drop__disabled': disabled }"
  @focusin="showDropDown",
  @focusout="hideDropDown",
)
  .ui-drop__trigger(ref="trigger")
    UiDateTimePickerInput(
      ref="dateTimePickerInput",
      style-ids="rounded hover-primary",
      :value="dateSelected",
      :validation-rules="validationRules",
      :name="name",
      :disabled="disabled",
      :is-focused="isActive",
      :loading="loading"
      @date-changed="onDateChangedInInput",
    )
  transition(name="fade")
    .ui-drop__menu(
      v-show="isActive",
      ref="dropdownMenu",
      :aria-hidden="false",
      tabindex="-1"
    )
      .ui-drop__item.ui-dpck__box(ref="item")
        header.ui-dpck__header
          .ui-dpck__header__buttons
            a.ui-dpck__header__previous(
              role="button",
              :class="{ 'ui-dpck__header__previous__disabled': !showPrev }",
              @click.prevent="prev"
            )
              img(
                src="../../assets/icons/chevron-left-solid.svg",
                alt="arrow-up",
                style="width: 12px"
              )
            a.ui-dpck__header__next(
              role="button",
              :class="{ 'ui-dpck__header__next__disabled': !showNext }",
              @click.prevent="next"
            )
              img(
                src="../../assets/icons/chevron-right-solid.svg",
                alt="arrow-down",
                style="width: 12px"
              )
            .ui-dpck__header__list
              UiSelect(
                v-model="focusedDateData.month",
                style="margin-right: 3px",
                tabindex="-1"
              )
                option(
                  v-for="month in listOfMonths",
                  :key="month.name",
                  :value="month.index",
                  :disabled="month.disabled"
                ) {{ month.name }}

              UiSelect(
                v-model="focusedDateData.year",
                style="margin-right: 3px",
                tabindex="-1"
              )
                option(v-for="year in listOfYears", :key="year", :value="year") {{ year }}
        div(style="display: flex")
          section.ui-dpck__table
            header.ui-dpck__table__head
              .ui-dpck__table__head-cell.ui-dpck__table__cell(
                v-for="(day, index) in visibleDayNames",
                :key="index"
              )
                span {{ day }}
            .ui-dpck__table__body
              template(v-for="(week, index) in weeksInThisMonth", :key="index")
                .dpck__table__row
                  template(v-for="(weekDay, i) in week", :key="i")
                    a(
                      v-if="selectableDate(weekDay)",
                      :ref="`day-${weekDay.getMonth()}-${weekDay.getDate()}`",
                      :class="cellClasses(weekDay)",
                      role="button",
                      @click.prevent="daySelected(weekDay)"
                    )
                      span {{ weekDay.getDate() }}
                    div(v-else, :key="index", :class="cellClasses(weekDay)")
                      span {{ weekDay.getDate() }}
          footer.ui-dpck__footer
            .ui-dpck__footer-column
              a.ui-dpck__footer__increase(
                role="button",
                :class="{ 'ui-dpck__footer__decrease-disable': !showIncreaseHour }",
                @click.prevent="increaseHour"
              )
                img(
                  src="../../assets/icons/chevron-up-solid.svg",
                  alt="arrow-up",
                  style="width: 10px"
                )
              UiSelect.ui-tpck__select.ui-tpck__select-placeholder(
                v-model="focusedDateData.hours",
                :hide-arrow="true",
                placeholder="00",
                tabindex="-1"
                @change="updateSelectedDate",
              )
                option(
                  v-for="hour in hours",
                  :key="hour.value",
                  :value="hour.value",
                  :disabled="isHourDisabled(hour.value)"
                ) {{ hour.label }}
              a.ui-dpck__footer__decrease(
                role="button",
                :class="{ 'ui-dpck__footer__decrease-disable': !showDecreaseHour }",
                @click.prevent="decreaseHour"
              )
                img(
                  src="../../assets/icons/chevron-down-solid.svg",
                  alt="arrow-down",
                  style="width: 10px"
                )
            .ui-dpck__footer-column
              span.ui-tpck__separator :
            .ui-dpck__footer-column
              a.ui-dpck__footer__increase(
                role="button",
                :class="{ 'ui-dpck__footer__decrease-disable': !showIncreaseMinutes }",
                @click.prevent="increaseMinutes"
              )
                img(
                  src="../../assets/icons/chevron-up-solid.svg",
                  alt="arrow-up",
                  style="width: 10px"
                )
              UiSelect.ui-tpck__select.ui-tpck__select-placeholder(
                v-model="focusedDateData.minutes",
                :hide-arrow="true",
                placeholder="00",
                tabindex="-1"
                @change="updateSelectedDate",
              )
                option(
                  v-for="minute in minutes",
                  :key="minute.value",
                  :value="minute.value",
                  :disabled="isMinuteDisabled(minute.value)"
                ) {{ minute.label }}

              a.ui-dpck__footer__decrease(
                role="button",
                :class="{ 'ui-dpck__footer__decrease-disable': !showDecreaseMinutes }",
                @click.prevent="decreaseMinutes"
              )
                img(
                  src="../../assets/icons/chevron-down-solid.svg",
                  alt="arrow-down",
                  style="width: 10px"
                )
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { formatDate } from "../../utils/dateFormatter";
import UiSelect from "../Select/UiSelect.vue";
import UiDateTimePickerInput from "./UiDateTimePickerInput.vue";

/**
 * An input with a simple dropdown/modal for selecting a date and time, uses native datetimepicker for mobile
 * @displayName UiDatetimePicker
 */
export default defineComponent({
  name: "UiDatetimePicker",
  components: { UiDateTimePickerInput, UiSelect },
  props: {
    minDatetime: {
      type: Date,
      default: () => new Date(),
    },
    maxDatetime: {
      type: Date,
      default: () => {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        return date;
      },
    },
    yearsRange: {
      type: Array as () => Array<number>,
      default: () => {
        return [-10, 10];
      },
    },
    firstDayOfWeek: {
      type: Number,
      default: 0,
    },
    modelValue: {
      type: [String, Date],
      default: undefined,
    },
    defaultDate: {
      type: [String, Date],
      required: false,
      default: undefined,
    },
    name: {
      type: String,
      required: false,
      default: "default",
    },
    validationRules: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: { type: Boolean, required: false, default: false },
  },
  emits: ["update:modelValue"],
  data() {
    const focusedDate = new Date();

    return {
      isActive: false,
      bodyEl: undefined, // Used to append to body
      dateSelected: this.modelValue ? new Date(this.modelValue) : undefined,
      focusedDateData: {
        minutes: 0,
        hours: 0,
        day: focusedDate.getDate(),
        month: focusedDate.getMonth(),
        year: focusedDate.getFullYear(),
      },
    };
  },
  computed: {
    minDate() {
      if (!this.minDatetime) {
        return null;
      }
      return new Date(
        this.minDatetime.getFullYear(),
        this.minDatetime.getMonth(),
        this.minDatetime.getDate(),
        0,
        0,
        0,
        0
      );
    },
    maxDate() {
      if (!this.maxDatetime) {
        return null;
      }
      return new Date(
        this.maxDatetime.getFullYear(),
        this.maxDatetime.getMonth(),
        this.maxDatetime.getDate(),
        0,
        0,
        0,
        0
      );
    },
    formattedDate() {
      return this.dateSelected
        ? formatDate((this.dateSelected as Date).toISOString())
        : "";
    },
    showPrev() {
      if (!this.minDate) {
        return false;
      }
      const dateToCheck = new Date(
        this.focusedDateData.year,
        this.focusedDateData.month
      );
      const date = new Date(
        this.minDate.getFullYear(),
        this.minDate.getMonth()
      );
      return dateToCheck > date;
    },
    showDecreaseHour() {
      if (!this.minDatetime) {
        return true;
      }
      const dateToCheck = new Date(
        this.focusedDateData.year,
        this.focusedDateData.month,
        this.focusedDateData.day,
        this.focusedDateData.hours
      );
      const date = new Date(
        this.minDatetime.getFullYear(),
        this.minDatetime.getMonth(),
        this.minDatetime.getDate(),
        this.minDatetime.getHours()
      );
      return dateToCheck > date;
    },
    showDecreaseMinutes() {
      if (!this.minDatetime) {
        return true;
      }
      const dateToCheck = new Date(
        this.focusedDateData.year,
        this.focusedDateData.month,
        this.focusedDateData.day,
        this.focusedDateData.hours,
        this.focusedDateData.minutes
      );
      const date = new Date(
        this.minDatetime.getFullYear(),
        this.minDatetime.getMonth(),
        this.minDatetime.getDate(),
        this.minDatetime.getHours(),
        this.minDatetime.getMinutes()
      );
      return dateToCheck > date;
    },
    showIncreaseHour() {
      if (!this.maxDatetime) {
        return true;
      }
      const dateToCheck = new Date(
        this.focusedDateData.year,
        this.focusedDateData.month,
        this.focusedDateData.day,
        this.focusedDateData.hours
      );
      const date = new Date(
        this.maxDatetime.getFullYear(),
        this.maxDatetime.getMonth(),
        this.maxDatetime.getDate(),
        this.maxDatetime.getHours()
      );
      return dateToCheck < date;
    },
    showIncreaseMinutes() {
      if (!this.maxDatetime) {
        return true;
      }
      const dateToCheck = new Date(
        this.focusedDateData.year,
        this.focusedDateData.month,
        this.focusedDateData.day,
        this.focusedDateData.hours,
        this.focusedDateData.minutes
      );
      const date = new Date(
        this.maxDatetime.getFullYear(),
        this.maxDatetime.getMonth(),
        this.maxDatetime.getDate(),
        this.maxDatetime.getHours(),
        this.maxDatetime.getMinutes()
      );
      return dateToCheck < date;
    },
    showNext() {
      if (!this.maxDate) {
        return false;
      }
      const dateToCheck = new Date(
        this.focusedDateData.year,
        this.focusedDateData.month
      );
      const date = new Date(
        this.maxDate.getFullYear(),
        this.maxDate.getMonth()
      );
      return dateToCheck < date;
    },
    newMonthNames() {
      return this.getMonthNames();
    },
    listOfMonths() {
      let minMonth = 0;
      let maxMonth = 12;
      if (
        this.minDate &&
        this.focusedDateData.year === this.minDate.getFullYear()
      ) {
        minMonth = this.minDate.getMonth();
      }
      if (
        this.maxDate &&
        this.focusedDateData.year === this.maxDate.getFullYear()
      ) {
        maxMonth = this.maxDate.getMonth();
      }
      return this.newMonthNames.map((name, index) => {
        return {
          name: name,
          index: index,
          disabled: index < minMonth || index > maxMonth,
        };
      });
    },
    /*
     * Return array of all weeks in the specified month
     */
    weeksInThisMonth() {
      // this.validateFocusedDay()
      const month = this.focusedDateData.month;
      const year = this.focusedDateData.year;
      const weeksInThisMonth = [];
      let startingDay = 1;
      while (weeksInThisMonth.length < 6) {
        const newWeek = this.weekBuilder(startingDay, month, year);
        weeksInThisMonth.push(newWeek);
        startingDay += 7;
      }
      return weeksInThisMonth;
    },
    /*
     * Returns an array of years for the year dropdown. If earliest/latest
     * dates are set by props, range of years will fall within those dates.
     */
    listOfYears() {
      let latestYear = this.focusedDateData.year + this.yearsRange[1];
      if (this.maxDate && this.maxDate.getFullYear() < latestYear) {
        latestYear = Math.max(
          this.maxDate.getFullYear(),
          this.focusedDateData.year
        );
      }
      let earliestYear = this.focusedDateData.year + this.yearsRange[0];
      if (this.minDate && this.minDate.getFullYear() > earliestYear) {
        earliestYear = Math.min(
          this.minDate.getFullYear(),
          this.focusedDateData.year
        );
      }
      const arrayOfYears = [];
      for (let i = earliestYear; i <= latestYear; i++) {
        arrayOfYears.push(i);
      }
      return arrayOfYears.reverse();
    },
    visibleDayNames() {
      const visibleDayNames = [];
      let index = this.firstDayOfWeek;
      const dayNames = this.getWeekdayNames();
      while (visibleDayNames.length < dayNames.length) {
        const currentDayName = dayNames[index % dayNames.length];
        visibleDayNames.push(currentDayName);
        index++;
      }
      return visibleDayNames;
    },
    hours() {
      const hours = [];
      const numberOfHours = 24;
      for (let i = 0; i < numberOfHours; i += 1) {
        hours.push({
          label: (i < 10 ? "0" : "") + i,
          value: i,
        });
      }
      return hours.reverse();
    },

    minutes() {
      const minutes = [];
      for (let i = 0; i < 60; i += 1) {
        minutes.push({
          label: (i < 10 ? "0" : "") + i,
          value: i,
        });
      }
      return minutes.reverse();
    },
    minTime() {
      if (this.minDatetime) {
        if (
          this.minDatetime.getFullYear() === this.focusedDateData.year &&
          this.minDatetime.getMonth() === this.focusedDateData.month &&
          this.minDatetime.getDate() === this.focusedDateData.day
        ) {
          return this.minDatetime;
        }
      }
      return null;
    },
    maxTime() {
      if (this.maxDatetime) {
        if (
          this.maxDatetime.getFullYear() === this.focusedDateData.year &&
          this.maxDatetime.getMonth() === this.focusedDateData.month &&
          this.maxDatetime.getDate() === this.focusedDateData.day
        ) {
          return this.maxDatetime;
        }
      }
      return null;
    },
  },
  watch: {
    /**
     * Emit event when isActive value is changed.
     */
    isActive() {
      this.$nextTick(() => {
        this.updateAppendToBody();
      });
    },
    modelValue: {
      immediate: true,
      handler(newValue, old) {
        if (
          newValue &&
          old &&
          new Date(newValue).getTime() === new Date(old).getTime()
        )
          return;

        if (newValue) {
          this.dateSelected = new Date(newValue);
          this.assignDateToFocusedDateData(new Date(newValue));
        } else if (old && !newValue) {
          this.dateSelected = undefined;
        }
      },
    },
  },
  created() {
    if (typeof window !== "undefined") {
      document.addEventListener("click", this.clickedOutside);
    }
  },
  beforeUnmount() {
    if (typeof window !== "undefined") {
      document.removeEventListener("click", this.clickedOutside);
      // TODO: remove listener on unmount!
    }
  },
  methods: {
    onDateChangedInInput(date: Date) {
      this.assignDateToFocusedDateData(date);
      this.updateSelectedDate();
    },
    hideDropDown() {
      this.isActive = false;
    },
    showDropDown() {
      if (!this.disabled) {
        this.isActive = true;
        if (!this.modelValue) {
          if (this.defaultDate) {
            this.assignDateToFocusedDateData(new Date(this.defaultDate));
            this.updateSelectedDate();
          } else {
            const current = new Date();
            if (this.minDatetime.getTime() > current.getTime()) {
              this.assignDateToFocusedDateData(this.minDatetime);
              this.updateSelectedDate();
            } else {
              this.assignDateToFocusedDateData(current);
              this.updateSelectedDate();
            }
          }
        }
      }
    },
    menuClasses() {
      return {
        ["ui-drop__menu"]: true,
      };
    },
    updateAppendToBody() {
      const dropdownMenu: HTMLDivElement = this.$refs
        .dropdownMenu as HTMLDivElement;
      const rect = dropdownMenu.getBoundingClientRect();
      let top = -1 * rect.height;
      if (rect.y + top < 0) {
        if (this.$refs.dateTimePickerInput) {
          const dateTimePickerInput: HTMLDivElement = (
            this.$refs.dateTimePickerInput as typeof UiDateTimePickerInput
          ).$el as HTMLDivElement;
          top = dateTimePickerInput.getBoundingClientRect().height;
        }
      }
      dropdownMenu.style.position = "absolute";
      dropdownMenu.style.top = `${top}px`;
      dropdownMenu.style.left = `0px`;
      dropdownMenu.style.zIndex = "9999";
    },
    /**
     * White-listed items to not close when clicked.
     */
    isInWhiteList(el: EventTarget) {
      const dropDownMenu = this.$refs.dropdownMenu as HTMLDivElement;
      const trigger = this.$refs.trigger as HTMLDivElement;
      if (el === this.$refs.dropdownMenu) return true;
      if (el === this.$refs.trigger) return true;
      // All children from dropdown
      if (dropDownMenu) {
        const children = dropDownMenu.querySelectorAll("*");
        for (const child of children) {
          if (el === child) {
            return true;
          }
        }
      }
      // All children from trigger
      if (trigger) {
        const children = trigger.querySelectorAll("*");
        for (const child of children) {
          if (el === child) {
            return true;
          }
        }
      }
      return false;
    },
    /**
     * Close dropdown if clicked outside.
     */
    clickedOutside(event: MouseEvent) {
      if (!this.isInWhiteList(event.target as EventTarget)) {
        this.isActive = false;
      }
    },
    /**
     * Return month names according to a specified locale
     * @return {Array<String>} An array of month names
     */
    getMonthNames(): string[] {
      const dates = [];
      for (let i = 0; i < 12; i++) {
        dates.push(new Date(2000, i, 15));
      }
      const dtf = new Intl.DateTimeFormat(undefined, {
        month: "long",
      });
      return dates.map((d) => dtf.format(d));
    },
    /**
     * Return weekday names according to a specified locale
     * @return {Array<String>} An array of weekday names
     */
    getWeekdayNames(): string[] {
      const dates = [];
      for (let i = 1, j = 0; j < 7; i++) {
        const d = new Date(2000, 0, i);
        const day = d.getDay();
        if (day === 0 || j > 0) {
          dates.push(d);
          j++;
        }
      }
      const dtf = new Intl.DateTimeFormat(undefined, {
        weekday: "narrow",
      });
      return dates.map((d) => dtf.format(d));
    },
    /*
     * Either decrement month by 1 if not January or decrement year by 1
     * and set month to 11 (December) or decrement year when 'month'
     */
    prev() {
      if (this.showPrev) {
        if (this.focusedDateData.month > 0) {
          this.focusedDateData.month -= 1;
        } else {
          this.focusedDateData.month = 11;
          this.focusedDateData.year -= 1;
        }
      }
    },
    /*
     * Either increment month by 1 if not December or increment year by 1
     * and set month to 0 (January) or increment year when 'month'
     */
    next() {
      if (this.showNext) {
        if (this.focusedDateData.month < 11) {
          this.focusedDateData.month += 1;
        } else {
          this.focusedDateData.month = 0;
          this.focusedDateData.year += 1;
        }
      }
    },
    /*
     * Return array of all days in the week that the startingDate is within
     */
    weekBuilder(startingDate: number, month: number, year: number) {
      const thisMonth = new Date(year, month);
      const thisWeek = [];
      const dayOfWeek = new Date(year, month, startingDate).getDay();
      const end =
        dayOfWeek >= this.firstDayOfWeek
          ? dayOfWeek - this.firstDayOfWeek
          : 7 - this.firstDayOfWeek + dayOfWeek;
      let daysAgo = 1;
      for (let i = 0; i < end; i++) {
        thisWeek.unshift(
          new Date(
            thisMonth.getFullYear(),
            thisMonth.getMonth(),
            startingDate - daysAgo
          )
        );
        daysAgo++;
      }
      thisWeek.push(new Date(year, month, startingDate));
      let daysForward = 1;
      while (thisWeek.length < 7) {
        thisWeek.push(new Date(year, month, startingDate + daysForward));
        daysForward++;
      }
      return thisWeek;
    },
    /*
     * Check that selected day is within earliest/latest params and
     * is within this month
     */
    selectableDate(day: Date) {
      const validity = [];
      if (this.minDate) {
        validity.push(day >= this.minDate);
      }
      if (this.maxDate) {
        validity.push(day <= this.maxDate);
      }
      validity.push(day.getMonth() === this.focusedDateData.month);
      return validity.indexOf(false) < 0;
    },
    /*
     * Build cellClasses for cell using validations
     */
    cellClasses(day: Date) {
      function dateMatch(dateOne: any, dateTwo: any) {
        // if either date is null or undefined, return false
        // if using multiple flag, return false
        if (!dateOne || !dateTwo) {
          return false;
        }
        if (Array.isArray(dateTwo)) {
          return dateTwo.some(
            (date) =>
              dateOne.getDate() === date.getDate() &&
              dateOne.getFullYear() === date.getFullYear() &&
              dateOne.getMonth() === date.getMonth()
          );
        }
        return (
          dateOne.getDate() === dateTwo.getDate() &&
          dateOne.getFullYear() === dateTwo.getFullYear() &&
          dateOne.getMonth() === dateTwo.getMonth()
        );
      }

      return {
        "ui-dpck__table__cell": true,
        "ui-dpck__table__cell--selected": dateMatch(day, this.dateSelected),
        "ui-dpck__table__cell--today": dateMatch(day, new Date()),
        "ui-dpck__table__cell--selectable": this.selectableDate(day),
        "ui-dpck__table__cell--unselectable": !this.selectableDate(day),
      };
    },
    getDateFromFocusedDateData() {
      return new Date(
        this.focusedDateData.year,
        this.focusedDateData.month,
        this.focusedDateData.day,
        this.focusedDateData.hours,
        this.focusedDateData.minutes
      );
    },
    assignDateToFocusedDateData(date: Date) {
      this.focusedDateData = {
        year: date.getFullYear(),
        month: date.getMonth(),
        day: date.getDate(),
        hours: date.getHours(),
        minutes: date.getMinutes(),
      };
    },
    updateSelectedDate() {
      let newDate = this.getDateFromFocusedDateData();
      if (
        this.modelValue &&
        newDate.getTime() !== new Date(this.modelValue).getTime()
      ) {
        if (this.minDatetime && newDate < this.minDatetime) {
          newDate = new Date(this.minDatetime.getTime());
        }
        if (this.maxDatetime && newDate > this.maxDatetime) {
          newDate = new Date(this.maxDatetime.getTime());
        }
      }

      this.assignDateToFocusedDateData(newDate);
      this.dateSelected = newDate;
      this.$emit("update:modelValue", newDate);
    },
    daySelected(day: any) {
      if (this.dateSelected === day) return;
      this.focusedDateData = {
        ...this.focusedDateData,
        day: day.getDate(),
        month: day.getMonth(),
        year: day.getFullYear(),
      };
      this.updateSelectedDate();
    },
    increaseHour() {
      if (this.showIncreaseHour) {
        this.updateTime(true, true);
      }
    },
    decreaseHour() {
      if (this.showDecreaseHour) {
        this.updateTime(false, true);
      }
    },
    increaseMinutes() {
      if (this.showIncreaseMinutes) {
        this.updateTime(true, false);
      }
    },
    decreaseMinutes() {
      if (this.showDecreaseMinutes) {
        this.updateTime(false, false);
      }
    },
    updateTime(increase: boolean, hours: boolean) {
      let newDate = new Date();
      if (this.dateSelected) {
        newDate = this.dateSelected;
      } else {
        newDate = this.getDateFromFocusedDateData();
      }
      if (increase) {
        if (hours) {
          newDate.setHours(newDate.getHours() + 1);
        } else {
          newDate.setMinutes(newDate.getMinutes() + 1);
        }
      } else {
        if (hours) {
          newDate.setHours(newDate.getHours() - 1);
        } else {
          newDate.setMinutes(newDate.getMinutes() - 1);
        }
      }
      this.assignDateToFocusedDateData(newDate);
      this.updateSelectedDate();
    },
    isHourDisabled(hour: number) {
      let disabled = false;
      if (this.minTime) {
        const minHours = this.minTime.getHours();
        const noMinutesAvailable = this.minutes.every((minute) => {
          return this.isMinuteDisabledForHour(hour, minute.value);
        });
        disabled = hour < minHours || noMinutesAvailable;
      }
      if (this.maxTime) {
        if (!disabled) {
          const maxHours = this.maxTime.getHours();
          disabled = hour > maxHours;
        }
      }
      return disabled;
    },

    isMinuteDisabledForHour(hour: number, minute: number) {
      let disabled = false;
      if (this.minTime) {
        const minHours = this.minTime.getHours();
        const minMinutes = this.minTime.getMinutes();
        disabled = hour === minHours && minute < minMinutes;
      }
      if (this.maxTime) {
        if (!disabled) {
          const maxHours = this.maxTime.getHours();
          const maxMinutes = this.maxTime.getMinutes();
          disabled = hour === maxHours && minute > maxMinutes;
        }
      }
      return disabled;
    },

    isMinuteDisabled(minute: number) {
      let disabled = false;

      if (this.isHourDisabled(this.focusedDateData.hours)) {
        disabled = true;
      } else {
        disabled = this.isMinuteDisabledForHour(
          this.focusedDateData.hours,
          minute
        );
      }

      return disabled;
    },
  },
});
</script>

<style scoped>
.ui-drop {
  display: inline-flex;
  position: relative;
  vertical-align: top;
  &__disabled {
    @apply cursor-not-allowed;
  }
  &__trigger {
    width: 100%;
  }
  &__menu {
    position: absolute;
    left: 0;
    top: 100%;
    display: block;
    min-width: 12rem;
    z-index: 20;
    background-color: #272727;
    border-radius: 4px;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1);
    margin: 0;
  }
  &__item {
    display: flex;
    position: relative;
    outline: none;
    color: #000;
    font-size: 12px;
    font-weight: 400;
    line-height: 10px;
    padding: 0.375rem;
  }
}

.ui-dpck {
  &__box {
    display: block;
    position: relative;
    outline: none;
    line-height: 12px;
  }
  &__header {
    padding: 0 0 0.375rem;
    margin: 0 0 0.375rem;
    border-bottom: 1px solid black;
    &__buttons {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      text-align: center;
    }
    &__previous,
    &__next {
      @apply rounded-md;
      justify-content: center;
      cursor: pointer;
      display: inline-flex;
      &__disabled {
        opacity: 0.4;
        cursor: default;
      }
    }
    &__previous {
      order: 1;
      margin-right: 8px;
    }
    &__next {
      order: 3;
      margin-left: 8px;
    }
    &__list {
      order: 2;
      align-items: center;
      display: flex;
      justify-content: center;
      text-align: center;
      list-style: none;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
    }
  }

  &__table {
    display: table;
    border-right: 1px solid #000;

    &__body {
      display: table-row-group;
    }

    &__row {
      display: table-row;
    }

    &__head-cell {
      color: #aaa;
      font-weight: 700;
    }

    &__cell {
      text-align: center;
      vertical-align: middle;
      display: table-cell;
      text-decoration: none;
      border-radius: 4px;
      width: 1.875rem;
      height: 1.625rem;

      &--unselectable {
        color: rgba(180, 180, 180, 0.4);
        font-weight: 200;
      }

      &--today {
        span {
          border-radius: 4px;
          padding: 0.225rem 0.375rem;
          border: solid 1px rgba(255, 165, 0, 0.5);
        }
      }

      &--selectable {
        color: white;
        font-weight: 400;
        :hover:not(.ui-dpck__table__cell--selected) {
          font-weight: 800;
        }
      }

      &--selected {
        background-color: orange;
        color: black;
      }

      &--invisible {
        visibility: hidden;
      }
    }
  }
  &__footer {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &-column {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__increase,
    &__decrease {
      @apply rounded-md;
      justify-content: center;
      cursor: pointer;
      display: inline-flex;
      &-disable {
        opacity: 0.4;
        cursor: default;
      }
    }
    &__increase {
      margin-bottom: 4px;
    }
    &__decrease {
      margin-top: 4px;
    }
  }
}

.ui-tpck {
  &__select {
    -moz-appearance: none;
    -webkit-appearance: none;
    display: inline-block;
    position: relative;
    vertical-align: top;
    cursor: pointer;
    justify-content: flex-start;
    align-items: center;
    outline: none;
    border: 0;
    font-size: inherit;
    font-weight: 600;
    margin: 0 0.375rem 0 0.375rem;
    color: #363636;
  }
  &__select-placeholder {
    opacity: 1;
  }
  &__separator {
    font-weight: 600;
    color: white;
  }
}
</style>
