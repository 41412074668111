import {Loader} from "@googlemaps/js-api-loader";

let loader: Loader;
// is necessary to prevent errors during the build process

function resolveCoordinates(lat: number, lng: number): Promise<string> {
  return new Promise((resolve, reject) => {
    loader.load().then(() => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({location: {lat, lng}}, (results, status) => {
        if (status === "OK") {
          if (results![0]) {
            resolve(results![0].formatted_address);
          } else {
            reject("No results found");
          }
        } else {
          reject("Geocoder failed due to: " + status);
        }
      });
    });
  });
}

export default function useGoogleAPI() {
  loader = new Loader({
    apiKey: import.meta.env.VITE_GOOGLE_API_KEY as string,
    libraries: ["places"]
  });

  return {
    maps: {
      resolveCoordinates
    }
  };
};
