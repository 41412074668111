import gql from "graphql-tag";

export const getAllEventsByEventSeriesId = gql`
  query frontend_event_getAllEventsByEventSeriesId(
    $eventSeriesId: uuid!
    $orderBy: [Event_order_by!]
    $limit: Int
    $offset: Int
  ) {
    Event(
      offset: $offset
      limit: $limit
      where: { eventSeriesId: { _eq: $eventSeriesId } }
      order_by: $orderBy
    ) {
      id
      title
      locationAlias
      startTime
      cancelled
      endTime
      EventSeries {
        id
        logoId
        name
      }
      Promotion {
        id
        eventId
      }
    }
    Event_aggregate(where: { eventSeriesId: { _eq: $eventSeriesId } }) {
      aggregate {
        count
      }
    }
  }
`;

export const getAllEventsOfAnOrganizer = gql`
  query frontend_event_getAllEventsOfAnOrganizer(
    $organizerId: uuid!
    $orderBy: [Event_order_by!]
    $limit: Int
    $offset: Int
  ) {
    Event(
      offset: $offset
      limit: $limit
      where: { EventSeries: { organizerId: { _eq: $organizerId } } }
      order_by: $orderBy
    ) {
      id
      locationAlias
      title
      startTime
      cancelled
      endTime
      EventSeries {
        id
        logoId
        name
      }
      Promotion {
        id
        eventId
      }
    }
    Event_aggregate(
      where: { EventSeries: { organizerId: { _eq: $organizerId } } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const eventTable_promoteEvent = gql`
  mutation frontend_eventTable_promoteEvent($eventId: uuid!) {
    purchaseEventPromotion(eventId: $eventId) {
      affected_rows
    }
  }
`;

export const eventTable_deleteEventPromotion = gql`
  mutation frontend_eventTable_deleteEventPromotion(
    $id: uuid!
    $eventId: uuid!
  ) {
    delete_EventPromotion_by_pk(eventId: $eventId, id: $id) {
      id
      eventId
    }
  }
`;

export const eventTableFetchPromotionData = gql`
  query frontend_eventTable_fetch_promotion_data(
    $productId: uuid!
    $organizerId: uuid!
  ) {
    Product_by_pk(id: $productId) {
      price
    }
    Organizer_by_pk(id: $organizerId) {
      OrganizerAccountBalance {
        balance
      }
    }
  }
`;
