<template lang="pug">
//preview
.frame
  .preview-header
    p(v-if="name.length > 0") {{ name.length > 20 ? name.substring(0, 20).toUpperCase() + "..." : name.toUpperCase() }}
    p(v-else) Event Series Title

  .banner-logo-wrapper
    .banner
      img(v-if="bannerUri", :src="bannerUri")
      p(v-else-if="create") Event Series Banner Placeholder
      p(v-else) Loading Animation

    .logo
      img(v-if="logoUri", :src="logoUri")
      p(v-else-if="create") Logo
      p(v-else) Loading Animation

  .description
    p(v-if="description") {{ description }}
    p(v-else-if="create") Event Series description will be shown here

  .social-media
    img(
      v-if="facebookLink",
      src="../../../assets/socialMedia/facebook-square-brands.svg"
    )
    img(
      v-if="instagramLink",
      src="../../../assets/socialMedia/instagram-square-brands.svg"
    )

  .divider

  EventSeriesImageSlider.mt-5(:gallery-images="galleryImages")
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import EventSeriesImageSlider from "../ImageSlider/ImageSlider.vue";

export default defineComponent({
  name: "EventSeriesAppPreview",
  components: { EventSeriesImageSlider },
  props: {
    id: { type: String, required: false, default: "" },
    name: { type: String, required: true },
    bannerUri: { type: String, required: true },
    logoUri: { type: String, required: true },
    description: { type: String, required: true },
    facebookLink: { type: String, required: true },
    instagramLink: { type: String, required: true },
    galleryImages: {
      type: Array as PropType<{ imageId?: string; imageUri?: string }[]>,
      required: true,
    },
  },
  data: () => ({
    create: true,
  }),
});
</script>

<style lang="postcss" scoped>
/*
 * place it extra because otherwise declaration needs to be done
 * multiple times
 */

.frame {
  @apply flex flex-col items-center relative text-center h-full w-full overflow-auto no-scrollbar;
  padding: 30px 0 30px 0;
  background-color: #1a1a1a;
  border-radius: 2.6em;
}

.frame > div {
  flex: 1 0 auto;
}

.preview-header {
  @apply flex;

  & > p {
    @apply flex justify-center font-medium items-center mt-1 mb-1;
  }
}

.banner-logo-wrapper {
  @apply flex flex-col items-center justify-center;

  .banner {
    @apply flex justify-start items-center;
    @apply w-full;
    aspect-ratio: 19/11;
    background-color: #272727;

    & > img {
      @apply object-cover;
    }

    & > p {
      @apply text-text-default font-bold text-lg;
    }
  }

  .logo {
    @apply bg-gradient-pingr;
    @apply flex justify-center items-center rounded-full;
    @apply w-20 h-20 -mt-10;
    aspect-ratio: 1/1;

    & > p {
      @apply text-text-dark font-bold text-lg;
    }

    & > img {
      @apply rounded-full w-11/12;
    }
  }
}

.description {
  @apply text-center;
  overflow-wrap: anywhere;

  & p {
    @apply text-sm font-medium whitespace-pre-line mx-4 my-4;
    overflow-wrap: anywhere;
  }
}

.social-media {
  @apply flex justify-center items-center;
  @apply mx-auto;
  @apply w-full;

  & > img {
    @apply w-9 m-1;
  }
}

.upcoming {
  @apply mt-5 text-2xl font-bold;
  @apply flex justify-center items-center;
}

/*  .tags {
    @apply mt-5 text-2xl font-bold;
    @apply flex flex-col justify-center items-center;

    .primary-tags {
      @apply flex mt-5;
      @apply w-9 h-9;

      & > img {
      }
    }

    .secondary-tags {
      @apply flex mt-5;
      .secondary-tag {
        @apply text-xs;
        @apply flex justify-center items-center mx-2;
        @apply rounded-xl;
      }
    }
  }*/

/*:root {
  --primary: "#FFA900";
  --secondary: "#FFE400";
  --accent: "#FD0000";
  --background: "#1A1A1A";
  --lightgrey: "#707070";
  --darkgrey: "#343434";
  --blue: "#007EFD";
}

.frame {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px 0 30px 0;
  border-radius: 6px;
  background-color: #1a1a1a;
  width: 315px;
  height: 560px;
  border: solid 1px #343434;
  overflow: auto;
  -ms-overflow-style: none;
  -webkit-box-shadow: 0 40px 45px -20px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0 40px 45px -20px rgba(0, 0, 0, 0.55);
  box-shadow: 0 40px 45px -20px rgba(0, 0, 0, 0.55);
}

.frame:hover {
  border: solid 1px #ffa900;
}

.frame > div {
  flex: 1 0 auto;
}

.frame::-webkit-scrollbar {
  display: none;
}

.preview-header {
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 95%;
  margin-bottom: 10px;
}

.preview-header > p {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  width: 100%;
  margin-top: 0.4%;
}

.banner {
  display: flex;
  flex: 4;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
  background-color: #fff;
  object-fit: cover;
  margin-top: 0;
  border-width: 2px 0 2px 0;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: linear-gradient(
    90deg,
    rgba(253, 29, 29, 1) 0%,
    rgba(252, 176, 69, 1) 100%
  );
  height: 110px;
  padding: 3px;
  width: 110px;
  margin-top: -18%;
}

.logo > p {
  color: #1a1a1a;
}

.description {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  flex: 3;
}

.description > p {
  margin: 20px 30px 20px 30px;
  font-weight: 500;
  font-size: 14px;
  white-space: pre-line;
}

.socialMedia {
  margin-top: 15px;
}

.socialMedia > img {
  margin: 5px;
}

.upcoming,
.tags {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upcoming {
  margin-top: 20px;
}

.tags {
  margin-top: 30px;
}

.upcoming > p,
.tags > p {
  font-size: 24px;
  font-weight: bold;
}

.banner p {
  font-size: 20px;
  font-weight: 500;
  color: #1a1a1a;
}

.frame p {
  font-family: "Rubik", sans-serif;
}

.primaryTags {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.left {
  margin-right: 8px;
}

.right {
  margin-left: 8px;
}

p.description {
  margin: 20px 30px 20px 30px;
  font-weight: 500;
  font-size: 14px;
  white-space: pre-line;
}

.divider {
  height: 4px;
  width: 29px;
  background: #ffa900;
  border-radius: 40px;
  margin: 15px 0 25px 0;
}

.images {
  margin-top: 30px;
  width: 100%;
}*/
</style>
