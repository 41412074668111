<template lang="pug">
.frame
  .banner(v-if="event.cover")
    img.banner-image(
      v-if="event.cover",
      alt="Event Banner",
      :src="event.cover"
    )
  .banner(v-else)
    p Event Banner

  .time(style="display: flex")
    p.date.start-time {{ event.startTime ? parseDate(event.startTime) : "Start Time" }}
    p.date {{ "-" }}
    p.date.end-time {{ event.endTime ? parseDate(event.endTime) : "End Time" }}

  p.title {{ event.title ? event.title : "Event Name" }}
  p.location.underline {{ event.locationAlias ? event.locationAlias : "Event Location" }}
  .divider
  p.description {{ event.description ? event.description : "Event description will be shown here." }}
  .divider
  PrimaryTagIcon.primary-tag-icon(
    v-if="event.primaryTagId",
    :id="event.primaryTagId"
  )
  .sub-categories-container
    SecondaryTagVisualization(
      v-for="tag in event.secondaryTags",
      :key="tag.id",
      :name="tag.name",
      :is-clickable="false"
    )
  p.description(
    v-if="event.secondaryTags && event.secondaryTags.length === 0 && !event.primaryTagId"
  ) Main Category and Secondary Categories will be shown here.
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { EventPreviewDto } from "./types";
import SecondaryTagVisualization from "../../SecondaryTag/Visualization/SecondaryTagVisualization.vue";
import PrimaryTagIcon from "../../PrimaryTag/Icon/PrimaryTagIcon.vue";
import { formatDate } from "../../../utils/dateFormatter";

export default defineComponent({
  name: "EventPreview",
  components: { PrimaryTagIcon, SecondaryTagVisualization },
  props: {
    event: {
      type: Object as PropType<EventPreviewDto>,
      required: true,
      default: () => ({} as EventPreviewDto),
    },
  },
  methods: {
    parseDate(date: Date | string) {
      if (typeof date == "string") {
        return formatDate(date, "EEEEEE, dd.MM.yyyy HH:mm");
      } else {
        return formatDate(date.toISOString(), "EEEEEE, dd.MM.yyyy HH:mm");
      }
    },
  },
});
</script>

<style scoped>
.frame {
  @apply flex flex-col items-center	relative text-center h-full w-full overflow-auto;
  padding: 20px 0 30px 0;
  background-color: #1a1a1a;
  border-radius: 2.6em;
  -ms-overflow-style: none;
}

.frame > div {
  flex: 1 0 auto;
}

.frame::-webkit-scrollbar {
  display: none;
}

.banner {
  @apply flex items-center justify-center w-full;
    @apply h-32;
  /* background-color: #272727; */
  /* clip-path: ellipse(100% 50% at 50% 50%); */
  --screen-width: 18rem;
  /* height: calc(var(--screen-width) / 9 * 16); */
  /* border-radius: calc((var(--screen-width) + 320) / 2); */
  /*&-image {
    width: var(--screen-width);
    height: calc(var(--screen-width) / 9 * 16);
  }*/
}

.banner p {
  @apply text-xl font-medium;
}

.frame p {
  font-family: "Rubik", sans-serif;
}

p.date {
  margin-top: 25px;
  font-size: 0.65rem;
}
.start-time {
  @apply mr-1;
}

.end-time {
  @apply ml-1;
}

p.title {
  @apply font-extrabold text-lg mx-4 my-4;
  overflow-wrap: anywhere;
}

p.location {
  @apply mb-5;
  color: #707070;
  font-size: 0.65rem;
  letter-spacing: 1px;
}

p.description {
  @apply text-sm font-medium whitespace-pre-line mx-4 my-4;
  overflow-wrap: anywhere;
}

.sub-categories-container {
  @apply flex flex-wrap	justify-center mx-5 mt-2.5;
}

.divider {
  height: 4px;
  width: 29px;
  background: #ffa900;
  border-radius: 40px;
}

.primary-tag-icon {
  @apply mt-3;
}
</style>
