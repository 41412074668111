<template lang="pug">
.wrapper
  .circle-loader(
    :class="[size, { 'load-complete': isChecked, primary: isPrimary, secondary: !isPrimary }]"
  )
    .checkmark.draw(v-if="isChecked")
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoadingAnimation",
  props: {
    isChecked: { type: Boolean, required: false, default: false },
    isPrimary: { type: Boolean, required: false, default: false },
    size: {
      type: String,
      required: false,
      default: "lg",
      validator: (value: string) => {
        // The value must match one of these strings
        return ["sm", "md", "lg"].includes(value);
      },
    },
  },
});
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  --loader-size: 25px;
  --check-height: var(--loader-size) / 2;
  --check-width: var(--check-height) / 2;
  --check-left: calc(var(--loader-size) / 6 + var(--loader-size) / 12) - 1px;
  --check-thickness: 1px;
}

.sm {
  --loader-size: 12px;
}

.md {
  --loader-size: 18px;
}

.lg {
  --loader-size: 25px;
}

.secondary {
  --check-color: #5cb85c;
}

.primary {
  --check-color: #ffa800;
}

.circle-loader {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: var(--check-color);
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  border-radius: 50%;
  width: var(--loader-size);
  height: var(--loader-size);
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: var(--check-color);
  transition: border 500ms ease-out;
}

.checkmark {
  &.draw:after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
  }

  &:after {
    opacity: 1;
    height: var(--check-height);
    width: var(--check-width);
    transform-origin: left top;
    border-right: var(--check-thickness) solid var(--check-color);
    border-top: var(--check-thickness) solid var(--check-color);
    content: "";
    left: var(--check-left);
    top: var(--check-height);
    position: absolute;
  }
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: var(--check-width);
    opacity: 1;
  }
  40% {
    height: var(--check-height);
    width: var(--check-width);
    opacity: 1;
  }
  100% {
    height: var(--check-height);
    width: var(--check-width);
    opacity: 1;
  }
}
</style>
