<template lang="pug">
.balance-wrapper.ml-8.mt-2
  .flex.flex-row
    .flex.flex-col
      p.text-lg Credits
      p.text-xl.font-bold.mt-1 {{ formatNumberValue(organizerBalance) }}
    UiButton.ml-20.mt-auto.mb-auto(
      style-ids="action",
      @click="showTransferModal('deposit')"
    ) Hinzufügen
    //UiButton.ml-4.mt-auto.mb-auto(
      //style-ids="highlight",
      //@click="showTransferModal('withdraw')"
    //) Abbuchen
.divider
.organizer-table
  UiTable(
    :data="transactionList",
    :loading="loading",
    :total="count",
    :defaultSort="['startTime', 'desc']",
    :currentPage="paginationOffset + 1",
    :rowsPerPage="rowsPerPage",
    @page-change="pageChange",
    @update:rowsPerPage="rowsPerPageChanged",
    @sort="setSortDirection",
    :hoverable="false"
  )
    template(#caption)
      span.caption {{ title }}

    UiTableColumn(
      label="Datum",
      position="left",
      v-slot="props",
      :sortable="true",
      field="createdAt"
    )
      p {{ parseDate(props.row.createdAt) }}

    UiTableColumn(
      label="Beschreibung",
      position="left",
      v-slot="props",
      field="amount"
    )
      p {{ props.row.description }}

    UiTableColumn(
      label="Betrag",
      position="right",
      v-slot="props",
      :sortable="true",
      field="amount"
    )
      p(
        :class="{ 'text-background-action': props.row.action === 'DEPOSIT', 'text-background-highlight': props.row.action === 'WITHDRAW' }"
      ) {{ props.row.action === "DEPOSIT" ? "+" : "-" }}{{ formatNumberValue(props.row.amount) }}

    template(v-slot:empty-table) {{ "Für diesen Veranstalter liegen noch keine Transaktionen vor." }}
UiModal.modal-transfer(v-if="transferModalVisible", @close="hideTransferModal")
  template(#header)
    p.modal-transfer-title {{ `${modalTransactionMode === "deposit" ? "Überweisung an" : "Abbuchung von"} SoHO` }}
  template(#content)
    .w-full.flex.flex-col
      .summary.w-full
        UiMessage(type="warning", :active="true", :closable="false")
          .flex.flex-row
            p.modal-promotion-content-text {{ `${modalTransactionMode === "deposit" ? "Überweisungen" : "Abbuchungen"} können nicht rückgängig gemacht werden. Bitte vergewisser' dich, dass alle Daten korrekt eingegeben wurden.` }}
        .flex.flex-col.mt-6
          p {{ `Folgender Betrag wird SoHo ${modalTransactionMode === "deposit" ? "gutgeschrieben" : "abgebucht"}:` }}
          label.mt-2.currency-label(
            aria-label="Enter the amount in €",
            data-currency="€"
          )
            input.currency-input(
              type="text",
              v-model="modalCreditValueInput",
              @blur="formatCreditValue",
              @keyup="formatCreditValue",
              autocomplete="off"
            )
        .flex.flex-row.mt-1
          p Aktuelles Guthaben:
          p(
            style="margin-left: auto",
            :class="{ 'text-background-highlight': false }"
          ) {{ `${formatNumberValue(organizerBalance)} Credits` }}
        .flex.flex-row.mt-1
          p(
            style="margin-left: auto",
            :class="{ 'text-background-highlight': false }"
          ) {{ `+ ${formatNumberValue(Number(modalCreditAmount))} Credits` }}
        .flex.flex-row.mt-3
          p Guthaben danach:
          p(
            style="margin-left: auto",
            :class="{ 'text-background-highlight': modalTransactionMode === 'withdraw' && organizerBalance - Number(modalCreditAmount) < 0 }"
          ) {{ `${modalTransactionMode === "deposit" ? formatNumberValue(Number(organizerBalance) + Number(modalCreditAmount)) : formatNumberValue(Number(organizerBalance) - Number(modalCreditAmount))} Credits` }}
        p.mt-3 Überweisungsreferenz:
        UiTextInput.mt-1(
          style-ids="rounded-border",
          v-model="modalTransactionDescription",
          autocomplete="off"
        )

  template(#footer)
    .modal-transfer-footer
      UiButton.modal-transfer-button(
        @click="hideTransferModal",
        style-ids="subliminal"
      ) Abbrechen
      UiButton(
        @click="transferCreditsClicked",
        :style-ids="{ action: true }",
        :disabled="!modalTransactionTransferButtonEnabled"
      ) {{ `Credits ${modalTransactionMode === "deposit" ? "überweisen" : "abbuchen"}` }}
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useMutation, useQuery } from "@vue/apollo-composable";
import UiButton from "../../../ui/Button/UiButton.vue";
import UiTable from "../../../ui/Table/UiTable.vue";
import { getTransactionsAndBalanceOfOrganizer, insertDeposit } from "./queries";
import {
  InsertTransactionResponse,
  OrganizerTransactionsResponse,
} from "./types";
import { formatDate } from "../../../utils/dateFormatter";
import UiTableColumn from "../../../ui/Table/UiTableColumn.vue";
import UiModal from "../../../ui/Modal/UiModal.vue";
import UiTextInput from "../../../ui/TextInput/UiTextInput.vue";
import UiMessage from "../../../ui/Message/UiMessage.vue";
import { TYPE, useToast } from "vue-toastification";
import { formatNumber } from "../../../utils/numberFormatter";

export default defineComponent({
  name: "OrganizerBalance",
  components: {
    UiTextInput,
    UiButton,
    UiTable,
    UiTableColumn,
    UiModal,
    UiMessage,
  },
  props: {
    organizerId: {
      type: String,
      default: "",
      required: true,
    },
  },
  setup(props: { organizerId: string }) {
    const toast = useToast();
    let orderBy = ref<{ [key: string]: string }>({ createdAt: "desc" });
    const offset = ref(0);
    const rowsPerPage = ref(5);

    const { result, loading, error, onResult } =
      useQuery<OrganizerTransactionsResponse>(
        getTransactionsAndBalanceOfOrganizer,
        () => ({
          organizerId: props.organizerId,
          orderBy: orderBy.value,
          limit: rowsPerPage.value,
          offset: offset.value * rowsPerPage.value,
        }),
        () => ({
          fetchPolicy: "cache-and-network",
          refetchWritePolicy: "overwrite",
        })
      );

    const setSortDirection = (field: string, sortDirection: any) => {
      const [last, ...paths] = field.split(".").reverse();
      orderBy.value = paths.reduce((acc, el) => ({ [el]: acc }), {
        [last]: sortDirection,
      });
    };

    const setOffset = (newOffset: number) => {
      offset.value = newOffset;
    };

    const setRowsPerPage = (newRowsPerPage: number) => {
      rowsPerPage.value = newRowsPerPage;
    };

    const { mutate: insertTransaction } =
      useMutation<InsertTransactionResponse>(insertDeposit, {
        refetchQueries: [
          {
            query: getTransactionsAndBalanceOfOrganizer,
            variables: {
              organizerId: props.organizerId,
              orderBy: orderBy.value,
              limit: rowsPerPage.value,
              offset: offset.value * rowsPerPage.value,
            },
          },
        ],
      });

    return {
      transactionList: computed(
        () => result.value?.OrganizerAccountTransactions ?? []
      ),
      count: computed(
        () =>
          result.value?.OrganizerAccountTransactions_aggregate.aggregate
            .count ?? 0
      ),
      organizerBalance: computed(
        () => result.value?.OrganizerAccountBalance_by_pk.balance ?? 0
      ),
      loading,
      error,
      orderBy,
      paginationOffset: offset,
      rowsPerPage,
      setSortDirection,
      setOffset,
      setRowsPerPage,
      insertTransaction,
      toast,
    };
  },
  data() {
    return {
      title: "Transaktionsverlauf",
      transferModalVisible: false,
      modalCreditValueInput: "",
      modalCreditAmount: 0,
      modalTransactionDescription: "",
      modalTransactionMode: "deposit",
    };
  },
  computed: {
    modalTransactionTransferButtonEnabled() {
      return this.modalCreditValueInput && this.modalTransactionDescription;
    },
  },
  methods: {
    transferCreditsClicked() {
      this.insertTransaction({
        organizerId: this.organizerId,
        description: this.modalTransactionDescription,
        amount: this.modalCreditAmount,
      })
        .then(() => {
          this.hideTransferModal();
          this.toast("Die Überweisung wurde erfolgreich durchgeführt.", {
            type: TYPE.SUCCESS,
          });
        })
        .catch(() => {
          this.hideTransferModal();
          this.toast("Bei der Überweisung ist ein Fehler aufgetreten.", {
            type: TYPE.ERROR,
          });
        });
    },
    showTransferModal(mode: string) {
      this.modalTransactionMode = mode;
      this.transferModalVisible = true;
    },
    hideTransferModal() {
      this.modalCreditValueInput = "";
      this.modalTransactionDescription = "";
      this.transferModalVisible = false;
      this.modalCreditAmount = 0;
    },
    rowsPerPageChanged(rowsPerPage: number) {
      this.setRowsPerPage(rowsPerPage);
    },
    pageChange(pageNumber: number) {
      this.setOffset(pageNumber - 1);
    },
    parseDate(date: string) {
      return formatDate(date, "dd.MM.yyyy HH:mm");
    },
    formatCreditValue() {
      this.modalCreditValueInput = formatNumber(this.modalCreditValueInput);

      this.modalCreditAmount =
        10 * Number(this.modalCreditValueInput.replace(/[.,]*/g, ""));
    },
    formatNumberValue(value: number | string) {
      return formatNumber(value);
    },
  },
});
</script>

<style scoped>
.caption {
  @apply float-left text-base font-black mb-5;
}
.divider {
  @apply mx-4 h-0.5 bg-background-contrast mt-2;
}
.organizer-table {
  @apply ml-8 mt-8;
}
.modal-transfer {
  :deep .modal {
    max-width: 60%;
  }
  @media (min-width: 1200px) {
    :deep .modal {
      max-width: 40%;
    }
  }
  @media (min-width: 1700px) {
    :deep .modal {
      max-width: 30%;
    }
  }
  &-title {
    @apply text-lg font-bold;
  }
  &-content {
    &-text {
      @apply select-text mb-3;
    }
    &-input {
      @apply w-8/12;
    }
  }
  &-button {
    @apply mr-4;
  }
  &-footer {
    @apply flex w-full justify-start;
  }
}

.currency-label {
  @apply inline-flex overflow-hidden rounded-md text-background-subliminal bg-background-input;
}

.currency-label:before {
  content: attr(data-currency);
  @apply py-1	w-10 text-center text-xl text-text-subliminal;
  border-right: 1px #171717 solid;
}

.currency-input {
  @apply bg-background-input w-full pl-4 text-background-bright;
  &:focus {
    @apply outline-none;
  }
}
</style>
