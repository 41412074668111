export const formatNumber = (inputNumber: string | number) => {
  let negativeNumber = false;
  if (typeof inputNumber === "number") {
    negativeNumber = inputNumber < 0;
  }
  const formattedNumber = String(inputNumber)
    .replace(/\D/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return negativeNumber ? "-" + formattedNumber : formattedNumber;
};
