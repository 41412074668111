<template lang="pug">
UiModal.loading-overlay-modal
  template(#content)
    .wrapper
      .img-wrapper
        img.location-icon(src="../../assets/icons/locationPicker.png")
      span.description
        slot
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UiModal from "../Modal/UiModal.vue";

export default defineComponent({
  name: "UiLoadingOverlay",
  components: { UiModal },
});
</script>

<style scoped>
.loading-overlay-modal:deep .modal {
  min-width: 250px;
  max-width: 400px;
  width: 25%;
  height: 35%;
  @apply p-0;
}

.loading-overlay-modal:deep .modal-body {
  @apply h-full w-full m-0 p-0;
}

.wrapper {
  @apply flex flex-col w-full h-full items-center justify-center;
}

.img-wrapper {
  @apply animate-pulse;
  width: 66px;
  height: 80px;
}

.location-icon {
  @apply w-full h-full;
}

.description {
  @apply mt-2 text-center;
}
</style>
