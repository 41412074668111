import { Point } from "geojson";
import { SecondaryTag } from "../../SecondaryTag/AutoComplete/types";

export interface PublicEventCreate {
  __typename?: string;
  id: string;
  startTime: Date | string;
  endTime: Date | string;
  title: string;
  description: string;
  eventSeriesId: string;
  primaryTagId: string;
  locationAlias: string;
  location: Point;
  ticketLink?: string;
  cancelled?: boolean;
  secondaryTags: SecondaryTag[];
  SecondaryTags?: {
    __typename?: string;
    SecondaryTag: SecondaryTag;
  }[];
  cover: string;
  EventSeries: {
    id: string;
    name: string;
  };
}

export enum EventFormMode {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
}

export enum EventImageType {
  EVENT_COVER = "EVENT_COVER",
}
