import gql from "graphql-tag";

export const ScraperGetNextEventQuery = gql`
  query frontend_Scraper_GetNextScrapedEvents($offset: Int!, $limit: Int) @cached(refresh: true) {
    getNextScrapedEvents(offset: $offset, limit: $limit) {
      total
      event {
        id
        title
        description
        url
        cover
        location
        isCancelled
        startTime
        eventSeriesId
        Organizer {
          id
          name
          EventSeries {
            id
            name
          }
        }
        EventSeries {
          id
          name
          bannerId
          logoId
          facebookLink
          instagramLink
          GalleryImages {
            imageId
          }
        }
        old
      }
    }
  }
`;

export const ScraperCreateEvent = gql`
  mutation frontend_Scraper_CreateEvent(
    $id: String!
    $title: String!
    $description: String!
    $eventSeriesId: uuid!
    $startTime: timestamptz!
    $primaryTagId: uuid!
    $secondaryTags: [CreatePublicEventSecondaryTag!]!
    $endTime: timestamptz
    $ticketLink: String
    $location: geography!
    $locationAlias: String!
    $cover: String!
  ) {
    createScrapedEvent(
      input: {
        id: $id
        title: $title
        description: $description
        eventSeriesId: $eventSeriesId
        startTime: $startTime
        primaryTagId: $primaryTagId
        secondaryTags: $secondaryTags
        endTime: $endTime
        ticketLink: $ticketLink
        location: $location
        locationAlias: $locationAlias
        cover: $cover
      }
    ) {
      id
    }
  }
`;
