import gql from "graphql-tag";

export const adminForm_loadUser = gql`
  query frontend_adminForm_loadUser($id: uuid!) {
    AdminUser_by_pk(id: $id) {
      id
      firstName
      lastName
      firebaseId
      email
      Role {
        role
      }
    }
  }
`;

export const adminForm_insert = gql`
  mutation frontend_adminForm_insert(
    $firstName: String!
    $lastName: String!
    $email: String!
    $permissions: [AdminUserAdminRole_insert_input!]!
  ) {
    insert_AdminUser_one(
      object: {
        firstName: $firstName
        email: $email
        lastName: $lastName
        Role: { data: $permissions }
      }
    ) {
      updatedAt
      lastName
      id
      firstName
      firebaseId
      email
      createdAt
      Role {
        role
      }
    }
  }
`;

export const adminForm_delete = gql`
  mutation frontend_adminForm_delete($id: uuid!) {
    delete_AdminUser_by_pk(id: $id) {
      id
    }
  }
`;

export const adminForm_resendPasswordMail = gql`
  mutation frontend_adminForm_resendPasswordMail($firebaseId: String!) {
    resendPasswordMail(firebaseId: $firebaseId) {
      affected_rows
    }
  }
`;

export const adminForm_update = gql`
  mutation frontend_adminForm_insert(
    $id: uuid!
    $firstName: String!
    $lastName: String!
    $permissions: [AdminUserAdminRole_insert_input!]!
  ) {
    delete_AdminUserAdminRole(where: { adminUserId: { _eq: $id } }) {
      affected_rows
    }
    insert_AdminUserAdminRole(objects: $permissions) {
      affected_rows
    }
    update_AdminUser_by_pk(
      pk_columns: { id: $id }
      _set: { firstName: $firstName, lastName: $lastName }
    ) {
      updatedAt
      lastName
      id
      firstName
      firebaseId
      email
      createdAt
      Role {
        role
      }
    }
  }
`;
