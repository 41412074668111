import gql from "graphql-tag";

export const GetAllOrganizer = gql`
  query frontend_OrganizerSelect_getAllOrganizer {
    Organizer(order_by: { publicName: asc }) {
      id
      publicName
    }
  }
`;
