import gql from "graphql-tag";

export const loadOrganizerOverview = gql`
  query frontend_organizer_loadOrganizerOverview(
    $timeNow: timestamptz
    $orderBy: [Organizer_order_by!]
    $limit: Int
    $offset: Int
    $where: Organizer_bool_exp
  ) {
    Organizer(offset: $offset, limit: $limit, order_by: $orderBy, where: $where) { 
      id
      publicName
      eMail
      verified
      Supervisor {
        firstName
        lastName
      }
      EventSeries_aggregate {
        aggregate {
          count(distinct: true)
        }
      }
      EventSeries {
        Events_aggregate(where: { startTime: { _gt: $timeNow } }) {
          aggregate {
            count(distinct: true)
          }
        }
      }
    }
    Organizer_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const loadOrganizerLastLoginTime = gql`
  query frontend_organizer_loadOrganizerLastLoginTime($ids: [uuid!]) {
    getOrganizerLastLogin(ids: $ids) {
      lastLogins
    }
  }
`;
