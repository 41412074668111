import { Point } from "geojson";

export enum OrganizerFormMode {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
}

type OrganizerUser = {
  OrganizerAccount: OrganizerAccount;
}

type OrganizerAccount = {
  id: string;
  email: string;
}

export interface Organizer {
  id?: string;
  name: string;
  publicName: string;
  eMail?: string;
  supervisorId?: string;
  streetName: string;
  streetNumber: string;
  city: string;
  postalCode: string;
  verified: boolean;
  locationAlias?: string;
  location?: Point;
  contact?: string;
  selfManaged: boolean;
  AdminUser_aggregate: {
    aggregate: {
      count: number;
    };
  };
  OrganizerUser_aggregate: {
    aggregate: {
      count: number;
    };
  };
  OrganizerUser: OrganizerUser[];
}
