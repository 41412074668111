<template lang="pug">
.preview-area-container
  .preview-switch-container
    UiSwitch.preview-switch(v-model="showEventCardPreview")
      template(#left) Event Detail
      template(#right) Preview Card
  IPhoneFrame.device-frame
    EventCardPreview(
      v-if="showEventCardPreview",
      :event="event",
      @clicked="onEventCardClicked"
    )
    EventPreview(v-else, :event="event")
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { PublicEventUpdate } from "../Update/types";
import { PublicEventCreate } from "../Create/types";
import EventCardPreview from "./EventCardPreview.vue";
import EventPreview from "./EventPreview.vue";
import IPhoneFrame from "../../../ui/IPhoneFrame/IPhoneFrame.vue";
import UiSwitch from "../../../ui/Switch/UiSwitch.vue";

export default defineComponent({
  name: "EventPreviewArea",
  components: {
    UiSwitch,
    IPhoneFrame,
    EventPreview,
    EventCardPreview,
  },
  props: {
    event: {
      type: Object as PropType<PublicEventCreate | PublicEventUpdate | any>,
      required: true,
      default: () => ({} as PublicEventUpdate),
    },
  },
  data: () => ({
    showEventCardPreview: false,
  }),
  computed: {
    eventBanner() {
      return this.event.cover;
    },
  },
  watch: {
    // When an image is uploaded, switch to the corresponding preview
    eventBanner() {
      this.showEventCardPreview = false;
    },
  },
  methods: {
    onEventCardClicked() {
      this.showEventCardPreview = false;
    },
  },
});
</script>

<style scoped>
.preview-area-container {
  @apply w-full h-full flex flex-col justify-center items-center;
}
.preview-switch-container {
  @apply flex flex-row justify-center w-full my-6;
}
.preview-switch {
  @apply mx-3;
}
.device-frame {
  @apply pb-3;
}
.switch-button {
  @apply absolute;
}
</style>
