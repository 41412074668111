import { FirebaseOptions } from "@firebase/app";

const firebaseConfig: FirebaseOptions = {
  apiKey: import.meta.env.VITE_GOOGLE_API_KEY as string | undefined,
  authDomain: `${import.meta.env.VITE_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${
    import.meta.env.VITE_FIREBASE_PROJECT_ID
  }.firebaseio.com`,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID as string | undefined,
  storageBucket: `${import.meta.env.VITE_FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID as
    | string
    | undefined,
  appId: import.meta.env.VITE_FIREBASE_APP_ID as string | undefined,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID as
    | string
    | undefined,
};
export default firebaseConfig;
