import gql from "graphql-tag";

// TODO: move!
export const authenticatedAreaFetchEventSeries = gql`
  query frontend_authenticatedArea_fetchEventSeriesData($id: uuid!) {
    Organizer_by_pk(id: $id) {
      EventSeries {
        id
        name
      }
    }
  }
`;
