import { configure, defineRule } from "vee-validate";
import { email, required, min } from "@vee-validate/rules";
import { localize, setLocale } from "@vee-validate/i18n";
import {
  validationAdminUserCreate,
  validationOrganizerUserCreate,
  validationSecondaryNameCreate,
  validationSecondaryNameUpdate,
} from "./queries";
import { client } from "../infrastructure/api/graphql/apollo";

export const defineValidationRules = () => {
  // TODO: add proper typing
  defineRule("required", required);
  defineRule("email", email);
  defineRule("url", (value: string) => {
    const regex =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,5}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
    if (regex.test(value)) {
      // todo: check against blacklist
      return true;
    } else {
      if (!value || (value && value.length === 0)) return true;
      return "Please enter a valid URL";
    }
  });
  // TODO: currently it is called on every  update, we probably should improve that later on
  defineRule("checkSecondaryNameCreate", async (name: string) => {
    const { data } = await client.query({
      query: validationSecondaryNameCreate,
      fetchPolicy: "no-cache",
      variables: {
        name,
      },
    });
    if (data.count.aggregate.count === 0) {
      return true;
    } else {
      return "Name bereits vergeben";
    }
  });
  // TODO: use search_secondary_name/translations api!!!
  // TODO: currently it is called on every  update, we probably should improve that later on
  defineRule("checkSecondaryNameUpdate", async (name: string) => {
    const { data } = await client.query({
      query: validationSecondaryNameUpdate,
      fetchPolicy: "no-cache",
      variables: {
        name,
      },
    });
    if (data.count.aggregate.count === 0) {
      return true;
    } else {
      return "Name bereits vergeben";
    }
  });
  // TODO: currently it is called on every  update, we probably should improve that later on
  defineRule("checkAdminUserCreate", async (email: string) => {
    const { data } = await client.query({
      query: validationAdminUserCreate,
      fetchPolicy: "no-cache",
      variables: {
        email,
      },
    });
    if (data.AdminUser_aggregate.aggregate.count === 0) {
      return true;
    } else {
      return "E-Mail bereits vergeben";
    }
  });
  // TODO: currently it is called on every  update, we probably should improve that later on
  defineRule("checkOrganizerEmailCreate", async (email: string) => {
    const { data } = await client.query({
      query: validationOrganizerUserCreate,
      fetchPolicy: "no-cache",
      variables: {
        email,
      },
    });
    if (data.OrganizerUser_aggregate.aggregate.count === 0) {
      return true;
    } else {
      return "E-Mail bereits vergeben";
    }
  });
};
defineRule("min", min);

// see: https://vee-validate.logaretm.com/v4/guide/i18n
configure({
  generateMessage: localize({
    en: {
      messages: {
        required: "{field} is required",
      },
      names: {
        image: "Image",
        "image 0": "Image 1",
        "image 1": "Image 2",
        "image 2": "Image 3",
        logo: "Logo",
        banner: "Banner",
      },
    },
    de: {
      messages: {
        required: "{field} wird benötigt",
      },
      names: {
        image: "Bild",
        "image 0": "Bild 1",
        "image 1": "Bild 2",
        "image 2": "Bild 3",
        logo: "Logo",
        banner: "Banner",
      },
    },
  }),
});

// ToDo: sync with current localization
setLocale("de");
