<template lang="pug">
form.content(autocomplete="off")
  .section-header
    p Benenne dein Event
  label.label Eventname *
  UiTextInput(
    v-model="publicEvent.title",
    style-ids="rounded-border hover-primary",
    :validation-rules="{ required: true }",
    :disabled="publicEvent.cancelled || disabled",
    maxlength="100",
    name="Eventname",
    :loading="loading",
    :skeleton="true"
  )
  label.label Hauptkategorie *
  PrimaryTagSelect(
    v-model="publicEvent.primaryTagId",
    :rounded="true",
    :disabled="publicEvent.cancelled || disabled",
    style-ids="hover-primary",
    :loading="loading"
  )
  label.label Genres
  SecondaryTagAutoComplete(
    style-ids="rounded-border hover-primary",
    :disabled="publicEvent.cancelled || disabled",
    placeholder="",
    :parent-element-is-loading="loading"
    @on-select="onSecondaryTagSelect",
  )
  .secondary-tag-wrapper
    .secondary-tags
      SecondaryTagVisualization(
        v-for="tag in publicEvent.secondaryTags",
        :key="tag.id",
        :name="tag.name",
        @click="onSecondaryTagRemove(tag)"
      )
    div
      .error-message(v-if="secondaryTagErrorMessage") {{ secondaryTagErrorMessage }}
  .section-divider
  .section-header
    p Beschreibe dein Event

  label.label Beschreibung *
  UiTextInput(
    style-ids="rounded-border hover-primary",
    tag="textarea",
    v-model="publicEvent.description",
    :disabled="publicEvent.cancelled || disabled",
    :validation-rules="{ required: true }",
    maxlength="5000",
    :max-length="5000",
    name="Beschreibung",
    :loading="loading",
    :skeleton="true"
  )
  .section-divider
  .section-header
    p Wo und wann findet dein Event statt?

  label.label Location *
  UiLocationAutoComplete(
    style-ids="rounded-border hover-primary",
    :locationAlias="publicEvent.locationAlias",
    @onSelect="onLocationSelect",
    :disabled="publicEvent.cancelled || disabled",
    :validation-rules="{ required: true }",
    fetch-policy="establishment-first",
    name="Location",
    :loading="loading"
  )
    template(#icon, v-if="!publicEvent.cancelled && !disabled")
      .location-picker
        img.location-picker-icon(
          src="../../../assets/icons/locationPicker.png",
          @click="openLocationPickerModal"
        )
  label.label Start *
  UiDateTimePicker(
    v-model="publicEvent.startTime",
    :minDatetime="minStartTime",
    :maxDatetime="maxStartTime",
    :validation-rules="{ required: true }",
    :disabled="publicEvent.cancelled || disabled",
    name="Startdatum",
    :loading="loading"
  )
  label.label Ende *
  UiDateTimePicker(
    v-model="publicEvent.endTime",
    :default-date="publicEvent.startTime ? addHours(publicEvent.startTime.toString(), defaultEventTime) : undefined",
    :minDatetime="minEndTime",
    :maxDatetime="maxEndTime",
    :disabled="publicEvent.cancelled || disabled",
    :validation-rules="{ required: true }",
    name="Enddatum",
    :loading="loading"
  )
  .section-divider
  .section-header
    p Verkaufe Tickets für dein Event!
  label.label Ticket-Link
  UiTextInput(
    style-ids="rounded-border hover-primary",
    name="Ticket-Link",
    :validation-rules="{ url: true }",
    v-model="publicEvent.ticketLink",
    :disabled="publicEvent.cancelled || disabled",
    :loading="loading",
    :skeleton="true"
  )
  .section-divider
  .section-header
    p Lade dein Design hoch!
  label.label Event Cover *
  UiDropZone(
    name="Event Cover",
    v-model="publicEvent.cover",
    :disabled="publicEvent.cancelled || disabled",
    :type="eventCoverType",
    :validation-rules="{ required: true }"
  )
.flex.flex-col.items-center.w-full
  UiButton.create-button(
    style-ids="primary",
    @click="onSubmit",
    :disabled="Object.keys(errors).length > 0 || isSubmitting || publicEvent.cancelled || disabled"
  ) {{ mode === createMode ? "Create" : "Update" }}
  UiButton.cancel-button(
    v-if="mode !== createMode",
    @click="toggleShowCancelEventModal",
    :disabled="isSubmitting || publicEvent.cancelled || disabled"
  ) Cancel Event
UiModal.modal-cancel(
  v-if="showCancelEventModal",
  @close="toggleShowCancelEventModal"
)
  template(#header)
    p.modal-cancel-title Möchtest du dein Event wirklich absagen?
  template(#content)
    .modal-cancel-content-wrapper
      p.modal-cancel-content-text {{ 'Nachdem das Event abgesagt wurde, kann es nicht mehr bearbeitet werden. Aktuelle Änderungen werden nicht berücksichtigt, das Event wird ausschließlich abgesagt. Teilnehmende oder eingeladene Nutzer erhalten eine Benachrichtigung über die Absage des Events. Wenn du dein Event absagen möchtest, tippe bitte "' + publicEvent.title + '" zur Bestätigung ein:' }}
      UiTextInput.modal-cancel-content-input(
        style-ids="rounded-border",
        v-model="cancelEventModalInputValue",
        autocomplete="off"
      )
  template(#footer)
    .modal-cancel-footer
      UiButton.modal-cancel-button(
        @click="showCancelEventModal = false",
        style-ids="subliminal"
      ) Abbrechen
      UiButton(
        @click="onCancelEvent",
        style-ids="highlight",
        :disabled="!cancelEventButtonEnabled"
      ) Event absagen
UiLocationPickerModal(
  :show-modal="showLocationPickerModal",
  @close="closeLocationPickerModal",
  @locationPicked="locationPicked"
)
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { useForm } from "vee-validate";
import UiButton from "../../../ui/Button/UiButton.vue";
import UiTextInput from "../../../ui/TextInput/UiTextInput.vue";
import PrimaryTagSelect from "../../PrimaryTag/Select/PrimaryTagSelect.vue";
import UiDateTimePicker from "../../../ui/DateTimePicker/UiDateTimePicker.vue";
import UiLocationAutoComplete from "../../../ui/LocationAutoComplete/UiLocationAutoComplete.vue";
import SecondaryTagVisualization from "../../SecondaryTag/Visualization/SecondaryTagVisualization.vue";
import UiModal from "../../../ui/Modal/UiModal.vue";
import UiDropZone from "../../../ui/DropZone/UiDropZone.vue";
import SecondaryTagAutoComplete from "../../SecondaryTag/AutoComplete/Autocomplete.vue";
import {
  EventFormMode,
  EventImageType,
  PublicEventCreate,
} from "../Create/types";
import { SecondaryTag } from "../../SecondaryTag/AutoComplete/types";
import { FormattedPlace } from "../../../ui/LocationAutoComplete/types";
import UiLocationPickerModal from "../../../ui/LocationPickerModal/UiLocationPickerModal.vue";
import { LocationPickerResult } from "../../../ui/LocationPickerModal/types";
import { PublicEventUpdate } from "../Update/types";
import { addOffsetToDate } from "../../../utils/dateFormatter";

export default defineComponent({
  name: "EventForm",
  components: {
    UiLocationPickerModal,
    UiButton,
    UiTextInput,
    PrimaryTagSelect,
    SecondaryTagAutoComplete,
    UiDateTimePicker,
    UiLocationAutoComplete,
    SecondaryTagVisualization,
    UiModal,
    UiDropZone,
  },
  props: {
    mode: {
      type: String as PropType<EventFormMode>,
      default: () => EventFormMode.CREATE,
      required: true,
    },
    modelValue: {
      type: Object as PropType<PublicEventCreate | PublicEventUpdate>,
      default: () => ({} as PublicEventCreate),
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onSubmit", "update:modelValue", "onCancelEvent"],
  setup(props, context) {
    const { handleSubmit, isSubmitting, errors } = useForm();

    const onSubmit = handleSubmit(() => {
      context.emit("onSubmit");
    });

    return {
      isSubmitting,
      errors,
      onSubmit,
    };
  },
  data() {
    return {
      createMode: EventFormMode.CREATE,
      eventCoverType: EventImageType.EVENT_COVER,
      secondaryTagErrorMessage: "",
      showCancelEventModal: false,
      showLocationPickerModal: false,
      cancelEventModalInputValue: "",
      publicEvent: this.modelValue,
    };
  },
  computed: {
    minStartTime() {
      return new Date();
    },
    maxStartTime() {
      const date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      return this.publicEvent.endTime
        ? new Date(this.publicEvent.endTime)
        : date;
    },
    minEndTime() {
      return this.publicEvent.startTime
        ? new Date(this.publicEvent.startTime)
        : new Date();
    },
    maxEndTime() {
      const date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      return date;
    },
    cancelEventButtonEnabled() {
      return this.publicEvent.title === this.cancelEventModalInputValue;
    },
    defaultEventTime() {
      if (import.meta.env.VITE_DEFAULT_EVENT_DURATION) {
        return Number(import.meta.env.VITE_DEFAULT_EVENT_DURATION);
      } else {
        return 4;
      }
    },
  },
  watch: {
    modelValue() {
      this.publicEvent = this.modelValue;
    },
    publicEvent() {
      this.$emit("update:modelValue", this.publicEvent);
    },
  },
  methods: {
    onSecondaryTagSelect(tag: SecondaryTag) {
      if (
        !this.publicEvent.secondaryTags.some(
          (secondaryTag) => secondaryTag.id === tag.id
        )
      ) {
        this.publicEvent.secondaryTags.push(tag);
      } else {
        this.secondaryTagErrorMessage = `${tag.name} has already been taken...`;
        setTimeout(() => {
          this.secondaryTagErrorMessage = "";
        }, 2500);
      }
    },
    onSecondaryTagRemove(tag: SecondaryTag) {
      this.publicEvent.secondaryTags = this.publicEvent.secondaryTags.filter(
        (secondaryTag) => secondaryTag.id !== tag.id
      );
    },
    onLocationSelect(place: FormattedPlace) {
      this.publicEvent.location = place.location;
      this.publicEvent.locationAlias = place.locationAlias;
    },
    onImageReceive(image: string, type: EventImageType) {
      switch (type) {
        case EventImageType.EVENT_COVER: {
          this.publicEvent.cover = image;
          break;
        }
      }
    },
    toggleShowCancelEventModal() {
      this.cancelEventModalInputValue = "";
      this.showCancelEventModal = !this.showCancelEventModal;
    },
    onCancelEvent() {
      this.toggleShowCancelEventModal();
      this.$emit("onCancelEvent");
    },
    openLocationPickerModal() {
      this.showLocationPickerModal = true;
    },
    closeLocationPickerModal() {
      this.showLocationPickerModal = false;
    },
    locationPicked(location: LocationPickerResult) {
      this.publicEvent.location = location.location;
      this.publicEvent.locationAlias = location.locationAlias;
      this.showLocationPickerModal = false;
    },
    addHours(date: string, hours: number) {
      return addOffsetToDate(date, hours);
    },
  },
});
</script>

<style scoped>
.content {
  @apply grid grid-flow-row;
  @apply p-8;
  @apply gap-x-24 gap-y-4;
  grid-template-columns: 3fr 8fr;

  & > * {
    @apply self-center;
  }

  .section {
    &-header {
      @apply text-xl font-bold;
      grid-area: auto / 1 / auto / span 2;
    }
  }
}

label {
  @apply text-sm;
}

.section-divider {
  @apply h-0.5 bg-background-contrast;
  grid-area: auto / 1 / auto / span 2;
}

.secondary-tag-wrapper {
  @apply flex flex-col items-start flex-wrap;
  grid-area: auto / 2 / auto / 2;

  .error-message {
    @apply text-background-highlight;
  }
  .secondary-tags {
    @apply flex flex-wrap;
  }
}

.create-button {
  @apply w-11/12;
}
.cancel-button {
  @apply text-background-highlight underline;
}

.organizer-delete-modal:deep .modal {
  max-width: 50%;
}

.modal-cancel {
  :deep .modal {
    max-width: 50%;
  }
  &-button {
    @apply mr-4;
  }
  &-title {
    @apply text-xl font-extrabold;
  }
  &-content {
    &-text {
      @apply select-text mb-3;
    }
    &-input {
      @apply w-8/12;
    }
  }
  &-footer {
    @apply flex w-full justify-start;
  }
}
.location-picker {
  width: 16px;
  height: 20px;
  &-icon {
    @apply w-full h-full cursor-pointer;
  }
}
</style>
