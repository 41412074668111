<template lang="pug">
.update-organizer-container
  .header(v-if="isOrganizerManager")
    p.header-item(
      :class="{ active: selectedHeaderItem === 'profile' }",
      @click="selectedHeaderItem = 'profile'"
    ) Profil
    p.header-item(
      :class="{ active: selectedHeaderItem === 'balance' }",
      @click="selectedHeaderItem = 'balance'"
    ) Konto
  template(v-if="selectedHeaderItem === 'profile'")
    OrganizerForm(
      :mode="updateMode",
      :admins="adminUsers",
      :loading="mutationLoading",
      :organizer="organizer",
      @on-submit="updateOrganizerClicked",
      @on-delete="deleteOrganizerClicked",
      @on-invite="inviteOrganizer"
    )
  template(v-if="selectedHeaderItem === 'balance'")
    OrganizerBalance(:organizer-id="organizerId")
UiLoadingOverlay(v-if="mutationLoading") {{ action === "invite" ? "Der Veranstalter wird gelöscht..." : "Der Veranstalter wird angelegt und eingeladen..." }}
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { useRoute } from "vue-router";
import OrganizerForm from "../Form/OrganizerForm.vue";
import UiButton from "../../../ui/Button/UiButton.vue";
import UiLoadingOverlay from "../../../ui/LoadingOverlay/LoadingOverlay.vue";
import {
  LoadOrganizerByIdResponse,
  OrganizerDeleteResponse,
  OrganizerAdminUserResponse,
  OrganizerUpdateResponse,
} from "./types";
import {
  deleteOrganizerById,
  inviteOrganizerMutation,
  loadOrganizerById,
  organizer_loadAdminUsers,
  updateOrganizerById,
} from "./queries";
import { OrganizerFormMode } from "../Form/types";
import router from "../../../infrastructure/router";
import { useToast } from "vue-toastification";
import OrganizerBalance from "../Balance/OrganizerBalance.vue";
import { useStore } from "vuex";
import { storeKey } from "../../../infrastructure/store/store";

export default defineComponent({
  name: "OrganizerUpdate",
  components: {
    OrganizerBalance,
    OrganizerForm,
    UiLoadingOverlay,
  },
  setup() {
    const route = useRoute();
    const toast = useToast();
    const organizerId = route.params.organizerId as string;
    const selectedHeaderItem = ref("profile");
    const store = useStore(storeKey);
    const isOrganizerManager = ref(store.getters.isOrganizerManager);

    const { mutate: updateOrganizer } =
      useMutation<OrganizerUpdateResponse>(updateOrganizerById);
    const { mutate: deleteOrganizer, loading: deleteOrganizerLoading } =
      useMutation<OrganizerDeleteResponse>(deleteOrganizerById);
    const { mutate: invite, loading: inviteOrganizerLoading } = useMutation(
      inviteOrganizerMutation,
    );

    const { result: adminUserResult, loading: adminUserLoading } =
      useQuery<OrganizerAdminUserResponse>(organizer_loadAdminUsers);
    const { loading: organizerLoading, result } =
      useQuery<LoadOrganizerByIdResponse>(
        loadOrganizerById,
        {
          organizerId,
        },
        {
          fetchPolicy: "cache-and-network",
        },
      );
    const adminUsers = computed(() => adminUserResult.value?.AdminUser ?? []);
    const organizer = computed(() => result.value?.Organizer_by_pk);

    const inviteOrganizer = () => {
      invite({
        email: organizer.value?.eMail,
        organizerId: organizer.value?.id,
      })
        .then(() => {
          toast.success("Veranstalter wurde eingeladen");
          router.back();
        })
        .catch((error) => {
          toast.error("Fehler beim Einladen des Veranstalters");
          throw error;
        });
    };

    const mutationLoading = computed(() => {
      return deleteOrganizerLoading.value || inviteOrganizerLoading.value;
    });

    return {
      organizerId,
      selectedHeaderItem,
      queryLoading: organizerLoading.value || adminUserLoading.value,
      mutationLoading,
      deleteOrganizerLoading,
      inviteOrganizerLoading,
      organizer,
      adminUsers,
      inviteOrganizer,
      updateOrganizer,
      toast,
      deleteOrganizer,
      isOrganizerManager,
    };
  },
  data: () => ({
    updateMode: OrganizerFormMode.UPDATE,
    action: "" as "invite" | "delete",
  }),
  methods: {
    updateOrganizerClicked(organizer) {
      // check if email was changed
      this.updateOrganizer(organizer)
        .then(() => {
          router.back();
        })
        .catch((error) => {
          this.toast.error("Fehler beim Aktualisieren des Veranstalters");
          throw error;
        });
    },
    deleteOrganizerClicked(organizerId: string) {
      this.deleteOrganizer({ organizerId })
        .then(() => {
          router.back();
        })
        .catch((error) => {
          throw error;
        });
    },
  },
});
</script>

<style scoped>
.update-organizer-container {
  @apply h-full w-1/2 flex flex-col;
}
.header {
  @apply flex;
  @apply mx-4;
  @apply border-b border-border-default;

  &-item {
    @apply py-5 ml-8 cursor-pointer;
  }

  &-item.active {
    @apply text-primary;
  }
}

.balance-wrapper {
  @apply border-b py-5 border-border-default;
}
</style>
