<template lang="pug">
form.admin-form(autocomplete="off", @submit="onSubmit")
  .section-header
  p Benutzerinformationen
  .section-divider
  label.label Vorname *
  UiTextInput(
    v-model="admin.firstName"
    style-ids="rounded-border hover-primary",
    :validation-rules="{ required: true }",
    name="Vorname",
  )
  label.label Nachname *
  UiTextInput(
    v-model="admin.lastName"
    style-ids="rounded-border hover-primary",
    :validation-rules="{ required: true }",
    name="Nachname",
  )
  label.label E-Mail *
  UiTextInput.relative(
    v-model="admin.email"
    style-ids="rounded-border hover-primary",
    :validation-rules="{ required: true, email: true, checkAdminUserCreate: mode === 'create' }",
    name="E-Mail",
    :disabled="mode === 'update'",
  )
    template(
      v-if="mode === 'update'"
      #icon
    )
      .icon
        button(type="button", @click="resendEmail")
          img.resend-email-icon(
            src="../../../../../../assets/icons/paper-plane-solid.svg",
            alt="Resend invitation Email"
          )
        .tooltip
          p.tip Resend Password Reset Email

  .section-header
  p Berechtigungen
  .section-divider

  .permissions-grid
    label Kann Events verwalten
    input(type="checkbox", value="EventManager", v-model="admin.permissions")

    label Kann Tags verwalten
    input(type="checkbox", value="TagManager", v-model="admin.permissions")

    label Kann Organizer verwalten
    input(
      type="checkbox",
      value="OrganizerManager",
      v-model="admin.permissions"
    )

    label Kann Admins verwalten
    input(type="checkbox", value="AdminManager", v-model="admin.permissions")

    label Kann Scraper verwenden
    input(type="checkbox", value="ScraperManager", v-model="admin.permissions")

  UiButton.mt-6.btn(style-ids="primary", :disabled="isSubmitting || hasErrors") {{ mode === "create" ? "Erstellen" : "Aktualisieren" }}
  UiButton.btn.delete-btn(
    @click="showModal = !showModal",
    style-ids="highlight",
    :disabled="isSubmitting || mode === 'create'",
    type="button"
  ) Benutzer löschen
UiModal.modal(v-if="showModal", @close="showModal = !showModal")
  template(#header)
    p.modal-delete-title Möchtest du Benutzer wirklich löschen?
  template(#content)
    div
      p.font-bold Diese Aktion kann nicht rückgängig gemacht werden!
      p {{ `Wenn du den Benutzer wirklich löschen möchtest, tippe bitte "Benutzer löschen" zur Bestätigung ein:` }}
      br
      UiTextInput.modal-delete-content-input(
        style-ids="rounded-border",
        v-model="deleteInput",
        autocomplete="off"
      )
  template(#footer)
    .modal-delete-footer
      UiButton.modal-delete-button(
        @click="showModal = !showModal",
        style-ids="subliminal"
      ) Abbrechen
      UiButton(
        style-ids="highlight",
        :disabled="!deleteButtonEnabled",
        @click="deleteUser"
      ) Benutzer löschen
</template>

<script lang="ts">
import { TYPE, useToast } from "vue-toastification";
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import { useForm } from "vee-validate";
import { AdminFormUpsertResponse, AdminFormUserResponse } from "./types";
import {
  adminForm_delete,
  adminForm_insert,
  adminForm_loadUser,
  adminForm_resendPasswordMail,
  adminForm_update,
} from "./queries";
import { useRouter } from "vue-router";
import UiButton from "../../../../../../ui/Button/UiButton.vue";
import { useMutation, useQuery } from "@vue/apollo-composable";
import UiTextInput from "../../../../../../ui/TextInput/UiTextInput.vue";
import UiModal from "../../../../../../ui/Modal/UiModal.vue";

export default defineComponent({
  name: "AdminForm",
  components: {
    UiButton,
    UiTextInput,
    UiModal,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props) {
    const { id } = toRefs(props);
    const router = useRouter();
    const toast = useToast();
    const permissions = ref<string[]>();
    const showModal = ref(false);
    const admin = reactive({
      firstName: "",
      lastName: "",
      firebaseId: "",
      email: "",
      permissions: [] as string[],
    });
    const mode = ref(router.currentRoute.value.fullPath.split("/")[2]);
    const deleteInput = ref("");

    const { mutate: create } =
      useMutation<AdminFormUpsertResponse>(adminForm_insert);
    const { mutate: update } = useMutation(adminForm_update);
    const { mutate: deleteAdminUser } = useMutation(adminForm_delete);
    const { mutate: resendPasswordMail } = useMutation(
      adminForm_resendPasswordMail
    );

    const { result, onResult, loading } = useQuery<AdminFormUserResponse>(
      adminForm_loadUser,
      () => ({
        id: id.value,
      }),
      () => ({
        enabled: mode.value === "update",
      })
    );

    onResult((result) => {
      if (!result.loading) {
        admin.firstName = result.data.AdminUser_by_pk.firstName;
        admin.lastName = result.data.AdminUser_by_pk.lastName;
        admin.firebaseId = result.data.AdminUser_by_pk.firebaseId;
        admin.email = result.data.AdminUser_by_pk.email;
        admin.permissions = result.data.AdminUser_by_pk.Role.map(
          (role) => role.role
        );
      }
    });

    const adminResult = computed(() => result.value?.AdminUser_by_pk);

    if (adminResult.value) {
      admin.firstName = adminResult.value.firstName;
      admin.lastName = adminResult.value.lastName;
      admin.email = adminResult.value.email;
      admin.firebaseId = adminResult.value.firebaseId;
      admin.permissions = adminResult.value.Role.map((role) => role.role);
    }

    const { handleSubmit, isSubmitting, errors } = useForm();

    const onSubmit = handleSubmit(() => {
      if (mode.value == "create") {
        create({
          firstName: admin.firstName,
          lastName: admin.lastName,
          email: admin.email,
          permissions: admin.permissions.map((permission) => ({
            role: permission,
          })),
        })
          .then(() => {
            router.back();
          })
          .catch((error) => {
            toast("Fehler beim Erstellen des Benutzers", {
              type: TYPE.ERROR,
            });
            throw error;
          });
      } else if (mode.value === "update") {
        update({
          id: id.value,
          firstName: admin.firstName,
          lastName: admin.lastName,
          permissions: admin.permissions.map((permission) => ({
            adminUserId: id.value,
            role: permission,
          })),
        })
          .then(() => {
            router.back();
          })
          .catch((error) => {
            toast("Fehler beim Aktualisieren des Benutzers", {
              type: TYPE.ERROR,
            });
            throw error;
          });
      }
    });

    const hasErrors = computed(() => {
      return Object.keys(errors.value).length > 0;
    });

    const deleteButtonEnabled = computed(() => {
      return deleteInput.value === "Benutzer löschen";
    });

    const deleteUser = () => {
      deleteAdminUser({ id: id.value })
        .then(() => {
          router.back();
        })
        .catch((error) => {
          toast("Fehler beim Löschen des Benutzers", {
            type: TYPE.ERROR,
          });
          throw error;
        });
    };

    const resendEmail = () => {
      resendPasswordMail({
        firebaseId: admin.firebaseId,
      })
        .then(() => {
          toast("E-Mail wurde erfolgreich gesendet!", { type: TYPE.SUCCESS });
        })
        .catch((error) => {
          toast(
            "Fehler beim Senden der Passwort-Mail. Bitte versuche es erneut!",
            {
              type: TYPE.ERROR,
            }
          );
          throw error;
        });
    };

    return {
      isSubmitting,
      loading,
      admin,
      deleteButtonEnabled,
      deleteInput,
      showModal,
      mode,
      deleteUser,
      onSubmit,
      hasErrors,
      permissions,
      resendEmail,
    };
  },
});
</script>

<style scoped>
.admin-form {
  @apply grid w-8/12;
  @apply p-4 gap-x-24 gap-y-4;
  @apply w-2/3;
  grid-template-columns: 3fr 8fr;

  .permissions-grid {
    @apply grid grid-flow-row;
    @apply gap-y-4;
    grid-area: auto / 1 / auto / span 2;
    grid-template-columns: 1fr 2fr;
  }

  .section-header {
    @apply text-lg font-bold;
    grid-area: auto / 1 / auto / span 2;
  }

  .section-divider {
    @apply h-0.5 bg-background-contrast mt-2;
    grid-area: auto / 1 / auto / span 2;
  }
}

.btn {
  grid-area: auto / 1 / auto / span 2;
}

.modal-delete-button {
  @apply mr-4;
}
.modal-delete-title {
  @apply text-xl font-extrabold;
}
.modal-delete-content {
  &-text {
    @apply select-text mb-3;
  }
  &-input {
    @apply w-8/12;
  }
}

.list {
  @apply border border-background-highlight bg-background-highlight bg-opacity-40 border-dashed p-4;
}

.modal-delete-footer {
  @apply flex w-full justify-start;
}

.icon {
  &:hover {
    :last-child {
      @apply block;
    }
  }

  .resend-email-icon {
    @apply w-6 h-6 hover:cursor-pointer;
  }
  .tooltip {
    @apply absolute -right-72 top-1 break-words h-8 bg-primary rounded-2xl hidden;
    .tip {
      @apply px-5 py-1 text-background-dark;
    }
  }
}
</style>
