<template lang="pug">
.create-organizer-container
  .create-organizer-form
    OrganizerForm(
      :mode="createMode",
      :admins="admins",
      @on-submit="createOrganizerClicked"
    )
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref } from "vue";
import { useMutation, useQuery } from "@vue/apollo-composable";
import OrganizerForm from "../Form/OrganizerForm.vue";
import UiButton from "../../../ui/Button/UiButton.vue";
import { OrganizerCreateResponse } from "./types";
import { organizer_create } from "./queries";
import { Organizer, OrganizerFormMode } from "../Form/types";
import router from "../../../infrastructure/router";
import { organizer_loadAdminUsers } from "../Update/queries";
import { OrganizerAdminUserResponse } from "../Update/types";
import { useToast } from "vue-toastification";

export default defineComponent({
  name: "OrganizerCreate",
  components: {
    OrganizerForm,
  },
  setup() {
    const toast = useToast();
    const { mutate: createOrganizer } =
      useMutation<OrganizerCreateResponse>(organizer_create);
    const { result } = useQuery<OrganizerAdminUserResponse>(
      organizer_loadAdminUsers,
    );
    return {
      toast,
      admins: computed(() => result.value?.AdminUser ?? []),
      createOrganizer,
    };
  },
  data: () => ({
    createMode: OrganizerFormMode.CREATE,
  }),
  methods: {
    createOrganizerClicked(organizer) {
      this.createOrganizer(organizer)
        .then(() => {
          router.back();
        })
        .catch((error) => {
          this.toast.error(
            "Fehler beim Erstellen des Veranstalters. Kontaktiere bitte einen Admin.",
          );
          throw error;
        });
    },
    sanitize(value: Organizer) {
      for (const key in value) {
        if (!value[key]) {
          delete value[key];
        }
      }
      return value;
    },
  },
});
</script>

<style scoped>
.create-organizer-container {
  @apply h-full w-full flex flex-col overflow-auto;
}
.create-organizer-form {
  @apply w-8/12;
}
</style>
