import { createStore, Store } from "vuex";
import state, { AppState } from "./states/app.state";
import { InjectionKey } from "vue";

// TODO: probably migrate to pinia

// https://next.vuex.vuejs.org/guide/migrating-to-4-0-from-3-x.html#typescript-support
export const storeKey: InjectionKey<Store<AppState>> = Symbol();

export default createStore<AppState>({
  state: {
    navigation: {
      activeNavigationItem: "dashboard",
      initialLoading: true,
    },
    eventSeries: {
      eventSeriesList: [],
      error: null,
      loading: false,
    },
    organizer: null,
    user: {
      id: "",
      data: null,
      eventManager: false,
      adminManager: false,
      tagManager: false,
      organizerManager: false,
      loggedIn: false,
      scraperManager: false,
    },
  },
  mutations: {},
  actions: {},
  modules: {
    state,
  },
  strict: ["local", "development"].includes(
    import.meta.env.VITE_APP_ENVIRONMENT as string
  ),
});
