<template lang="pug">
AuthenticationHeader
  p.description {{ actionCodeIsInvalid ? "Sorry, your registration link expired or has already been used. Please request a new link. " : "Please enter a password that contains at least 6 characters." }}
  form.flex.flex-col.items-center.w-full(v-if="!actionCodeIsInvalid")
    UiTextInput.mt-3(
      v-model="firstResetPassword",
      type="password",
      style-ids="login",
      placeholder="New Password"
    )
    UiTextInput.mt-3(
      v-model="secondResetPassword",
      type="password",
      style-ids="login",
      placeholder="Repeat Password"
    )
  p.text-sm.text-red-500.self-start(v-if="errorWhileResettingPassword", class="mt-1.5") An error occurred while resetting your password. Please try again.
  .flex.mt-10.align-center.justify-center.w-full
    UiButton(
      :disabled="!resetPasswordIsValid",
      @click="resetPassword",
      v-if="!actionCodeIsInvalid",
      style-ids="primary login"
    ) Reset Password
    UiButton(variant="primary", @click="requestLinkClicked", v-else) Request Link
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import UiButton from "../../../ui/Button/UiButton.vue";
import {
  getAuth,
  verifyPasswordResetCode,
  confirmPasswordReset,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useRoute } from "vue-router";
import UiTextInput from "../../../ui/TextInput/UiTextInput.vue";
import AuthenticationHeader from "./AuthenticationHeader.vue";

export default defineComponent({
  name: "ResetPasswordForm",
  components: { UiButton, UiTextInput, AuthenticationHeader },
  emits: ["backToLogin", "requestLinkClicked"],
  setup(props, { emit }) {
    const actionCode = useRoute().query.oobCode as string;
    const resetPasswordActionCode = ref<string>("");
    const resetPasswordAccountEmail = ref<string>("");
    const actionCodeIsInvalid = ref<boolean>(false);

    verifyPasswordResetCode(getAuth(), actionCode)
      .then((email: string) => {
        resetPasswordActionCode.value = actionCode;
        resetPasswordAccountEmail.value = email;
      })
      .catch((error: any) => {
        // Invalid or expired action code. Ask user to try to reset the password again.
        if (error.code === "auth/expired-action-code") {
          actionCodeIsInvalid.value = true;
        }
        if (error.code === "auth/invalid-action-code") {
          emit("backToLogin");
        }
      });
    return {
      resetPasswordActionCode,
      resetPasswordAccountEmail,
      actionCodeIsInvalid,
    };
  },
  data() {
    return {
      firstResetPassword: "",
      secondResetPassword: "",
      errorWhileResettingPassword: false,
    };
  },
  computed: {
    resetPasswordIsValid() {
      return (
        this.isValidPassword(this.firstResetPassword) &&
        this.firstResetPassword === this.secondResetPassword
      );
    },
  },
  methods: {
    async resetPassword() {
      const newPassword = this.firstResetPassword;
      if (this.resetPasswordIsValid) {
        try {
          await confirmPasswordReset(
            getAuth(),
            this.resetPasswordActionCode,
            newPassword
          );
          await signInWithEmailAndPassword(
            getAuth(),
            this.resetPasswordAccountEmail,
            newPassword
          );
        } catch (e) {
          // Error occurred during confirmation. The code might have expired or the
          // password is too weak.
          this.errorWhileResettingPassword = true;
        }
      }
    },
    isValidPassword(password: string) {
      return password.length >= 6;
    },
    requestLinkClicked() {
      this.$emit("requestLinkClicked");
    },
  },
});
</script>

<style scoped>
.description {
  @apply text-sm text-center text-text-subliminal;
  @apply mt-2.5;
  text-align: center;
  max-width: 60%;
}
</style>
