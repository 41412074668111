<template lang="pug">
.content
  .switch
    UiSwitch(v-model="switchValue")
      template(#left) Secondary Tags
      template(#right) Secondary Tag Proposals
  .search-wrapper
    UiTextInput.search(
      v-model="search"
      style-ids="rounded-border hover-primary",
      :placeholder="'Suche'",
    )
  UiTable(
    v-if="!switchValue",
    :data="useSearch ? searchSecondaryTags : secondaryTags",
    :loading="secondaryTagsLoading",
    :total="useSearch ? searchSecondaryTagsTotal : secondaryTagsTotal",
    :default-sort="['translation', 'asc']",
    :current-page="secondaryTagsOffset + 1",
    :rows-per-page="secondaryTagsRowsPerPage",
    @page-change="(value: any) => setOffsetSecondaryTags(value - 1)",
    @update:rows-per-page="(value: any) => setRowsPerPageSecondaryTags(value)",
    @sort="setSortDirectionSecondaryTags",
    @click="onSecondaryTagClick($event)"
  )
    template(#caption)
      span.caption Secondary Tags

    UiTableColumn(
      v-slot="props",
      label="Name",
      field="translation",
      :sortable="true"
    ) {{ props.row.translation }}

    UiTableColumn(
      v-slot="props",
      label="Anzahl der getaggten Events",
      field="SecondaryTag.EventSecondaryMappings_aggregate.count",
      :sortable="true"
    ) {{ props.row.SecondaryTag.EventSecondaryMappings_aggregate.aggregate.count }}

    UiTableColumn(
      v-slot="props",
      label="Anzahl der User die diesen Tag verwenden",
      field="SecondaryTag.SecondaryInterests_aggregate.count",
      :sortable="true"
    )
      p {{ props.row.SecondaryTag.SecondaryInterests_aggregate.aggregate.count }}

    UiTableColumn(
      v-slot="props",
      label="Erstellt",
      field="SecondaryTag.createdAt",
      :sortable="true"
    )
      p {{ parseDate(props.row.SecondaryTag.createdAt) }}

    UiTableColumn(
      v-slot="props",
      label="Zuletzt bearbeitet",
      field="SecondaryTag.updatedAt",
      :sortable="true"
    )
      p {{ parseDate(props.row.SecondaryTag.updatedAt) }}

    UiTableColumn(position="centered")
      template.flex.items-center(#default)
      template(#header)
        button.add-secondary-tag(@click="onCreateClick") + Add Secondary Tag

  UiTable(
    v-else,
    :data="proposals",
    :loading="proposalsLoading",
    :total="proposalsTotal",
    :default-sort="['name', 'asc']",
    :current-page="proposalsOffset + 1",
    :rows-per-page="proposalsRowsPerPage",
    @page-change="setOffsetProposals($event - 1)",
    @update:rows-per-page="setRowsPerPageProposals($event)",
    @sort="setSortDirectionProposals"
  )
    template(#caption)
      p.caption Proposals
    UiTableColumn(
      v-slot="props"
      label="Name"
      field="name"
      :sortable="true"
    )
      p {{ props.row.name }}
    UiTableColumn(
      v-slot="props",
      label="Erstellt",
      field="createdAt",
      :sortable="true"
    )
      p {{ parseDate(props.row.created_at) }}
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import UiTable from "./../../../../../ui/Table/UiTable.vue";
import UiTableColumn from "./../../../../../ui/Table/UiTableColumn.vue";
import { useQuery } from "@vue/apollo-composable";
import { formatDate } from "../../../../../utils/dateFormatter";
import UiTextInput from "./../../../../../ui/TextInput/UiTextInput.vue";
import UiSwitch from "./../../../../../ui/Switch/UiSwitch.vue";
import {
  TagOverviewSecondaryProposalsResponse,
  TagOverviewSecondaryTagsResponse,
} from "./types";
import {
  tagOverviewProposalQuery,
  tagOverviewSecondaryTagsQuery,
} from "./queries";

export default defineComponent({
  name: "TagOverview",
  components: { UiSwitch, UiTableColumn, UiTable, UiTextInput },
  setup() {
    // secondary tags
    const secondaryTagsOrderBy = ref<{ [key: string]: string }>({
      translation: "asc",
    });
    const secondaryTagsRowsPerPage = ref(10);
    const secondaryTagsOffset = ref(0);

    // proposals
    const proposalsOrderBy = ref<{ [key: string]: string }>({
      name: "asc",
    });
    const proposalsRowsPerPage = ref(10);
    const proposalsOffset = ref(0);

    const search = ref("");
    const switchValue = ref(false);

    // computed values
    const setOffsetSecondaryTags = (newOffset: number) => {
      secondaryTagsOffset.value = newOffset;
    };
    const setRowsPerPageSecondaryTags = (newRowsPerPage: number) => {
      secondaryTagsRowsPerPage.value = newRowsPerPage;
    };
    const setOffsetProposals = (newOffset: number) => {
      proposalsOffset.value = newOffset;
    };
    const setRowsPerPageProposals = (newRowsPerPage: number) => {
      proposalsRowsPerPage.value = newRowsPerPage;
    };
    const useSearch = computed(() => {
      return search.value.length > 1;
    });

    // queries
    const { result: secondaryTagsResult, loading: secondaryTagsLoading } =
      useQuery<TagOverviewSecondaryTagsResponse>(
        tagOverviewSecondaryTagsQuery,
        () => {
          const variables = {
            timeNow: new Date(),
            orderBy: secondaryTagsOrderBy.value,
            limit: secondaryTagsRowsPerPage.value,
            offset: secondaryTagsOffset.value * secondaryTagsRowsPerPage.value,
          } as any;
          if (useSearch.value) {
            variables.search = search.value;
          }
          return variables;
        },
      );
    const { result: proposalResult, loading: proposalsLoading } =
      useQuery<TagOverviewSecondaryProposalsResponse>(
        tagOverviewProposalQuery,
        () => {
          const variables = {
            offset: proposalsOffset.value * proposalsRowsPerPage.value,
            limit: proposalsRowsPerPage.value,
            oderBy: proposalsOrderBy.value,
          } as any;
          if (useSearch.value) {
            // TODO: performance issue
            variables.where = {
              name: {
                _eq: search.value,
              },
            };
          }
          return variables;
        },
      );

    const setSortDirectionSecondaryTags = (
      field: string,
      sortDirection: any,
    ) => {
      const [last, ...paths] = field.split(".").reverse();
      secondaryTagsOrderBy.value = paths.reduce((acc, el) => ({ [el]: acc }), {
        [last]: sortDirection,
      });
    };

    const setSortDirectionProposals = (field: string, sortDirection: any) => {
      const [last, ...paths] = field.split(".").reverse();
      proposalsOrderBy.value = paths.reduce((acc, el) => ({ [el]: acc }), {
        [last]: sortDirection,
      });
    };

    return {
      switchValue,
      secondaryTags: computed(
        () => secondaryTagsResult.value?.SecondaryTagTranslation ?? [],
      ),
      searchSecondaryTags: computed(
        () => secondaryTagsResult.value?.search_secondary_translations ?? [],
      ),
      secondaryTagsLoading,
      secondaryTagsTotal: computed(
        () =>
          secondaryTagsResult.value?.SecondaryTag_aggregate.aggregate.count ??
          0,
      ),
      searchSecondaryTagsTotal: computed(
        () =>
          secondaryTagsResult.value?.search_secondary_translations_aggregate
            .aggregate.count ?? 0,
      ),
      secondaryTagsOffset,
      secondaryTagsRowsPerPage,
      setSortDirectionSecondaryTags,
      setOffsetSecondaryTags,
      setRowsPerPageSecondaryTags,
      proposals: computed(() => proposalResult.value?.SecondaryProposals ?? []),
      proposalsLoading,
      proposalsTotal: computed(
        () =>
          proposalResult.value?.SecondaryProposals_aggregate.aggregate.count ??
          0,
      ),
      proposalsOffset,
      proposalsRowsPerPage,
      setSortDirectionProposals,
      setOffsetProposals,
      setRowsPerPageProposals,
      search,
      useSearch,
    };
  },
  methods: {
    parseDate(date: string) {
      return formatDate(date, "dd.MM.yyyy HH:mm");
    },
    onCreateClick() {
      this.$router.push({ path: "/secondary-tags/create" });
    },
    onSecondaryTagClick(tag: { SecondaryTag: { id: string } }) {
      this.$router.push({
        path: `/secondary-tags/update/${tag.SecondaryTag.id}`,
      });
    },
  },
});
</script>

<style scoped>
.content {
  @apply px-4 py-4;

  .switch {
    @apply flex justify-start items-center;
  }

  .add-secondary-tag {
    @apply text-primary font-bold;
  }

  .caption {
    @apply float-left text-base font-black mb-5;
  }

  .search-wrapper {
    @apply flex w-full items-center justify-end;

    .search {
      @apply flex-grow-0 w-1/6;
    }
  }
}
</style>
