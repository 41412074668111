import gql from "graphql-tag";

export const sendPasswordResetLinkToOrganizerAccountMail = gql`
  mutation frontend_organizerAccount_sendPasswordResetLinkToOrganizerAccountMail(
    $organizerAccountMail: String!
  ) {
    sendPasswordResetLinkToOrganizerAccountMail(
      organizerAccountMail: $organizerAccountMail
    ) {
      organizerAccountMail
    }
  }
`;
