<template lang="pug">
UiModal.location-picker-modal(v-show="showModal", @close="$emit('close')")
  template(#content)
    .location-picker-map(ref="mapDiv")
    img.location-picker-icon(
      src="../../assets/icons/locationPicker.png",
      alt="Location Picker Icon"
    )
    UiButton.location-picker-button(
      style-ids="primary",
      @click="locationPicked"
    ) Koordinaten verwenden
    span.location-picker-coordinate-preview {{ formattedCoordinates }}
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UiButton from "../Button/UiButton.vue";
import UiModal from "../Modal/UiModal.vue";
import { Loader } from "@googlemaps/js-api-loader";
import { mapStyles } from "./mapStyles";
import { LocationPickerResult } from "./types";

// TODO Since the google variable is loaded dynamically, this default any declaration of the google variable
// is necessary to prevent errors during the build process
declare var google: any;

export default defineComponent({
  name: "UiLocationPickerModal",
  components: { UiModal, UiButton },
  props: {
    showModal: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  emits: ["close", "locationPicked"],
  data() {
    return {
      // Initial location is set to Mannheim
      currentLocation: {
        latitude: 49.485867110449696,
        longitude: 8.46263045213106,
      },
    };
  },
  computed: {
    formattedCoordinates() {
      return (
        this.currentLocation.latitude.toFixed(5) +
        "°" +
        (this.currentLocation.latitude < 0 ? "S" : "N") +
        ", " +
        this.currentLocation.longitude.toFixed(5) +
        "°" +
        (this.currentLocation.longitude < 0 ? "W" : "E")
      );
    },
  },
  mounted() {
    this.$nextTick(async () => {
      // getting the DOM element where to create the map
      const mapDiv = this.$refs.mapDiv;
      const loader = new Loader({
        apiKey: import.meta.env.VITE_GOOGLE_API_KEY as string,
        libraries: ["places"],
      });
      await loader.load();
      // eslint-disable-next-line no-undef
      const map = new google.maps.Map(mapDiv, {
        center: {
          lat: this.currentLocation.latitude,
          lng: this.currentLocation.longitude,
        },
        zoom: 8,
        styles: mapStyles,
        // eslint-disable-next-line no-undef
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true,
      });

      map.addListener("center_changed", () => {
        const mapCenter = map.getCenter();
        this.currentLocation = {
          latitude: mapCenter.lat(),
          longitude: mapCenter.lng(),
        };
      });
    });
  },
  methods: {
    locationPicked() {
      const coordinatesToReturn = {
        locationAlias: this.formattedCoordinates,
        location: {
          type: "Point",
          coordinates: [
            this.currentLocation.longitude,
            this.currentLocation.latitude,
          ],
        },
      } as LocationPickerResult;
      this.$emit("locationPicked", coordinatesToReturn);
    },
  },
});
</script>

<style scoped>
.location-picker-modal:deep .modal {
  width: 45%;
  height: 60%;
  @apply p-0;
}

.location-picker-modal:deep .modal-body {
  @apply w-full h-full m-0 p-0;
}

.location-picker {
  &-map {
    @apply w-full h-full bg-background-contrast;
  }

  &-icon {
    @apply absolute mx-auto left-0 right-0 text-center;
    width: 44px;
    height: 54px;
    top: calc(50% - 54px);
  }

  &-button {
    @apply absolute mx-auto left-0 right-0 text-center;
    width: 40%;
    bottom: 50px;
  }

  &-coordinate-preview {
    @apply absolute rounded-md py-0.5 px-1.5;
    right: 2%;
    top: 2%;
    background-color: black;
  }
}
</style>
