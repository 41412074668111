<template lang="pug">
UiAutoComplete.auto-complete(
  ref="autocomplete"
  v-model="input",
  :loading="loading",
  :style-ids="styleIds",
  :placeholder="placeholder",
  :show-suggestions="showPredictions",
  :validation-rules="validationRules",
  :name="name",
  v-bind="$attrs",
  @focusout="onFocusOut",
)
  // This forwards the icon slot to the TextInput component
  template(v-for="(_, slot) of $slots", #[slot]="scope")
    slot(:name="slot", v-bind="scope")
  .suggestion-item(
    v-for="(prediction, i) in predictions",
    :key="i"
    @mousedown="onLocationSelected(prediction, searchForAddresses)"
  )
    .main-text {{ prediction.structured_formatting.main_text }}
    .secondary-text {{ prediction.structured_formatting.secondary_text }}

  .empty-list-item(v-if="predictionsAreEmpty")
    .main-text {{ !searchForAddresses && fetchPolicy !== "address-only" ? "Keinen passenden Ort gefunden." : "Keine passende Adresse gefunden." }}

  .address-switch-item(
    v-if="!searchForAddresses && fetchPolicy !== 'address-only'"
    @mousedown.prevent="searchForAddress",
  )
    .address-switch-item-text Adresse eingegeben? Hier klicken.
  template(#footer)
    .footer
      .google-logo-wrapper
        img.google-logo(
          alt="google attribution",
          src="../../assets/logos/powered_by_google_on_non_white.png"
        )
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, toRefs } from "vue";
import { Loader } from "@googlemaps/js-api-loader";
import { STYLE_IDS_PROP_DEF, useStyleIdClasses } from "../utils/style-ids";
import UiAutoComplete from "../AutoComplete/UiAutoComplete.vue";
import {
  AddressComponent,
  AutocompletePrediction,
  PlaceResult,
  PlacesServiceStatus,
} from "./types";

export default defineComponent({
  name: "UiLocationAutoComplete",
  components: { UiAutoComplete },
  props: {
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    name: {
      type: String,
      required: false,
      default: "default",
    },
    locationAlias: {
      type: String,
      required: false,
      default: "",
    },
    fetchPolicy: {
      type: String,
      required: true,
      default: "establishment-first",
      validator: (value: string) =>
        ["establishment-first", "address-only"].includes(value),
    },
    validationRules: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    loading: { type: Boolean, default: false },
    styleIds: STYLE_IDS_PROP_DEF,
  },
  emits: ["onSelect"],
  setup(props) {
    const { fetchPolicy, styleIds } = toRefs(props);

    const autocompleteService = ref<any>(undefined);
    const placesService = ref<any>(undefined);
    const searchForAddresses = ref(false);
    // eslint-disable-next-line no-undef
    const sessionToken = ref<google.maps.places.AutocompleteSessionToken>("");
    const predictions = ref<AutocompletePrediction[]>([]);
    let showPredictions = ref(false);

    const displayPredictions = function (
      autoCompletePredictions: AutocompletePrediction[],
      status: PlacesServiceStatus
    ) {
      if (status !== PlacesServiceStatus.OK || !autoCompletePredictions) {
        predictions.value = [];
      } else {
        predictions.value = autoCompletePredictions;
        if (autoCompletePredictions.length > 0) {
          showPredictions.value = true;
        }
      }
    };

    onMounted(async () => {
      const loader = new Loader({
        apiKey: import.meta.env.VITE_GOOGLE_API_KEY as string,
        libraries: ["places"],
      });
      await loader.load();
      // eslint-disable-next-line no-undef
      sessionToken.value = new google.maps.places.AutocompleteSessionToken();
      // eslint-disable-next-line no-undef
      autocompleteService.value = new google.maps.places.AutocompleteService();
      // The attribution gets displayed manually, passing a hidden element is a workaround
      // eslint-disable-next-line no-undef
      placesService.value = new google.maps.places.PlacesService(
        document.createElement("div")
      );
    });

    const onInput = (newInput: string) => {
      const queryTypes = searchForAddresses.value
        ? ["address"]
        : fetchPolicy.value === "establishment-first"
        ? ["establishment"]
        : ["address"];
      if (autocompleteService.value) {
        // Only query addresses within Germany
        autocompleteService.value.getPlacePredictions(
          {
            input: newInput,
            componentRestrictions: { country: "DE" },
            types: queryTypes,
            sessionToken: sessionToken.value,
          },
          displayPredictions
        );
      }
    };

    const resetSessionToken = () => {
      // eslint-disable-next-line no-undef
      if (google) {
        // eslint-disable-next-line no-undef
        sessionToken.value = new google.maps.places.AutocompleteSessionToken();
      }
    };

    return {
      styleIdClasses: useStyleIdClasses(styleIds),
      predictions,
      onInput,
      showPredictions,
      placesService,
      sessionToken,
      searchForAddresses,
      resetSessionToken,
    };
  },
  data() {
    return {
      input: this.locationAlias,
      locationHasBeenSelected: false,
      locationHasBeenReset: false,
      selectedLocationAlias: "",
    };
  },
  computed: {
    predictionsAreEmpty() {
      return this.predictions.length === 0;
    },
  },
  watch: {
    input() {
      if (!this.locationHasBeenSelected && !this.locationHasBeenReset) {
        this.onInput(this.input);
      } else {
        this.locationHasBeenSelected = false;
        this.locationHasBeenReset = false;
      }
    },
    locationAlias() {
      if (this.locationAlias && !this.input) {
        this.input = this.locationAlias;
        this.locationHasBeenSelected = true;
      }
    },
    searchForAddresses() {
      if (this.searchForAddresses) {
        this.onInput(this.input);
      }
    },
  },
  methods: {
    onLocationSelected(
      prediction: AutocompletePrediction,
      searchForAddresses: boolean
    ) {
      const fieldsToFetch = [
        "geometry",
        "formatted_address",
        "adr_address",
        "address_components",
      ];
      if (!searchForAddresses) {
        fieldsToFetch.push("name");
      }
      this.placesService.getDetails(
        {
          placeId: prediction.place_id,
          fields: fieldsToFetch,
          sessionToken: this.sessionToken,
        },
        this.propagateSelectedLocation
      );
      this.showPredictions = false;
      this.locationHasBeenSelected = true;
      this.resetSessionToken();
      // TODO Loading animation to indicate that the selection is handled
    },
    propagateSelectedLocation(place: PlaceResult, status: PlacesServiceStatus) {
      if (
        status === PlacesServiceStatus.OK &&
        place.geometry &&
        place.formatted_address &&
        place.address_components
      ) {
        const lon = place.geometry.location.lng();
        const lat = place.geometry.location.lat();
        const addressComponents = this.mapAddressComponents(
          place.address_components
        );
        let locationAlias = "";
        if (
          this.fetchPolicy === "establishment-first" &&
          !this.searchForAddresses &&
          addressComponents["city"] &&
          place.name
        ) {
          locationAlias = place.name + ", " + addressComponents["city"];
        } else {
          locationAlias = place.formatted_address;
        }
        const formattedPlace = {
          location: {
            coordinates: [lon, lat],
            type: "Point",
          },
          locationAlias,
          addressComponents,
        };
        this.$emit("onSelect", formattedPlace);
        this.input = locationAlias;
        this.selectedLocationAlias = locationAlias;
      } else {
        // TODO Error handling
      }
    },
    mapAddressComponents(addressComponents: AddressComponent[]) {
      const mappedAddressComponents: { [key: string]: string } = {};
      try {
        const addressComponentsMap: { [key: string]: string } = {
          street_number: "streetNumber",
          route: "streetName",
          locality: "city",
          postal_code: "postalCode",
        };

        addressComponents.forEach((component) => {
          if (
            ["street_number", "route", "locality", "postal_code"].includes(
              component.types[0]
            )
          ) {
            mappedAddressComponents[addressComponentsMap[component.types[0]]] =
              component.long_name as string;
          }
        });
      } catch (e) {
        // TODO Show error message
        console.log(e);
      }

      return mappedAddressComponents;
    },
    // if no location has been selected, clear the input or set the value to the previously selected location
    onFocusOut() {
      this.searchForAddresses = false;
      this.showPredictions = false;
      if (!this.locationHasBeenSelected) {
        if (this.selectedLocationAlias) {
          this.input = this.selectedLocationAlias;
        } else {
          this.input = "";
        }
        this.locationHasBeenReset = true;
      }
    },
    searchForAddress() {
      this.searchForAddresses = true;
    },
  },
});
</script>

<style scoped>
.auto-complete::v-deep(.suggestion-drop-down) {
  @apply bg-background-contrast z-10;
}
.suggestion-item {
  @apply px-4 py-1 relative w-full bg-background-contrast z-50;

  &:hover:not(.footer) {
    background-color: #242424;
  }

  .secondary-text {
    @apply text-subliminal text-sm -mt-1;
  }
}
.address-switch-item {
  @apply px-4 py-1 relative w-full bg-background-contrast z-50;
  &:hover:not(.footer) {
    background-color: #242424;
  }
  &-text {
    @apply text-primary;
  }
}
.empty-list-item {
  @apply px-4 py-1 relative w-full bg-background-contrast z-50;
}
.footer {
  @apply px-4 py-1 relative w-full bg-background-contrast z-50 h-7;
  @apply rounded-b-lg pb-1;
}
.google-logo-wrapper {
  @apply inline-block float-right	h-3.5;
}
.google-logo {
  @apply max-w-full max-h-full;
}
</style>
