<template lang="pug">
nav(v-if="total > 0", :class="rootClasses")
  UiSelect.ui-pag__page-select(
    v-model="selectedRowsPerPage",
    @change="changedRowsPerPage"
  )
    option(
      v-for="rowAmount in rowsPerPageList",
      :key="rowAmount"
      :value="rowAmount",
    ) {{ rowAmount }} rows
  ul.ui-pag__list
    // First
    li(v-if="hasFirst")
      ui-button(
        :class="linkClasses(1)",
        style-ids="subliminal size-sm",
        @click="changePage(1, $event)"
      ) 1
    li(v-if="hasFirstEllipsis")
      span(:class="ellipsisClasses") &hellip;

    // Pages
    li(v-for="page in pagesInRange", :key="page.number")
      ui-button(
        :class="linkClasses(page.number)",
        style-ids="size-sm subliminal",
        @click="changePage(page.number, $event)"
      ) {{ page.number }}

    //Last
    li(v-if="hasLastEllipsis")
      span(:class="ellipsisClasses") &hellip;
    li(v-if="hasLast")
      ui-button(
        :class="linkClasses(pageCount)",
        style-ids="size-sm subliminal",
        @click="changePage(pageCount, $event)"
      ) {{ pageCount }}

  ui-button(
    :class="prevBtnClasses",
    style-ids="size-sm subliminal",
    @click="changePage(current - 1, $event)"
  ) Previous
  ui-button(
    :class="nextBtnClasses",
    style-ids="size-sm subliminal",
    @click="changePage(current + 1, $event)"
  ) Next
</template>

<script>
// TODO: add typescript support
import { defineComponent } from "vue";
import UiSelect from "../Select/UiSelect.vue";
import UiButton from "../Button/UiButton.vue";

export default defineComponent({
  name: "UiTablePagination",
  components: { UiButton, UiSelect },
  provide() {
    return {
      $pagination: this,
    };
  },
  props: {
    /** Total count of items
     * TODO: add default value
     */
    total: {
      type: [Number, String],
      required: false,
      default: 10,
    },
    /** Items count for each page */
    rowsPerPage: {
      type: [Number, String],
      default: 10,
    },
    rowsPerPageList: {
      type: Array,
      default: () => [5, 10, 20, 50, 100],
    },
    /** Number of pagination items to show before current page */
    rangeBefore: {
      type: [Number, String],
      default: 1,
    },
    /** Number of pagination items to show after current page */
    rangeAfter: {
      type: [Number, String],
      default: 1,
    },
    current: {
      type: [Number, String],
      default: 1,
    },
  },
  emits: ["update:currentPage", "page-change", "update:rowsPerPage"],
  data() {
    return {
      // TODO: why?
      selectedRowsPerPage: this.rowsPerPage,
    };
  },
  computed: {
    rootClasses() {
      return {
        ["ui-pag"]: true,
        ["ui-pag--right"]: true,
      };
    },
    prevBtnClasses() {
      return [
        { "ui-pag__link": true },
        { "ui-pag__previous": true },
        { "ui-pag__link--disabled": !this.hasPrev },
      ];
    },
    ellipsisClasses() {
      return { "ui-pag__ellipsis": true };
    },
    nextBtnClasses() {
      return [
        { "ui-pag__link": true },
        { "ui-pag__next": true },
        { "ui-pag__link--disabled": !this.hasNext },
      ];
    },
    linkCurrentClasses() {
      return [{ "ui-pag__link--current": true }];
    },
    /**
     * Total page size (count).
     */
    pageCount() {
      return Math.ceil(this.total / this.rowsPerPage);
    },
    beforeCurrent() {
      return parseInt(this.rangeBefore);
    },
    afterCurrent() {
      return parseInt(this.rangeAfter);
    },
    /**
     * Get near pages, 1 before and 1 after the current.
     * Also add the click event to the array.
     */
    pagesInRange() {
      let left = Math.max(1, this.current - this.beforeCurrent);
      if (left - 1 === 2) {
        left--; // Do not show the ellipsis if there is only one to hide
      }
      let right = Math.min(this.current + this.afterCurrent, this.pageCount);
      if (this.pageCount - right === 2) {
        right++; // Do not show the ellipsis if there is only one to hide
      }
      const pages = [];
      for (let i = left; i <= right; i++) {
        pages.push(this.getPage(i));
      }
      return pages;
    },
    /**
     * Check if first page button should be visible.
     */
    hasFirst() {
      return this.current >= 2 + this.beforeCurrent;
    },
    /**
     * Check if first ellipsis should be visible.
     */
    hasFirstEllipsis() {
      return this.current >= this.beforeCurrent + 4;
    },
    /**
     * Check if last page button should be visible.
     */
    hasLast() {
      return this.current <= this.pageCount - (1 + this.afterCurrent);
    },
    /**
     * Check if last ellipsis should be visible.
     */
    hasLastEllipsis() {
      return this.current < this.pageCount - (2 + this.afterCurrent);
    },
    /**
     * Check if previous button is available.
     */
    hasPrev() {
      return this.current > 1;
    },
    /**
     * Check if next button is available.
     */
    hasNext() {
      return this.current < this.pageCount;
    },
  },
  methods: {
    linkClasses(num = -1) {
      return [
        { "ui-pag__link": true },
        { "ui-pag__link--current": num === this.current },
      ];
    },
    /**
     * Previous button click listener.
     */
    prev(event) {
      this.changePage(this.current - 1, event);
    },
    /**
     * Next button click listener.
     */
    next(event) {
      this.changePage(this.current + 1, event);
    },
    /**
     * First button click listener.
     */
    first(event) {
      this.changePage(1, event);
    },
    /**
     * Last button click listener.
     */
    last(event) {
      this.changePage(this.pageCount, event);
    },
    changePage(num, event) {
      if (this.current === num || num < 1 || num > this.pageCount) return;
      this.$emit("page-change", num);
      this.$emit("update:currentPage", num);
      // Set focus on element to keep tab order
      if (event && event.target) {
        this.$nextTick(() => event.target.focus());
      }
    },
    getPage(num, options = {}) {
      return {
        number: num,
        isCurrent: this.current === num,
        click: (event) => this.changePage(num, event),
        disabled: options.disabled || false,
        class: options.class || "",
      };
    },
    changedRowsPerPage() {
      this.$emit("page-change", 1);
      this.$emit("update:rowsPerPage", this.selectedRowsPerPage);
    },
  },
});
</script>

<style scoped lang="scss">
.ui-pag {
  @apply flex mt-3 items-center text-center justify-between;
  &__page-select {
    float: right;
    width: 50px;
    flex-grow: initial;
  }
  &__link {
    @apply items-center shadow-none inline-flex relative align-top justify-center	text-center no-underline cursor-pointer	mx-1;
    &:hover {
      @apply no-underline;
    }
    &--disabled {
      @apply pointer-events-none opacity-50;
    }
    &--current {
      @apply pointer-events-none bg-primary;
    }
  }
  &__ellipsis {
    @apply justify-center text-center pointer-events-none mx-2;
  }
  &--right {
    @apply justify-end;
    .ui-pag__previous {
      @apply order-2;
    }
    .ui-pag__next {
      @apply order-3 mr-0;
    }
    .ui-pag__list {
      @apply order-1 justify-end;
    }
  }
  &__list {
    @apply flex flex-grow	flex-shrink order-1	items-center justify-center text-center list-none	flex-wrap	m-0 p-0;
  }
}
</style>
