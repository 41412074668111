import { computed, Ref } from "vue";

// From https://stackoverflow.com/a/67243723
/*const kebabize = (str: string) =>
  str.replace(
    /[A-Z]+(?![a-z])|[A-Z]/g,
    ($, ofs) => (ofs ? "-" : "") + $.toLowerCase()
  );*/

export const STYLE_IDS_PROP_NAME = "styleIds";
export const STYLE_IDS_PROP_DEF = {
  type: [String, Array, Object],
  default: null,
};

export const STYLE_IDS_PROP = { [STYLE_IDS_PROP_NAME]: STYLE_IDS_PROP_DEF };

// TODO: add proper typing
export const useStyleIdClasses = (styleIdsProp: Ref<unknown>) => {
  // Allows for passing the whole props object (isProxy returns true if it's a reactive object)
  /*if (isProxy(styleIdsProp))
    styleIdsProp = toRef(styleIdsProp as object, STYLE_IDS_PROP_NAME);*/

  // eslint-disable-next-line vue/return-in-computed-property
  return computed(() => {
    const styleIds = styleIdsProp.value;

    if (styleIds == null) {
      return [];
    } else if (typeof styleIds === "string") {
      const styleIdsArray = styleIds.split(" ");

      const obj = {};
      styleIdsArray.forEach((style) => {
        Object.assign(obj, { [`style-${style}`]: true });
      });
      return obj;
    } else if (typeof styleIds === "object") {
      for (const key of Object.keys(styleIds)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        styleIds[`style-${key}`] = styleIds[key];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete styleIds[key];
      }
      return styleIds;
    }
  });
};

export const withStyleIds = (component: any) => ({
  ...component,
  props: {
    ...STYLE_IDS_PROP,
    ...component.props,
  },
  setup: (props: any, ...args: any) => ({
    styleIdClasses: useStyleIdClasses(props),
    ...component.setup?.(props, ...args),
  }),
});
