import clubMusicIcon from "../../../assets/primaryTags/ClubMusic.png";
import liveMusicIcon from "../../../assets/primaryTags/LiveMusic.png";
import talksAndWorkshopsIcon from "../../../assets/primaryTags/Talks&Workshops.png";
import artsAndExhibitionsIcon from "../../../assets/primaryTags/Art&Exhibitions.png";
import sportEventsIcon from "../../../assets/primaryTags/SportEvents.png";
import cultureAndTheatreIcon from "../../../assets/primaryTags/Culture&Theatre.png";
import foodAndDrinksIcon from "../../../assets/primaryTags/Food&Drinks.png";
import vaccinationOffersIcon from "../../../assets/primaryTags/VaccinationOffers.png";

export default {
  "440cc585-920d-405c-b099-8ddb1b60af06": clubMusicIcon,
  "c04b6dac-4f26-4803-b25f-b099f87181bf": liveMusicIcon,
  "b120cc4b-b1ae-4619-9af0-199ddd9e13a1": talksAndWorkshopsIcon,
  "8e37c52b-a869-40db-9af9-d97c752b1f25": artsAndExhibitionsIcon,
  "f51fc6d6-cf56-4193-b28f-0a1f67bba86c": sportEventsIcon,
  "898645b9-7c90-482a-bfab-973b496f2c70": cultureAndTheatreIcon,
  "267815cb-f747-4ffd-ba8c-f02a782b4cfc": foodAndDrinksIcon,
  "984109fd-f500-44cc-8846-3211865aa0f9": vaccinationOffersIcon,
};
