<template lang="pug">
AuthenticationHeader
  p.company-subline Welcome back! Please login to your account.
  form.flex.flex-col.items-center.w-full(@submit="onSubmit")
    UiTextInput.w-full.mt-6(
      name="email",
      v-model="email",
      placeholder="E-Mail",
      :validation-rules="{ required: true }",
      style-ids="login",
      @keyup.enter="onSubmit"
    )
    UiTextInput.w-full.mt-6(
      name="password",
      v-model="password",
      type="password",
      :validation-rules="{ required: true }",
      placeholder="Password",
      style-ids="login",
      @keyup.enter="onSubmit"
    )
    .login-error {{ loginErrorMessage }}
    .mt-8.flex.flex-row.items-center.justify-between.w-full
      .flex.items-center
        UiSwitch(v-model="rememberLogin")
        label.switch-label Remember me
      .forgot-password(@click="$emit('forgotPasswordClicked')") Forgot Password?
  .mt-11.flex.flex-grow.justify-between.w-full
    UiButton(style-ids="primary login", type="submit", @click="onSubmit") Login
    a(href="https://pingr.de" target="_blank")
      UiButton.ml-10(
        style-ids="subliminal login",
      ) Sign Up
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import UiSwitch from "../../../ui/Switch/UiSwitch.vue";
import UiButton from "../../../ui/Button/UiButton.vue";
import UiTextInput from "../../../ui/TextInput/UiTextInput.vue";
import AuthenticationHeader from "./AuthenticationHeader.vue";
import { useForm } from "vee-validate";
import {
  browserLocalPersistence,
  browserSessionPersistence,
  getAuth,
  signInWithEmailAndPassword,
} from "firebase/auth";

export default defineComponent({
  name: "LoginForm",
  components: { UiSwitch, UiButton, UiTextInput, AuthenticationHeader },
  emits: ["forgotPasswordClicked"],
  setup() {
    const email = ref("");
    const password = ref("");
    const rememberLogin = ref(false);
    const loginErrorMessage = ref("");

    const { handleSubmit } = useForm();

    const onSubmit = handleSubmit(async () => {
      await getAuth().setPersistence(
        rememberLogin.value
          ? browserLocalPersistence
          : browserSessionPersistence
      );
      try {
        await signInWithEmailAndPassword(
          getAuth(),
          email.value,
          password.value
        );
      } catch (e) {
        loginErrorMessage.value = "Incorrect username or password";
      }
    });

    return {
      onSubmit,
      email,
      password,
      loginErrorMessage,
      rememberLogin,
    };
  },
});
</script>

<style scoped>
.form {
  @apply flex flex-col items-center justify-center;

  min-width: 21rem;
}

.company {
  &-name {
    @apply text-3xl font-black tracking-widest;
    @apply mt-2;
  }
  &-subtext {
    @apply font-bold text-sm tracking-widest;
    @apply mt-0.5;
  }

  &-subline {
    @apply text-sm text-center text-text-subliminal;
    @apply mt-2.5;
  }
}
.login-error {
  @apply text-sm text-text-highlight;
  @apply mt-2 h-2 self-start;
}

.forgot-password {
  @apply text-sm cursor-pointer underline text-text-subliminal;
}
.switch {
  @apply w-10 h-6 cursor-pointer rounded-2xl relative inline-block appearance-none;
  transition: all 0.25s linear;

  &::before {
    @apply absolute left-0 rounded-2xl w-9 h-5;

    content: "";
    background-color: rgb(73, 73, 73);
    transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
    transition: all 0.2s linear;
  }

  &::after {
    @apply absolute left-0 rounded-2xl;

    /* no matching tailwind classes found */
    content: "";
    width: 18px;
    height: 18px;
    background-color: rgb(255, 255, 255);
    transform: translate3d(3px, 3px, 0);
    transition: all 0.2s ease-in-out;
  }

  &:active::after {
    width: 23px;
    transform: translate3d(2px, 3px, 0);
  }

  &:active:checked::after {
    transform: translate3d(15px, 3px, 0);
  }

  &:checked {
    @apply bg-primary;
  }

  &:checked::before {
    transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
  }

  &:checked::after {
    transform: translate3d(20px, 3px, 0);
  }

  &:checked + &-label {
    color: #fff;
  }

  &-label {
    @apply text-sm;
  }
}
</style>
