<template lang="pug">
.picker-input(
  ref="picker-input"
  :class="{ ...styleIdClasses, 'border-background-highlight': errorMessage, 'border-background-contrast': !errorMessage, 'cursor-not-allowed': disabled, 'is-focused': isFocused, 'animate-pulse': loading }",
  @click="pickerClicked",
)
  .text-wrapper(
    ref="text-wrapper",
    :class="{ 'cursor-not-allowed': disabled }"
  )
    .day.input-element(
      ref="day"
      :class="{ 'text-hidden': !isFocused && !emittedDate, 'cursor-not-allowed': disabled }",
      :tabindex="disabled ? -1 : 0",
      @click="setFocusedElement('day')",
      @focus="setFocusedElement('day')",
      @blur="setFocusedElement('')",
      @keydown="onKeypressClicked",
    ) {{ focusedDateData.day === -1 ? "dd" : ("0" + focusedDateData.day).slice(-2) }}
    span(
      :class="{ 'text-hidden': !isFocused && !emittedDate, 'cursor-not-allowed': disabled }",
      @click="setFocusedElement('day')"
    ) .
    .month.input-element(
      ref="month",
      :class="{ 'text-hidden': !isFocused && !emittedDate, 'cursor-not-allowed': disabled }",
      :tabindex="disabled ? -1 : 0"
      @click="setFocusedElement('month')",
      @focus="setFocusedElement('month')",
      @blur="setFocusedElement('')",
      @keydown="onKeypressClicked",
    ) {{ focusedDateData.month === -1 ? "mm" : ("0" + focusedDateData.month).slice(-2) }}
    span(
      :class="{ 'text-hidden': !isFocused && !emittedDate, 'cursor-not-allowed': disabled }",
      @click="setFocusedElement('month')"
    ) .
    .year.mr-2.input-element(
      ref="year"
      :class="{ 'text-hidden': !isFocused && !emittedDate, 'cursor-not-allowed': disabled }",
      :tabindex="disabled ? -1 : 0",
      @click="setFocusedElement('year')",
      @focus="setFocusedElement('year')",
      @blur="setFocusedElement('')",
      @keydown="onKeypressClicked",
    ) {{ focusedDateData.year === -1 ? "yyyy" : (focusedDateData.year + "yyy").substring(0, 4) }}
    .hour.input-element(
      ref="hour"
      :class="{ 'text-hidden': !isFocused && !emittedDate, 'cursor-not-allowed': disabled }",
      :tabindex="disabled ? -1 : 0",
      @click="setFocusedElement('hour')",
      @focus="setFocusedElement('hour')",
      @blur="setFocusedElement('')",
      @keydown="onKeypressClicked",
    ) {{ focusedDateData.hours === -1 ? "hh" : ("0" + focusedDateData.hours).slice(-2) }}
    span(
      :class="{ 'text-hidden': !isFocused && !emittedDate, 'cursor-not-allowed': disabled }",
      @click="setFocusedElement('hour')"
    ) :
    .minute.input-element(
      ref="minute"
      :class="{ 'text-hidden': !isFocused && !emittedDate, 'cursor-not-allowed': disabled }",
      :tabindex="disabled ? -1 : 0",
      @click="setFocusedElement('minute')",
      @focus="setFocusedElement('minute')",
      @blur="setFocusedElement('')",
      @keydown="onKeypressClicked",
    ) {{ focusedDateData.minutes === -1 ? "mm" : ("0" + focusedDateData.minutes).slice(-2) }}
  .error-indicator(v-if="errorMessage")
    .indicator !
    .error-message {{ errorMessage }}
</template>

<script lang="ts">
import { defineComponent, Ref, ref, toRef, toRefs } from "vue";
import { STYLE_IDS_PROP_DEF, useStyleIdClasses } from "../utils/style-ids";
import { useField } from "vee-validate";

export default defineComponent({
  name: "UiDateTimePickerInput",
  props: {
    value: { type: Date, default: undefined },
    validationRules: { type: Object, required: false, default: () => ({}) },
    disabled: { type: Boolean, required: false, default: false },
    name: { type: String, required: false, default: "default" },
    styleIds: STYLE_IDS_PROP_DEF,
    isFocused: { type: Boolean, default: false },
    loading: { type: Boolean, required: false, default: false },
  },
  emits: ["dateChanged"],
  setup(props) {
    const { validationRules, name, value } = toRefs(props);

    let returnValue = {
      styleIdClasses: useStyleIdClasses(toRef(props, "styleIds")),
      fieldName: name,
      errorMessage: ref("") as Ref<string | undefined>,
      inputValue: ref<Date | undefined>(undefined),
    };

    if (Object.keys(validationRules.value).length > 0) {
      const { errorMessage, value: fieldValue } = useField<undefined | Date>(
        name,
        validationRules
      );

      returnValue.errorMessage = errorMessage;
      returnValue.inputValue = fieldValue;
    } else {
      returnValue.errorMessage = ref(undefined);
      returnValue.inputValue = value;
    }

    return returnValue;
  },
  data() {
    return {
      focusedElement: "",
      elementInput: "",
      emittedDate: undefined as undefined | Date,
      focusedDateData: {
        minutes: -1,
        hours: -1,
        day: -1,
        month: -1,
        year: -1,
      },
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, old) {
        if (newValue) {
          this.inputValue = newValue;
          this.focusedDateData = {
            year: newValue.getFullYear(),
            month: newValue.getMonth() + 1, // 0 (January) and 11 (December)
            day: newValue.getDate(),
            hours: newValue.getHours(),
            minutes: newValue.getMinutes(),
          };
          this.emittedDate = newValue;
          /*this.setFocusedElement("day");
          (this.$refs.day as HTMLDivElement).focus();*/
        } else if (!newValue && old) {
          this.inputValue = undefined;
          this.emittedDate = undefined;
        }
      },
    },
  },
  methods: {
    pickerClicked(event: MouseEvent) {
      if (
        event.target === this.$refs["picker-input"] ||
        event.target === this.$refs["text-wrapper"]
      ) {
        this.setFocusedElement("day");
        (this.$refs.day as HTMLDivElement).focus();
      }
    },
    showDateText() {
      const currentDate = this.focusedDateData;
      return (
        currentDate.day > 0 &&
        currentDate.month > 0 &&
        currentDate.year > 0 &&
        currentDate.minutes >= 0 &&
        currentDate.hours >= 0
      );
    },
    emitDateChange() {
      const currentDate = this.focusedDateData;
      if (
        currentDate.day > 0 &&
        currentDate.month > 0 &&
        currentDate.year > 0
      ) {
        let dateToEmit = undefined;
        if (currentDate.minutes >= 0 && currentDate.hours >= 0) {
          dateToEmit = new Date(
            currentDate.year,
            currentDate.month - 1, // 0 (January) and 11 (December)
            currentDate.day,
            currentDate.hours,
            currentDate.minutes
          );
          this.$emit("dateChanged", dateToEmit);
          this.emittedDate = dateToEmit;
        } else {
          dateToEmit = new Date(
            currentDate.year,
            currentDate.month - 1,
            currentDate.day
          ); // 0 (January) and 11 (December)
          this.$emit("dateChanged", dateToEmit);
          this.emittedDate = dateToEmit;
        }
        this.inputValue = dateToEmit;
      }
    },
    setFocusedElement(focusedElement: string) {
      if (!this.disabled) {
        this.focusedElement = focusedElement;
      }
    },
    onFocusOut() {
      this.setFocusedElement("");
    },
    setDay(day: number) {
      this.focusedDateData.day = day;
      this.elementInput = "";
      this.setFocusedElement("month");
      (this.$refs.month as HTMLDivElement).focus();
      this.emitDateChange();
    },
    setMonth(month: number) {
      this.focusedDateData.month = month;
      this.elementInput = "";
      this.setFocusedElement("year");
      (this.$refs.year as HTMLDivElement).focus();
      this.emitDateChange();
    },
    setYear(year: number) {
      this.focusedDateData.year = year;
      this.elementInput = "";
      this.setFocusedElement("hour");
      (this.$refs.hour as HTMLDivElement).focus();
      this.emitDateChange();
    },
    setHour(hour: number) {
      this.focusedDateData.hours = hour;
      this.elementInput = "";
      this.setFocusedElement("minute");
      (this.$refs.minute as HTMLDivElement).focus();
      this.emitDateChange();
    },
    setMinute(minute: number) {
      this.focusedDateData.minutes = minute;
      this.elementInput = "";
      this.emitDateChange();
    },
    onKeypressClicked(event: KeyboardEvent) {
      const keyString = event.key;
      const keyNumber = parseInt(keyString);
      if (isFinite(keyNumber)) {
        switch (this.focusedElement) {
          case "day": {
            // highest allowed number at the first position is 3, otherwise skip to the month section
            if (this.elementInput.length === 0 && keyNumber >= 4) {
              this.setDay(keyNumber);
              break;
            }
            if (this.elementInput.length === 1) {
              this.elementInput += keyString;
              if (parseInt(this.elementInput) > 31) {
                this.setDay(31);
              } else {
                if (parseInt(this.elementInput) < 1) {
                  this.setDay(1);
                } else {
                  this.setDay(parseInt(this.elementInput));
                }
              }
            } else {
              this.elementInput += keyString;
              this.focusedDateData.day = keyNumber;
            }
            break;
          }
          case "month": {
            // highest allowed number at the first position is 1, otherwise skip to the year section
            if (this.elementInput.length === 0 && keyNumber >= 2) {
              this.setMonth(keyNumber);
              break;
            }
            if (this.elementInput.length === 1) {
              this.elementInput += keyString;
              if (parseInt(this.elementInput) > 12) {
                this.setMonth(12);
              } else {
                if (parseInt(this.elementInput) < 1) {
                  this.setMonth(1);
                } else {
                  this.setMonth(parseInt(this.elementInput));
                }
              }
            } else {
              this.elementInput += keyString;
              this.focusedDateData.month = keyNumber;
            }
            break;
          }
          case "year": {
            this.elementInput += keyString;
            this.focusedDateData.year = parseInt(this.elementInput);
            if (this.elementInput.length === 4) {
              const currentYear = new Date().getFullYear();
              const currentYearInput = parseInt(this.elementInput);
              if (
                currentYearInput > currentYear + 10 ||
                currentYearInput < currentYear - 10
              ) {
                this.setYear(currentYear);
              } else {
                this.setYear(currentYearInput);
              }
            }
            break;
          }
          case "hour": {
            // highest allowed number at the first position is 2, otherwise skip to the minutes section
            if (this.elementInput.length === 0 && keyNumber >= 3) {
              this.setHour(keyNumber);
              break;
            }
            if (this.elementInput.length === 1) {
              this.elementInput += keyString;
              if (parseInt(this.elementInput) > 23) {
                this.setHour(23);
              } else {
                this.setHour(parseInt(this.elementInput));
              }
            } else {
              this.elementInput += keyString;
              this.focusedDateData.hours = keyNumber;
            }
            break;
          }
          case "minute": {
            // highest allowed number at the first position is 5 (e.g., 23:59)
            if (this.elementInput.length === 0 && keyNumber >= 6) {
              this.setMinute(keyNumber);
              break;
            }
            if (this.elementInput.length === 1) {
              this.elementInput += keyString;
              if (parseInt(this.elementInput) > 59) {
                this.setMinute(59);
              } else {
                this.setMinute(parseInt(this.elementInput));
              }
            } else {
              this.elementInput += keyString;
              this.focusedDateData.minutes = keyNumber;
            }
            break;
          }
        }
      }
      if (keyString === "ArrowRight") {
        if (this.focusedElement === "day") {
          this.setFocusedElement("month");
          (this.$refs.month as HTMLDivElement).focus();
        } else {
          if (this.focusedElement === "month") {
            this.setFocusedElement("year");
            (this.$refs.year as HTMLDivElement).focus();
          } else {
            if (this.focusedElement === "year") {
              this.setFocusedElement("hour");
              (this.$refs.hour as HTMLDivElement).focus();
            } else {
              if (this.focusedElement === "hour") {
                this.setFocusedElement("minute");
                (this.$refs.minute as HTMLDivElement).focus();
              }
            }
          }
        }
      }
      if (keyString === "ArrowLeft") {
        if (this.focusedElement === "minute") {
          this.setFocusedElement("hour");
          (this.$refs.hour as HTMLDivElement).focus();
        } else {
          if (this.focusedElement === "hour") {
            this.setFocusedElement("year");
            (this.$refs.year as HTMLDivElement).focus();
          } else {
            if (this.focusedElement === "year") {
              this.setFocusedElement("month");
              (this.$refs.month as HTMLDivElement).focus();
            } else {
              if (this.focusedElement === "month") {
                this.setFocusedElement("day");
                (this.$refs.day as HTMLDivElement).focus();
              }
            }
          }
        }
      }
      if (keyString === "Tab") {
        this.setFocusedElement("");
      }
    },
  },
});
</script>

<style scoped>
.picker-input {
  @apply flex flex-row flex-grow relative items-center;
  @apply px-3 py-2;
  @apply bg-background-input;

  :not(.cursor-not-allowed) {
    cursor: text;
  }

  .is-focused {
    @apply border-primary;
  }

  .text-hidden {
    @apply text-background-input;
  }

  .text-wrapper {
    @apply text-text-default text-sm;
    @apply flex flex-row flex-grow relative items-center;
  }

  .input-element {
    &:focus:not(.cursor-not-allowed) {
      @apply outline-none bg-primary text-background-dark;
    }
  }

  .error-indicator {
    @apply flex justify-center items-center relative;

    .indicator {
      @apply h-5 w-5 bg-background-highlight;
      @apply rounded-xl;
      @apply flex justify-center items-center;

      /* todo: find better way to centralize vertically in parent */
      @apply absolute -right-1 -top-3;

      &:hover {
        & + div {
          @apply block;
        }
      }
    }

    .error-message {
      @apply absolute -right-3;
      top: -45px;
      @apply hidden;
      @apply w-60 px-4 py-1;
      @apply bg-background-highlight text-background-bright;
      @apply rounded-lg;
    }
  }
}
.style-rounded {
  @apply rounded-md;
}

.style-hover-primary {
  @apply border;

  &:hover {
    @apply border-primary;
  }
}

.is-focused {
  @apply border-primary;
}
</style>
