import gql from "graphql-tag";

export const secondaryTagFormSecondaryTagsQuery = gql`
  query frontend_secondaryTagForm_secondaryTag($id: uuid!) {
    SecondaryTag_by_pk(id: $id) {
      id
      PrimarySecondaryMappings {
        primary_id
      }
      Translations {
        id
        translation
        language
      }
    }
  }
`;

export const secondaryTagFormMetaQuery = gql`
  query frontend_secondaryTagForm_secondaryTag {
    Languages {
      language
    }
    PrimaryTag {
      id
      name
    }
  }
`;

export const secondaryTagFormUpsertSecondaryTag = gql`
  mutation frontend_secondaryTagForm_upsertSecondaryTag(
    $id: uuid!
    $translations: [SecondaryTagTranslation_insert_input!]!
    $primaryTagId: uuid!
    $deleteTranslationLanguages: [Languages_enum!]
    $name: String!
  ) {
    insert_SecondaryTag_one(
      object: {
        id: $id
        name: $name
        Translations: {
          data: $translations
          on_conflict: {
            constraint: SecondaryTagTranslation_pkey
            update_columns: translation
          }
        }
        PrimarySecondaryMappings: {
          data: { primary_id: $primaryTagId }
          on_conflict: {
            constraint: PrimarySecondaryMapping_pkey
            update_columns: primary_id
          }
        }
      }
      on_conflict: { constraint: SecondaryTag_pkey, update_columns: id }
    ) {
      updatedAt
      name
      id
      createdAt
      Translations {
        id
        translation
        language
      }
      PrimarySecondaryMappings {
        secondary_id
        primary_id
      }
    }
    delete_SecondaryTagTranslation(
      where: {
        _and: {
          id: { _eq: $id }
          language: { _in: $deleteTranslationLanguages }
        }
      }
    ) {
      affected_rows
    }
  }
`;
