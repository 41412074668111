<template lang="pug">
.flex.mx-4.my-4
  .organizer-table
    UiTable(
      :data="admins",
      :loading="loading",
      :total="count",
      :default-sort="sortDirection",
      :current-page="paginationOffset + 1",
      :rows-per-page="rowsPerPage",
      @page-change="pageChange",
      @update:rows-per-page="rowsPerPageChanged",
      @sort="setSortDirection",
      @click="onClick($event)"
    )
      template(#caption)
        span.caption {{ title }}

      UiTableColumn(
        v-slot="props",
        label="Vorname",
        position="left",
        :sortable="true",
        field="firstName"
      ) {{ props.row.firstName }}

      UiTableColumn(
        v-slot="props",
        label="Nachname",
        position="left",
        :sortable="true",
        field="lastName"
      ) {{ props.row.lastName }}

      UiTableColumn(
        v-slot="props",
        label="E-Mail",
        position="left",
        :sortable="true",
        field="email"
      ) {{ props.row.email }}

      UiTableColumn(
        v-slot="props",
        label="Rollen",
        position="left",
        :sortable="true",
        field="email"
      ) {{ props.row.Role.map((role: { role: string }) => role.role).join(", ") }}

      UiTableColumn(
        v-slot="props",
        label="Erstellt",
        position="left",
        :sortable="true",
        field="createdAt"
      ) {{ parseDate(props.row.createdAt) }}

      UiTableColumn(
        v-slot="props",
        label="Zuletzt bearbeitet",
        position="left",
        :sortable="true",
        field="updatedAt"
      ) {{ parseDate(props.row.updatedAt) }}

      UiTableColumn(position="centered")
        template.flex.items-center(#default)
        template(#header)
          button.add-organizer-button(@click="addAdmin") + Admin hinzufügen
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import { useQuery } from "@vue/apollo-composable";
import { useToast } from "vue-toastification";
import { formatDate } from "../../../../../utils/dateFormatter";
import UiTable from "../../../../../ui/Table/UiTable.vue";
import UiTableColumn from "../../../../../ui/Table/UiTableColumn.vue";
import { adminOverview_loadAdmins } from "./queries";
import { AdminOverviewAdminUser, AdminOverviewQueryResponse } from "./types";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "AdminsOverview",
  components: {
    UiTable,
    UiTableColumn,
  },
  setup() {
    let orderBy = ref<{ [key: string]: string }>({ email: "asc" });
    const offset = ref(0);
    const rowsPerPage = ref(10);
    const router = useRouter();

    const { result, loading, error } = useQuery<AdminOverviewQueryResponse>(
      adminOverview_loadAdmins,
      () => ({
        orderBy: orderBy.value,
        limit: rowsPerPage.value,
        offset: offset.value * rowsPerPage.value,
      }),
      {
        fetchPolicy: "cache-and-network",
      }
    );

    const setSortDirection = (field: string, sortDirection: any) => {
      const [last, ...paths] = field.split(".").reverse();
      orderBy.value = paths.reduce((acc, el) => ({ [el]: acc }), {
        [last]: sortDirection,
      });
    };

    const setOffset = (newOffset: number) => {
      offset.value = newOffset;
    };

    const setRowsPerPage = (newRowsPerPage: number) => {
      rowsPerPage.value = newRowsPerPage;
    };

    return {
      admins: computed(() => result.value?.AdminUser ?? []),
      count: computed(() => result.value?.AdminUser_aggregate.aggregate.count ?? 0),
      loading,
      error: error,
      paginationOffset: offset,
      rowsPerPage,
      setSortDirection,
      setOffset,
      setRowsPerPage,
      toast: useToast(),
      router,
    };
  },
  data() {
    return {
      title: "Admin User",
      sortDirection: ["email", "asc"],
    };
  },
  methods: {
    pageChange(pageNumber: number) {
      this.setOffset(pageNumber - 1);
    },
    rowsPerPageChanged(rowsPerPage: number) {
      this.setRowsPerPage(rowsPerPage);
    },
    parseDate(date: string) {
      return formatDate(date, "dd.MM.yyyy HH:mm");
    },
    addAdmin() {
      this.router.push({ name: "CreateAdmin" });
    },
    onClick(user: AdminOverviewAdminUser) {
      this.router.push({ name: "UpdateAdmin", params: { id: user.id } });
    },
  },
});
</script>

<style scoped>
.caption {
  @apply float-left text-base font-black mb-5;
}
.organizer-table {
  @apply flex w-full;
}

.add-organizer-button {
  @apply text-primary font-bold;
}

.button-context {
  @apply font-black text-xl;
}
.cancel-event-item {
  @apply text-background-highlight;
}
</style>
