<template lang="pug">
.update-event-container
  .cancelled-banner(v-if="event.cancelled")
    span Event wurde abgesagt!
  .area-divider
    .left
      .header(v-if="false")
        .header-left
          p.header-item.active Eckdaten
          p.header-item Ticketing
          p.header-item Guestlist
          p.header-item.header-item-last Sharing
        .header-right
          p.header-item Statistics
      UiMessage.event-in-past-message(
        :active="eventIsInPast && !event.cancelled"
      )
        span Bereits stattgefundene Events können nicht mehr bearbeitet werden.
      EventForm(
        :mode="updateMode",
        v-model="event",
        @onSubmit="updatePublicEventClicked",
        @onCancelEvent="cancelPublicEventClicked",
        :disabled="eventIsInPast",
        :loading="loading"
      )
      UiLoadingOverlay(v-if="isCanceling || isUpdating") {{ isUpdating ? "Die Veranstaltung wird aktualisiert..." : "Die Veranstaltung wird abgesagt..." }}
    .right
      EventPreviewArea(:event="event")
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import { useMutation } from "@vue/apollo-composable";
import { useRoute, useRouter } from "vue-router";
import EventPreviewArea from "../Preview/EventPreviewArea.vue";
import EventForm from "../Form/index.vue";
import {
  EventFormCancelPublicEventResponse,
  EventUpdateResponse,
  LoadEventCoverResponse,
  PublicEventUpdate,
} from "./types";
import {
  event_update,
  eventForm_cancelPublicEvent,
  eventForm_loadEventCover,
} from "./queries";
import { EventFormMode } from "../Create/types";
import router from "../../../infrastructure/router";
import { TYPE, useToast } from "vue-toastification";
import UiMessage from "../../../ui/Message/UiMessage.vue";
import { isBefore } from "date-fns";
import UiLoadingOverlay from "../../../ui/LoadingOverlay/LoadingOverlay.vue";
import { useBeforeRouteLeaveAlert } from "../../../utils/beforeRouteLeaveAlertMixin";
import isEqual from "lodash.isequal";
import { useLoadEventById } from "./utils";

export default defineComponent({
  name: "UpdateEvent",
  components: {
    UiMessage,
    EventForm,
    EventPreviewArea,
    UiLoadingOverlay,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const eventId = route.params.eventId;

    const { event, initialEvent, loading } =
      useLoadEventById<PublicEventUpdate>(eventId as string);

    const { mutate: updateEvent, loading: isUpdating } =
      useMutation<EventUpdateResponse>(event_update);
    const { mutate: cancelPublicEvent, loading: isCanceling } =
      useMutation<EventFormCancelPublicEventResponse>(
        eventForm_cancelPublicEvent
      );

    const eventIsInPast = computed(() => {
      if (event.startTime) {
        return isBefore(new Date(event.startTime), new Date());
      }
      return false;
    });

    const showRouteLeaveAlert = ref(false);
    const setShowRouteLeaveAlert = (value: boolean) => {
      showRouteLeaveAlert.value = value;
    };
    useBeforeRouteLeaveAlert(showRouteLeaveAlert);

    watch(event, () => {
      if (
        // don't show the alert if the event is in the past
        !isBefore(new Date(event.startTime), new Date()) &&
        !isEqual(initialEvent, event)
      ) {
        showRouteLeaveAlert.value = true;
      }
    });

    return {
      router,
      toast,
      loading,
      isUpdating,
      isCanceling,
      event,
      updateEvent,
      cancelPublicEvent,
      eventIsInPast,
      setShowRouteLeaveAlert,
    };
  },
  computed: {
    updateMode() {
      return EventFormMode.UPDATE;
    },
  },
  methods: {
    updatePublicEventClicked() {
      this.setShowRouteLeaveAlert(false);
      const eventToUpdate = { ...this.event };
      if (eventToUpdate.secondaryTags) {
        eventToUpdate.secondaryTagIds = eventToUpdate.secondaryTags.map(
          (secondaryTag) => secondaryTag.id
        );
      }

      if (eventToUpdate.cover && eventToUpdate.cover.startsWith("http")) {
        delete eventToUpdate.cover;
      }

      this.updateEvent(eventToUpdate)
        .then(() => {
          // TODO: go back ?
          router.replace("/event-series/" + this.event.eventSeriesId);
        })
        .catch((error) => console.log(error));
    },
    cancelPublicEventClicked() {
      this.setShowRouteLeaveAlert(false);
      this.cancelPublicEvent({
        id: this.event.id,
      })
        .then(() => {
          this.router.back();
        })
        .catch((error) => {
          // TODO: log to sentry
          this.toast("Fehler beim Absagen des Events!", { type: TYPE.ERROR });
          throw error;
        });
    },
  },
});
</script>

<style scoped>
.cancelled-banner {
  @apply flex justify-center items-center;
  @apply w-full h-10;
  @apply bg-background-highlight;

  & > span {
    @apply text-lg font-bold;
  }
}

.update-event-container {
  @apply h-full flex flex-col;

  .area-divider {
    @apply h-full flex flex-row overflow-hidden;
  }

  .left {
    @apply w-1/2 pb-5;
    @apply border-r border-border-default;
    @apply overflow-auto;

    .header {
      @apply flex justify-between items-center;
      @apply border-b py-5 pr-6 border-border-default;

      &-left {
        @apply flex items-center justify-evenly;
      }

      &-right {
        @apply flex justify-center items-center;
      }

      &-item {
        @apply ml-8 cursor-pointer;
      }

      &-item.active {
        @apply text-primary;
      }
    }
  }

  .right {
    @apply w-1/2;
    @apply flex flex-col items-center;
    @apply overflow-auto;
  }
  .event-in-past-message {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 20px;
  }
}
</style>
