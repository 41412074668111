<template lang="pug">
.create-event-container
  .left
    .header(v-if="false")
      .header-left
        p.header-item.active Eckdaten
        p.header-item Ticketing
        p.header-item Guestlist
        p.header-item.header-item-last Sharing
      .header-right
        p.header-item Statistics
    EventForm(
      v-model="event",
      :mode="createMode",
      :loading="isLoadingEvent"
      @on-submit="createPublicEvent",
    )
    UiLoadingOverlay(v-if="loading") Die Veranstaltung wird erstellt...
  .right
    EventPreviewArea(:event="event")
</template>

<script lang="ts">
import { defineComponent, reactive, ref, watch } from "vue";
import EventPreviewArea from "../Preview/EventPreviewArea.vue";
import EventForm from "../Form/index.vue";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { EventFormMode, PublicEventCreate } from "./types";
import { event_create, get_event_series_name } from "./queries";
import { useRoute } from "vue-router";
import router from "../../../infrastructure/router";
import UiLoadingOverlay from "../../../ui/LoadingOverlay/LoadingOverlay.vue";
import { useToast } from "vue-toastification";
import { useBeforeRouteLeaveAlert } from "../../../utils/beforeRouteLeaveAlertMixin";
import isEqual from "lodash.isequal";
import cloneDeep from "lodash.clonedeep";
import { authenticatedArea_adminEventStatistics } from "../../../application/pages/authenticatedArea/queries";
import { storeKey } from "../../../infrastructure/store/store";
import { useStore } from "vuex";
import { useLoadEventById } from "../Update/utils";
import { loadImageAsBase64String } from "./utils";

export default defineComponent({
  name: "CreateEvent",
  components: {
    EventForm,
    EventPreviewArea,
    UiLoadingOverlay,
  },
  setup() {
    const userId = ref(useStore(storeKey).getters.user.id);

    const { mutate: createEvent, loading } = useMutation(event_create, {
      refetchQueries: [
        {
          query: authenticatedArea_adminEventStatistics,
          variables: {
            id: userId.value,
            startOfMonth: new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              1
            ).toISOString(),
            startOfYear: new Date(new Date().getFullYear(), 0, 1).toISOString(),
          },
        },
      ],
    });

    const route = useRoute();
    const toast = useToast();

    let event = reactive({} as PublicEventCreate);
    let initialEvent = reactive({} as PublicEventCreate);
    let isLoadingEvent = ref(false);

    if (route.query.sourceEvent) {
      const useLoadEventByIdObject = useLoadEventById<PublicEventCreate>(
        route.query.sourceEvent as string,
        true
      );
      event = useLoadEventByIdObject.event;
      initialEvent = useLoadEventByIdObject.initialEvent;
      // eslint-disable-next-line vue/no-ref-as-operand
      isLoadingEvent = useLoadEventByIdObject.loading;

      watch(event, async (newEvent, oldEvent) => {
        if (newEvent.cover && newEvent.cover.startsWith("http")) {
          event.startTime = "";
          loadImageAsBase64String(newEvent.cover).then((imageBase64) => {
            event.cover = imageBase64;
            initialEvent.cover = imageBase64;
          });
        }
      });

    } else {
      event = reactive({} as PublicEventCreate);
      event.cover = "";
      event.secondaryTags = [];
      event.eventSeriesId = route.params.id as string;
      event.EventSeries = {
        id: event.eventSeriesId,
        name: "Event Series Name",
      };
      initialEvent = cloneDeep(event);

      // Loading the name of the corresponding event series (should be stored in the cache)
      const { result, onResult } = useQuery(get_event_series_name, {
        id: route.params.id as string,
      });

      if (result.value && result.value.EventSeries_by_pk) {
        event.EventSeries.name = result.value.EventSeries_by_pk.name;
        initialEvent.EventSeries.name = result.value.EventSeries_by_pk.name;
      }

      onResult((result) => {
        event.EventSeries.name = result.data.EventSeries_by_pk.name;
        initialEvent.EventSeries.name = result.data.EventSeries_by_pk.name;
      });
    }

    const showRouteLeaveAlert = ref(false);
    const setShowRouteLeaveAlert = (value: boolean) => {
      showRouteLeaveAlert.value = value;
    };
    useBeforeRouteLeaveAlert(showRouteLeaveAlert);

    watch(event, () => {
      if (!isEqual(initialEvent, event)) {
        showRouteLeaveAlert.value = true;
      }
    });

    return {
      event,
      toast,
      createEvent,
      loading,
      setShowRouteLeaveAlert,
      isLoadingEvent,
    };
  },
  computed: {
    createMode() {
      return EventFormMode.CREATE;
    },
  },
  methods: {
    createPublicEvent() {
      this.setShowRouteLeaveAlert(false);
      this.createEvent(this.event)
        .then(() => {
          router.back();
        })
        .catch((error) => {
          this.toast.error("Fehler beim Erstellen des Events");
          throw error;
        });
    },
  },
});
</script>

<style scoped>
.create-event-container {
  @apply h-full w-full flex flex-row overflow-hidden;

  .left {
    @apply w-1/2 pb-5;
    @apply border-r border-border-default;
    @apply overflow-auto;

    .header {
      @apply flex justify-between items-center;
      @apply border-b py-5 pr-6 border-border-default;

      &-left {
        @apply flex items-center justify-evenly;
      }

      &-right {
        @apply flex justify-center items-center;
      }

      &-item {
        @apply ml-8;
      }

      &-item.active {
        @apply text-primary;
      }
    }
  }

  .right {
    @apply w-1/2;
    @apply flex flex-row justify-center items-center;
    @apply overflow-auto;
  }
}
</style>
