import {
  LoadEventCoverResponse,
  LoadPublicEventByIdResponse,
  PublicEventUpdate,
} from "./types";
import {computed, reactive, ref, watch} from "vue";
import { useQuery } from "@vue/apollo-composable";
import { eventForm_loadEventCover, loadPublicEventById } from "./queries";
import { PublicEventCreate } from "../Create/types";

export const convertEvent = <T extends PublicEventCreate | PublicEventUpdate>(
  eventResponse: LoadPublicEventByIdResponse
) => {
  const eventUpdate = {
    ...({} as T),
    ...eventResponse.Event_by_pk,
  } as T;
  eventUpdate.startTime = new Date(eventUpdate.startTime);
  eventUpdate.endTime = new Date(eventUpdate.endTime);
  if (eventUpdate.SecondaryTags) {
    eventUpdate.secondaryTags = eventUpdate.SecondaryTags.map(
      (element) => element.SecondaryTag
    ).map(({ __typename, ...secondaryTag }) => secondaryTag);
    delete eventUpdate.SecondaryTags;
    delete eventUpdate.__typename;
  }
  return eventUpdate;
};

export const useLoadEventById = <
  T extends PublicEventCreate | PublicEventUpdate
>(
  eventId: string,
  emptyStartAndEndTime?: boolean
) => {
  const loadEventCover = ref(false);

  const { loading, result, onResult } = useQuery<LoadPublicEventByIdResponse>(
    loadPublicEventById,
    { eventId }
  );
  const mappedEventResponse = computed(() => result.value && convertEvent<T>(result.value))

  const resetEvent = (publicEvent: T) => {
    if (emptyStartAndEndTime) {
      publicEvent.startTime = "";
      publicEvent.endTime = "";
    }
    Object.assign(event, publicEvent);
    Object.assign(initialEvent, publicEvent);
  };

  watch(mappedEventResponse, async (event) => {
    if (event) {
      resetEvent({ ...event });
    }
  });

  const event = reactive<T>({} as T);
  const initialEvent = {} as T;

  onResult(() => {
    loadEventCover.value = true;
  });

  if (mappedEventResponse.value) {
    resetEvent({ ...mappedEventResponse.value });
    loadEventCover.value = true;
  }

  // Load Event Banner
  // TODO Error Handling
  const { result: eventBannerResult, onResult: onEventBannerResult } =
    useQuery<LoadEventCoverResponse>(
      eventForm_loadEventCover,
      () => ({ eventId: eventId }),
      () => ({
        fetchPolicy: "cache-and-network",
        enabled: loadEventCover.value,
      })
    );

  if (eventBannerResult.value) {
    event.cover = eventBannerResult.value?.getPublicEventCoverUrl.url;
    initialEvent.cover = eventBannerResult.value?.getPublicEventCoverUrl.url;
    loadEventCover.value = false;
  }

  onEventBannerResult((result) => {
    if (!result.loading) {
      event.cover = result.data.getPublicEventCoverUrl.url;
      initialEvent.cover = result.data.getPublicEventCoverUrl.url;
      loadEventCover.value = false;
    }
  });

  return { event, initialEvent, loading };
};
