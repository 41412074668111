import gql from "graphql-tag";

export const eventSeriesForm_processImage = gql`
  mutation frontend_createEventSeries_processImage(
    $data: String!
    $type: ImageType!
  ) {
    processImage(image: { data: $data, type: $type }) {
      data
    }
  }
`;

export const eventSeriesForm_create = gql`
  mutation frontend_createEventSeries_create(
    $name: String!
    $organizerId: uuid!
    $description: String!
    $logo: String!
    $banner: String!
    $facebookLink: String
    $websiteLink: String
    $instagramLink: String
    $images: [GalleryImageInput!]
  ) {
    createEventSeries(
      eventSeries: {
        name: $name
        organizerId: $organizerId
        description: $description
        facebookLink: $facebookLink
        websiteLink: $websiteLink
        instagramLink: $instagramLink
        logo: $logo
        banner: $banner
        images: $images
      }
    ) {
      id
      organizerId
      name
      description
      instagramLink
      facebookLink
      websiteLink
      bannerId
      logoId
    }
  }
`;

export const eventSeriesForm_update = gql`
  mutation frontend_createEventSeries_update(
    $id: uuid!
    $name: String
    $description: String
    $logo: String
    $banner: String
    $facebookLink: String
    $websiteLink: String
    $instagramLink: String
    $images: [GalleryImageInput!]
  ) {
    updateEventSeries(
      _set: {
        name: $name
        description: $description
        facebookLink: $facebookLink
        websiteLink: $websiteLink
        instagramLink: $instagramLink
        logo: $logo
        banner: $banner
        images: $images
      }
      where: { id: $id }
    ) {
      id
      organizerId
      name
      description
      instagramLink
      facebookLink
      websiteLink
      bannerId
      logoId
      GalleryImages {
        imageId
      }
    }
  }
`;

export const eventSeriesForm_loadEventSeriesInitial = gql`
  query frontend_eventSeriesForm_loadEventSeriesInitial($id: uuid!) {
    EventSeries_by_pk(id: $id) {
      id
      name
      description
      facebookLink
      instagramLink
      websiteLink
      logoId
      bannerId
      GalleryImages {
        imageId
      }
    }
  }
`;

export const eventSeriesForm_loadEventSeriesImageUrls = gql`
  query frontend_eventSeriesForm_getEventSeriesImageUrls(
    $eventSeriesId: uuid!
    $imageId1: uuid!
    $imageId2: uuid!
    $imageId3: uuid!
  ) {
    image1: getEventSeriesImageUrl(
      data: { imageId: $imageId1, eventSeriesId: $eventSeriesId }
    ) {
      url
    }
    image2: getEventSeriesImageUrl(
      data: { imageId: $imageId2, eventSeriesId: $eventSeriesId }
    ) {
      url
    }
    image3: getEventSeriesImageUrl(
      data: { imageId: $imageId3, eventSeriesId: $eventSeriesId }
    ) {
      url
    }
  }
`;

export const eventSeriesForm_loadEventSeriesLogo = gql`
  query frontend_eventSeriesForm_loadEventSeriesLogo($eventSeriesId: uuid!) {
    loadLogo(logo: { eventSeriesId: $eventSeriesId }) {
      url
    }
  }
`;

export const eventSeriesForm_loadEventSeriesBanner = gql`
  query frontend_eventSeriesForm_getEventSeriesBannerUrl(
    $eventSeriesId: uuid!
  ) {
    getEventSeriesBannerUrl(eventSeriesId: $eventSeriesId) {
      url
    }
  }
`;
