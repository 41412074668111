import gql from "graphql-tag";

export const secondaryTag_autocomplete_getSuggestions = gql`
  query search($search: String!) {
    search_secondary_names(args: { search: $search }, limit: 6) {
      id
      name
    }
  }
`;
