<template lang="pug">
UiAutoComplete(
  v-model="input",
  :loading="loading || parentElementIsLoading",
  :style-ids="styleIds",
  :placeholder="placeholder",
  :show-suggestions="showSuggestions",
  :disabled="disabled"
  @focusin="loadSuggestions",
  @focusout="showSuggestions = false",
)
  .suggestion-item(
    v-for="(suggestion, i) in suggestions",
    :key="i"
    @mousedown="onSecondaryTagSelected(suggestion)"
  ) {{ suggestion.name }}
</template>

<script lang="ts">
import {computed, defineComponent, ref, toRef} from "vue";
import UiAutoComplete from "../../../ui/AutoComplete/UiAutoComplete.vue";
import {
  STYLE_IDS_PROP_DEF,
  useStyleIdClasses,
} from "../../../ui/utils/style-ids";
import { useQuery } from "@vue/apollo-composable";
import { secondaryTag_autocomplete_getSuggestions } from "./queries";
import {
  SecondaryTag,
} from "./types";

export default defineComponent({
  name: "SecondaryTagAutoComplete",
  components: { UiAutoComplete },
  props: {
    styleIds: STYLE_IDS_PROP_DEF,
    disabled: { type: Boolean, required: false, default: false },
    placeholder: { type: String, required: false, default: "" },
    parentElementIsLoading: { type: Boolean, default: false },
  },
  emits: ["onSelect"],
  setup(props) {
    let showSuggestions = ref(false);
    let variables = ref({
      search: "",
    });
    let options = ref({
      enabled: false,
    });

    const { loading, result } = useQuery<{ search_secondary_names: { id: string; name: string; }[] }>(
      secondaryTag_autocomplete_getSuggestions,
      variables,
      options
    );

    const loadSuggestions = () => {
      if (variables.value.search.length >= 3) {
        showSuggestions.value = true;
        options.value.enabled = true;
      } else {
        options.value.enabled = false;
        showSuggestions.value = false;
      }
    };

    const onInput = (value: string) => {
      variables.value.search = value;
      loadSuggestions();
    };

    return {
      styleIdClasses: useStyleIdClasses(toRef(props, "styleIds")),
      loadSuggestions,
      suggestions: computed(() => result.value?.search_secondary_names.map((secondaryTag) => ({
        id: secondaryTag.id,
        name: secondaryTag.name,
      }))),
      showSuggestions,
      loading,
      onInput,
    };
  },
  data() {
    return {
      input: "",
    };
  },
  watch: {
    input() {
      this.onInput(this.input);
    },
  },
  methods: {
    onSecondaryTagSelected(secondaryTag: SecondaryTag) {
      this.$emit("onSelect", secondaryTag);
      this.input = "";
    },
  },
});
</script>

<style scoped>
.suggestion-item {
  @apply px-4 py-1 relative w-full bg-background-contrast z-50;

  &:hover {
    background-color: #242424;
  }

  &:last-child {
    @apply rounded-b-lg pb-1;
  }
}
</style>
