import gql from "graphql-tag";

export const organizer_create = gql`
  mutation frontend_createOrganizer_create(
    $name: String!
    $publicName: String!
    $eMail: String!
    $contact: String
    $postalCode: String!
    $city: String!
    $streetName: String!
    $streetNumber: String!
    $supervisorId: uuid
    $selfManaged: Boolean!
  ) {
    insert_Organizer(
      objects: {
        name: $name
        contact: $contact
        publicName: $publicName
        eMail: $eMail
        postalCode: $postalCode
        city: $city
        streetName: $streetName
        streetNumber: $streetNumber
        supervisorId: $supervisorId
        selfManaged: $selfManaged
      }
    ) {
      returning {
        id
      }
    }
  }
`;
