import { getAuth, connectAuthEmulator } from "firebase/auth";
import { initializeApp } from "firebase/app";
import configuration from "./configuration";
import Logger from "../../../utils/logger";

export default class FirebaseFactory {
  private static auth: ReturnType<typeof getAuth>;
  private static initialized = false;
  private static authEmulatorDefaultUrl = "http://localhost:9099";

  private static initialize() {
    if (!this.initialized) {
      initializeApp(configuration);
    }
  }

  public static getAuth() {
    this.initialize();
    if (!this.auth) {
      this.auth = getAuth();
      this.configure("auth");
      return this.auth;
    }

    return this.auth;
  }

  private static configure(service: "auth") {
    switch (service) {
      case "auth": {
        if (import.meta.env.VITE_ENVIRONMENT === "development") {
          Logger.debug(
            "Running in development mode, connecting to auth emulator"
          );
          if (!import.meta.env.VITE_FIREBASE_EMULATOR_AUTH_URL) {
            Logger.warn(
              `VITE_FIREBASE_EMULATOR_AUTH_URL unset, using default url ${this.authEmulatorDefaultUrl}`
            );

            connectAuthEmulator(this.auth, this.authEmulatorDefaultUrl, {
              disableWarnings: false,
            });
          } else {
            connectAuthEmulator(
              this.auth,
              import.meta.env.VITE_FIREBASE_EMULATOR_AUTH_URL,
              {
                disableWarnings: false,
              }
            );
          }
        }
      }
    }
  }
}
