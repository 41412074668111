<template lang="pug">
AuthenticationHeader
  p.description {{ passwordResetEmailSuccessfullySent ? "If you have an account, you will receive an email in a few seconds. Follow the directions in the email to reset your password." : "Please enter the email you use to sign in to Pingr." }}
  form.input-wrapper(v-if="!passwordResetEmailSuccessfullySent")
    UiTextInput(
      v-model="sendPasswordResetEmailAccountEmail",
      placeholder="Email",
      style-ids="login"
    )
  .submit-area
    div
      UiButton(
        v-if="!passwordResetEmailSuccessfullySent",
        @click="sendPasswordResetEmail",
        :disabled="!emailIsValid",
        style-ids="primary login"
        type="button"
      ) Submit
      .back-button-wrapper
        a.back-button(
          v-if="!passwordResetEmailSuccessfullySent",
          @click="backToLogin"
        ) Back to Login
    div
      UiButton(
        v-if="passwordResetEmailSuccessfullySent",
        @click="backToLogin",
        style-ids="primary login"
      ) Done
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import UiTextInput from "../../../ui/TextInput/UiTextInput.vue";
import UiButton from "../../../ui/Button/UiButton.vue";
import AuthenticationHeader from "./AuthenticationHeader.vue";
import {useMutation} from "@vue/apollo-composable";
import { sendPasswordResetLinkToOrganizerAccountMail } from "./sendPasswordResetEmail.queries";

export default defineComponent({
  name: "SendPasswordResetEmailForm",
  components: { UiTextInput, UiButton, AuthenticationHeader },
  emits: ["backToLoginClicked"],
  setup() {
    const sendPasswordResetEmailAccountEmail = ref("");
    const passwordResetEmailSuccessfullySent = ref(false);

    const { mutate } = useMutation(
      sendPasswordResetLinkToOrganizerAccountMail,
    );

    const sendPasswordResetEmail = () => {
      mutate({
          organizerAccountMail: sendPasswordResetEmailAccountEmail.value,
      })
          .then(() => passwordResetEmailSuccessfullySent.value = true);
    };
    return {
      sendPasswordResetEmail,
      sendPasswordResetEmailAccountEmail,
      passwordResetEmailSuccessfullySent,
    };
  },
  computed: {
    emailIsValid() {
      const emailRegex = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

      return emailRegex.test(this.sendPasswordResetEmailAccountEmail);
    },
  },
  methods: {
    backToLogin() {
      this.$emit("backToLoginClicked");
    },
  },
});
</script>

<style scoped>
.description {
  @apply text-sm text-center text-text-subliminal;
  @apply mt-2.5;
  text-align: center;
  max-width: 60%;
}
.input-wrapper {
  @apply mt-10 w-full;
}

.submit-area {
  @apply flex-col mt-6 justify-center;
}

.back-button {
  @apply text-center text-sm cursor-pointer hover:underline;
}

.back-button-wrapper {
  @apply flex justify-center mt-2.5;
}
</style>
