<template lang="pug">
.flex.gap-x-10
  p Dieses Event wurde von folgender URL gescraped:
  a.select-text(:href="modelValue.url", target="_blank") {{ modelValue.url }}
p Modus: {{ loading ? "" : modelValue.old ? "Update" : "Create" }}
form.content(autocomplete="off")
  .section-header
    h1 EventSeries

  label.label EventSeries
  UiSelect(
    v-model="modelValue.eventSeriesId",
    name="EventSeries",
    placeholder="Auswählen",
    :loading="loading"
  )
    option(
      v-for="eventSeries in modelValue.Organizer.EventSeries",
      :key="eventSeries.id",
      :value="eventSeries.id"
    ) {{ eventSeries.name }}

  .section-header
    h1 Benenne dein Event
  label.label Eventname *
  UiTextInput(
    v-model="modelValue.title",
    style-ids="rounded-border hover-primary",
    :loading="loading",
    :skeleton="true"
  )

  label.label Hauptkategorie *
  PrimaryTagSelect(v-model="modelValue.primaryTagId", :rounded="true")

  label.label Genres
  SecondaryTagAutoComplete(
    style-ids="rounded-border hover-primary",
    @on-select="onSecondaryTagSelection"
  )
  .secondary-tag-wrapper
    .secondary-tags
      SecondaryTagVisualization(
        v-for="tag in modelValue.secondaryTags",
        :key="tag.id",
        :name="tag.name",
        @click="() => (modelValue.secondaryTags = modelValue.secondaryTags.filter((t) => t.id !== tag.id))"
      )

  .section-divider
  .section-header
    p Beschreibe dein Event

  label.label Beschreibung *
  UiTextInput(
    v-model="modelValue.description",
    style-ids="rounded-border hover-primary",
    tag="textarea",
    :validation-rules="{ required: true }",
    maxlength="5000",
    :max-length="5000",
    name="Beschreibung",
    :loading="loading",
    :skeleton="true"
  )

  .section-divider
  .section-header
    p Wo und wann findet dein Event statt?

  label.label Location *
  UiLocationAutoComplete(
    style-ids="rounded-border hover-primary",
    :validation-rules="{ required: true }",
    fetch-policy="establishment-first",
    name="Location",
    :loading="loading",
    :location-alias="modelValue.locationAlias",
    @on-select="onLocationSelection"
  )
    template(#icon)
      .location-picker
        img.location-picker-icon(
          src="../../../assets/icons/locationPicker.png",
          @click="showModal = !showModal"
        )

  label.label Start *
  UiDateTimePicker(
    v-model="modelValue.startTime",
    :validation-rules="{ required: true }",
    name="Startdatum",
    :loading="loading"
  )
  label.label Ende *
  UiDateTimePicker(
    v-model="modelValue.endTime",
    :validation-rules="{ required: true }",
    name="Enddatum",
    :min-datetime="minEndDate",
    :loading="loading"
  )
  .section-divider
  .section-header
    p Verkaufe Tickets für dein Event!
  label.label Ticket-Link
  UiTextInput(
    v-model="modelValue.ticketLink",
    style-ids="rounded-border hover-primary",
    name="Ticket-Link",
    :validation-rules="{ url: true }",
    :loading="loading",
    :skeleton="true"
  )
  .section-divider
  .section-header
    p Lade dein Design hoch!
  label.label Event Cover *
  UiDropZone(
    v-model="modelValue.cover",
    name="Event Cover",
    :validation-rules="{ required: true }",
    :limit="1",
    type="EVENT_COVER"
  )
.flex.flex-col.items-center.w-full
  UiButton.create-button(style-ids="primary", @click="$emit('onSubmit')") {{ modelValue.old ? "Update" : "Create" }}
UiLocationPickerModal(
  :show-modal="showModal",
  @location-picked="showModal = false"
)
</template>

<script setup lang="ts">
import { computed, ref, toRefs } from "vue";
import UiTextInput from "../../../ui/TextInput/UiTextInput.vue";
import PrimaryTagSelect from "../../PrimaryTag/Select/PrimaryTagSelect.vue";
import SecondaryTagAutoComplete from "../../SecondaryTag/AutoComplete/Autocomplete.vue";
import UiLocationAutoComplete from "../../../ui/LocationAutoComplete/UiLocationAutoComplete.vue";
import UiDropZone from "../../../ui/DropZone/UiDropZone.vue";
import UiButton from "../../../ui/Button/UiButton.vue";
import UiLocationPickerModal from "../../../ui/LocationPickerModal/UiLocationPickerModal.vue";
import UiDateTimePicker from "../../../ui/DateTimePicker/UiDateTimePicker.vue";
import SecondaryTagVisualization from "../../SecondaryTag/Visualization/SecondaryTagVisualization.vue";
import { addOffsetToDate } from "../../../utils/dateFormatter";
import UiSelect from "../../../ui/Select/UiSelect.vue";

const props = withDefaults(
  defineProps<{
    modelValue: {
      old?: any;
      ticketLink?: string;
      cover?: string;
      startTime: string;
      endTime?: string;
      url: string;
      eventSeriesId: string;
      Organizer: {
        EventSeries: {
          id: string;
          name: string;
        }[];
      };
      description: string;
      title: string;
      primaryTagId: string;
      location: number[];
      locationAlias: string;
      secondaryTags: { id: string; name: string }[];
    };
    disabled?: boolean;
    loading: boolean;
  }>(),
  {
    disabled: true,
  },
);

defineEmits(["onSubmit", "update:modelValue", "onCancelEvent"]);

const { modelValue } = toRefs(props);

const showModal = ref(false);

///////////////// state /////////////////
const secondaryTagErrorMessage = ref("");

//////////////// computed ///////////////
const minEndDate = computed(() =>
  addOffsetToDate(
    modelValue.value.startTime,
    Number(import.meta.env.VITE_DEFAULT_EVENT_DURATION),
  ),
);
/////////////////// methods ///////////////////
const onSecondaryTagSelection = (tag: { id: string; name: string }) => {
  if (
    !modelValue.value.secondaryTags.some(
      (secondaryTag) => secondaryTag.id === tag.id,
    )
  ) {
    modelValue.value.secondaryTags.push(tag);
  } else {
    secondaryTagErrorMessage.value = `${tag.name} has already been taken...`;
    setTimeout(() => {
      secondaryTagErrorMessage.value = "";
    }, 2500);
  }
};

// TODO: improve typing over here
const onLocationSelection = (some: {
  addressComponents: {
    city: string;
    postalCode: string;
    streetName: string;
    streetNumber: string;
  };
  location: {
    coordinates: number[];
    type: "Point";
  };
  locationAlias: string;
}) => {
  modelValue.value.location = some.location.coordinates;
  modelValue.value.locationAlias = some.locationAlias;
};
</script>

<style lang="postcss" scoped>
.content {
  @apply grid grid-flow-row;
  @apply p-8;
  @apply gap-x-24 gap-y-4;
  grid-template-columns: 3fr 8fr;

  & > * {
    @apply self-center;
  }

  .section-header {
    @apply text-xl font-bold;
    grid-area: auto / 1 / auto / span 2;
  }
}

label {
  @apply text-sm;
}

.section-divider {
  @apply h-0.5 bg-background-contrast;
  grid-area: auto / 1 / auto / span 2;
}

.secondary-tag-wrapper {
  @apply flex flex-col items-start flex-wrap;
  grid-area: auto / 2 / auto / 2;

  .error-message {
    @apply text-background-highlight;
  }
  .secondary-tags {
    @apply flex flex-wrap;
  }
}

.create-button {
  @apply w-11/12;
}
.cancel-button {
  @apply text-background-highlight underline;
}

.organizer-delete-modal:deep .modal {
  max-width: 50%;
}

.modal-cancel {
  :deep .modal {
    max-width: 50%;
  }
  &-button {
    @apply mr-4;
  }
  &-title {
    @apply text-xl font-extrabold;
  }
  &-content {
    &-text {
      @apply select-text mb-3;
    }
    &-input {
      @apply w-8/12;
    }
  }
  &-footer {
    @apply flex w-full justify-start;
  }
}
.location-picker {
  width: 16px;
  height: 20px;
  &-icon {
    @apply w-full h-full cursor-pointer;
  }
}
</style>
