<template lang="pug">
main.flex.h-full
  section.sec-image
  section.sec-form
    LoginForm(
      v-if="!resetPasswordMode && !sendPasswordResetEmailMode",
      @forgot-password-clicked="forgotPassword"
    )
    SendPasswordResetEmailForm(
      v-if="sendPasswordResetEmailMode",
      @back-to-login-clicked="backToLogin"
    )
    ResetPasswordForm(
      v-if="resetPasswordMode",
      @request-link-clicked="forgotPassword",
      @back-to-login="backToLogin"
    )
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import {
  getAuth,
  browserSessionPersistence,
  browserLocalPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useForm } from "vee-validate";
import { useRoute } from "vue-router";
import LoginForm from "./LoginForm.vue";
import SendPasswordResetEmailForm from "./SendPasswordResetEmailForm.vue";
import ResetPasswordForm from "./ResetPasswordForm.vue";

export default defineComponent({
  name: "LoginPage",
  components: {
    LoginForm,
    SendPasswordResetEmailForm,
    ResetPasswordForm,
  },
  setup() {
    const email = ref("");
    const password = ref("");
    const rememberLogin = ref(false);
    const loginErrorMessage = ref("");
    const resetPasswordMode = ref(false);

    const { handleSubmit } = useForm();

    const onSubmit = handleSubmit(async () => {
      await getAuth().setPersistence(
        rememberLogin.value
          ? browserLocalPersistence
          : browserSessionPersistence
      );
      try {
        await signInWithEmailAndPassword(
          getAuth(),
          email.value,
          password.value
        );
      } catch (e) {
        loginErrorMessage.value = "Incorrect username or password";
        throw e;
      }
    });

    if (useRoute().query.mode === "resetPassword") {
      resetPasswordMode.value = true;
    }

    return {
      onSubmit,
      email,
      password,
      loginErrorMessage,
      rememberLogin,
      resetPasswordMode,
    };
  },
  data() {
    return {
      sendPasswordResetEmailMode: false,
    };
  },
  methods: {
    forgotPassword() {
      this.sendPasswordResetEmailMode = true;
      this.resetPasswordMode = false;
    },

    backToLogin() {
      this.sendPasswordResetEmailMode = false;
      this.resetPasswordMode = false;
    },
  },
});
</script>

<style scoped>
.sec-image {
  flex: 0 1 50%;
  background: url("../../../assets/images/login-image.webp");
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
}

.sec-form {
  @apply flex justify-center items-center;

  flex: 1 1 50%;

  & > label {
    @apply text-3xl;
  }
}
</style>
