import { addHours, format } from "date-fns";
import { de, enGB } from "date-fns/locale";

/**
 * Formats the date to a localized human-readable format
 * @param date
 * @param formatString
 */
export const formatDate = (date: string, formatString?: string) => {
  // TODO Assign dynamically
  const languageTag = "de";
  try {
    const dateObject = new Date(date);
    const dateFormat = formatString || "dd.MM.yyyy HH:mm";
    switch (languageTag) {
      case "de": {
        return format(dateObject, dateFormat, {
          locale: de,
        });
      }
      default: {
        return format(dateObject, dateFormat, {
          locale: enGB,
        });
      }
    }
  } catch (e) {
    return "dateParseError";
  }
};

export const addOffsetToDate = (date: string, offset: number): Date => {
  return addHours(new Date(date), offset);
};

export const getStartOfYear = (): Date => {
  // https://stackoverflow.com/questions/2552483/why-does-the-month-argument-range-from-0-to-11-in-javascripts-date-constructor
  return new Date(new Date().getFullYear(), 0, 1);
};

export const getStartOfMonth = (): Date => {
  return new Date(new Date().getFullYear(), new Date().getMonth(), 1);
};
