import { App } from "vue";
import { setContext } from "@apollo/client/link/context";
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client/core";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { getAuth } from "firebase/auth";
import parseJwt from "../../authentication/utils/parseJWT";
import { HmacSHA256, enc } from "crypto-js";
import { base64Encode } from "../../../utils/base64";

const getToken = (): Promise<string> => {
  const user = getAuth().currentUser;
  if (user) {
    return user.getIdToken();
  }
  // return Promise.reject("No user logged in");
  return Promise.resolve("");
};

const authMiddleware = setContext(async (_, context) => {
  let token = await getToken();
  const headers: { [key:string]: string } = context.headers ?? {};

  // sign the token with the development secret
  // ATTENTION: ONLY RUN THAT CODE IN DEV ENVIRONMENT
  if (token && import.meta.env.VITE_ENVIRONMENT === "development") {
    const secret = "zk59p56eud5f535v5k5sr7yu32autqmn8mtjucfo";
    const decoded = parseJwt(token);
    const header = {
      alg: "HS256",
      typ: "JWT",
    };

    const headerString = JSON.stringify(header);
    const payloadString = JSON.stringify(decoded);

    const base64Header = base64Encode(headerString, true);
    const base64Body = base64Encode(payloadString, true);

    let signedToken = `${base64Header}.${base64Body}`;

    const hash = HmacSHA256(signedToken, secret);

    signedToken += `.${hash.toString(enc.Base64url)}`;

    token = signedToken;
    headers.Authorization = `Bearer ${token}`;
  } else if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return {
    ...context,
    headers,
  };
});

// TODO: implement for progress
/*const axiosClient = axios.create({
  onDownloadProgress: (value) => {
    // eventBus.$emit('downloadProgress', value);
  },
  onUploadProgress: (value) => {
    // eventBus.$emit('uploadProgress', value);
  },
});*/

const httpLink = createHttpLink({
  uri: `${import.meta.env.VITE_HASURA_API_URL}/v1/graphql`,
  credentials: "include",
  // fetch: buildAxiosFetch(axiosClient),
});

const remapTypenameLink = new ApolloLink((operation, forward) => {
  // https://www.apollographql.com/docs/react/api/link/introduction/#handling-a-response
  return forward(operation).map((data) => {
    if (data.data) {
      const body = data.data;
      if (body && body[Object.keys(body)[0]]) {
        // Add types that need to be remapped here
        if (
          body[Object.keys(body)[0]].__typename === "CreateEventSeriesOutput"
        ) {
          body[Object.keys(body)[0]].__typename = "EventSeries";
        }
        if (
          body[Object.keys(body)[0]].__typename === "UpdateEventSeriesOutput"
        ) {
          body[Object.keys(body)[0]].__typename = "EventSeries";
        }
        if (
          body[Object.keys(body)[0]].__typename === "CreatePublicEventOutput"
        ) {
          body[Object.keys(body)[0]].__typename = "Event";
        }
        if (
          body[Object.keys(body)[0]].__typename === "UpdatePublicEventOutput"
        ) {
          body[Object.keys(body)[0]].__typename = "Event";
        }
        if (
          body[Object.keys(body)[0]].__typename === "CancelPublicEventOutput"
        ) {
          body[Object.keys(body)[0]].__typename = "Event";
        }
      }
    }
    return data;
  });
});

const apolloClient = new ApolloClient({
  link: from([remapTypenameLink, authMiddleware, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      // necessary as apollo usually generates the cache IDs by using the typename and the ID
      // in this particular case the ID of the translation is just the ID of the secondaryTag
      // so we additionally need the language field in the cache ID so there are no duplicates (= overwrites)
      SecondaryTagTranslation: {
        keyFields: ["id", "language"],
      },
      // https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
      Query: {
        fields: {
          AdminUser: {
            merge(existing, incoming) {
              return { ...existing, ...incoming };
            },
          },
          // https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
          AdminUserAdminRole: {
            merge(existing, incoming) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
    },
  }),
});

export default {
  install(app: App) {
    //// Vue Apollo: Composition API ////
    app.provide(DefaultApolloClient, apolloClient);
    // app.provide(provideApolloClient(apolloClient));
  },
};

export { apolloClient as client };
