import gql from "graphql-tag";

export const firebaseAuthCallback_fetchAdmin = gql`
  query frontend_firebaseAuthCallback_fetchAdmin($id: uuid!) {
    AdminUser_by_pk(id: $id) {
      id
      firstName
      lastName
      email
      firebaseId
      createdAt
      updatedAt
      Role {
        role
      }
    }
  }
`;
