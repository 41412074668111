import { ActionContext } from "vuex";
import { User } from "firebase/auth";
import { EventSeries, EventSeriesDataResult, StoreOrganizer } from "../types";
import { authenticatedAreaFetchEventSeries } from "./queries";
import { provideApolloClient } from "@vue/apollo-composable";
import { client } from "../../api/graphql/apollo";

export interface AppState {
  user: {
    id: string;
    loggedIn: boolean;
    data: User | null;
    adminManager: boolean;
    eventManager: boolean;
    tagManager: boolean;
    organizerManager: boolean;
    scraperManager: boolean;
  };
  organizer: StoreOrganizer | null;
  navigation: {
    activeNavigationItem: string;
    initialLoading: boolean;
  };
  eventSeries: {
    loading: boolean;
    eventSeriesList: EventSeries[];
    error: string | null;
  };
}

const initialState: AppState = {
  user: {
    id: "",
    loggedIn: false,
    data: null,
    adminManager: false,
    eventManager: false,
    tagManager: false,
    organizerManager: false,
    scraperManager: false,
  },
  organizer: null,
  navigation: {
    activeNavigationItem: "dashboard",
    initialLoading: true,
  },
  eventSeries: {
    loading: false,
    eventSeriesList: [],
    error: null,
  },
};

// TODO: getters are not typed correctly right now
const getters = {
  user(state: AppState) {
    return state.user;
  },
  isAdminManager(state: AppState) {
    return state.user.adminManager;
  },
  isEventManager(state: AppState) {
    return state.user.eventManager;
  },
  isScraperManager(state: AppState) {
    return state.user.scraperManager;
  },
  isTagManager(state: AppState) {
    return state.user.tagManager;
  },
  isOrganizerManager(state: AppState) {
    return state.user.organizerManager;
  },
  activeNavigationItem(state: AppState) {
    return state.navigation.activeNavigationItem;
  },
  eventSeriesList(state: AppState) {
    return state.eventSeries.eventSeriesList;
  },
  organizer(state: AppState) {
    return state.organizer;
  },
  initialLoading(state: AppState) {
    return state.navigation.initialLoading;
  },
};

/**
 * Users data mutations
 */
export const mutations = {
  LOGIN(state: AppState, value: boolean) {
    state.user.loggedIn = value;
  },
  LOGOUT(state: AppState) {
    state = initialState;
  },
  SET_USER(state: AppState, data: User) {
    state.user.data = data;
  },
  SET_IS_EVENTMANAGER(state: AppState, value: boolean) {
    state.user.eventManager = value;
  },
  SET_IS_TAGMANAGER(state: AppState, value: boolean) {
    state.user.tagManager = value;
  },
  SET_IS_SCRAPERMANAGER(state: AppState, value: boolean) {
    state.user.scraperManager = value;
  },
  SET_IS_ORGANIZERMANAGER(state: AppState, value: boolean) {
    state.user.organizerManager = value;
  },
  SET_IS_ADMINMANAGER(state: AppState, value: boolean) {
    state.user.adminManager = value;
  },
  SET_ORGANIZER(state: AppState, organizer: StoreOrganizer | null) {
    state.organizer = organizer;
  },
  SET_ACTIVE_NAVIGATION_ITEM(state: AppState, activeNavigationItem: string) {
    state.navigation.activeNavigationItem = activeNavigationItem;
  },
  SET_USER_ID(state: AppState, userId: string) {
    state.user.id = userId;
  },
  EVENT_SERIES_DATA_FETCHING(state: AppState) {
    Object.assign(state.eventSeries, { loading: true, error: null });
  },
  EVENT_SERIES_DATA_SUCCESS(state: AppState, payload: EventSeries[]) {
    Object.assign(state.eventSeries, {
      loading: false,
      eventSeriesList: payload,
    });
  },
  EVENT_SERIES_DATA_ERROR(state: AppState, payload: string) {
    Object.assign(state.eventSeries, {
      loading: false,
      eventSeriesList: [],
      error: payload,
    });
  },
  SET_LOADING_DONE(state: AppState) {
    state.navigation.initialLoading = false;
  },
};

/**
 * Users data actions
 */
export const actions = {
  setUser(context: ActionContext<any, any>, user: User | null) {
    context.commit("LOGIN", user !== null);
    if (user) {
      context.commit("SET_USER", {
        displayName: user.displayName,
        email: user.email,
      });
    } else {
      context.commit("SET_USER", null);
    }
  },
  logout(context: ActionContext<any, any>) {
    context.commit("LOGOUT");
  },
  setIsEventManager(context: ActionContext<any, any>, value: boolean) {
    context.commit("SET_IS_EVENTMANAGER", value);
  },
  setIsTagManager(context: ActionContext<any, any>, value: boolean) {
    context.commit("SET_IS_TAGMANAGER", value);
  },
  setIsScraperManager(context: ActionContext<any, any>, value: boolean) {
    context.commit("SET_IS_SCRAPERMANAGER", value);
  },
  setIsOrganizerManager(context: ActionContext<any, any>, value: boolean) {
    context.commit("SET_IS_ORGANIZERMANAGER", value);
  },
  setIsAdminManager(context: ActionContext<any, any>, value: boolean) {
    context.commit("SET_IS_ADMINMANAGER", value);
  },
  setUserId(context: ActionContext<any, any>, value: string) {
    context.commit("SET_USER_ID", value);
  },
  async setOrganizer(
    context: ActionContext<any, any>,
    organizer: { id: string }
  ) {
    context.commit("SET_ORGANIZER", organizer);
    // await context.dispatch("fetchEventSeriesData", organizer.id);
  },
  setInitialLoadingDone(context: ActionContext<any, any>) {
    context.commit("SET_LOADING_DONE");
  },
  setActiveNavigationItem(
    context: ActionContext<any, any>,
    selectedNavigationItem: string
  ) {
    context.commit("SET_ACTIVE_NAVIGATION_ITEM", selectedNavigationItem);
  },
  /** fetch user data */
  // TODO: do we still need that?
  async fetchEventSeriesData(
    context: ActionContext<any, any>,
    organizerId: string
  ) {
    context.commit("EVENT_SERIES_DATA_FETCHING");

    const result = await provideApolloClient(client)(() =>
      client.query<EventSeriesDataResult>({
        query: authenticatedAreaFetchEventSeries,
        variables: {
          id: organizerId,
        },
      })
    );

    if (result.error) {
      context.commit("EVENT_SERIES_DATA_ERROR", result.error);
    } else if (result.data) {
      context.commit(
        "EVENT_SERIES_DATA_SUCCESS",
        result.data.Organizer_by_pk.EventSeries
      );
    }
  },
};

export default {
  mutations,
  getters,
  actions,
  state: initialState,
};
