<template lang="pug">
.icon-wrapper
  img.icon(alt="Primary Tag Icon", :src="icon")
</template>

<script>
// TODO: do we even need that component?
import { defineComponent } from "vue";
import primaryTagIcons from "./primaryTagIcons";

export default defineComponent({
  name: "PrimaryTagIcon",
  props: {
    id: { type: String, require: true, default: "" },
  },
  computed: {
    icon() {
      return primaryTagIcons[this.id];
    },
  },
});
</script>

<style scoped>
.icon {
  @apply w-full h-full;
}

.icon-wrapper {
  height: 36px;
  width: 36px;
  border-radius: 18px;
}
</style>
