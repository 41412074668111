<template lang="pug">
.image-slider
  .placeholder(v-if="galleryImages.length === 0")
    p Event Series Images Placeholder
  button.navigator.left(
    v-if="currentIndex > 0",
    @click="scroll(--currentIndex)"
  )
    p &lt;
  .images(ref="slider")
    .image(v-for="(galleryImage, index) of galleryImages", :key="index")
      img(v-if="galleryImage.imageUri", :src="galleryImage.imageUri")
      LoadingAnimation.loading-animation(
        v-if="!galleryImage.imageUri",
        :is-primary="true"
      )
  button.navigator.right(
    v-if="currentIndex + 1 < galleryImages.length",
    @click="scroll(++currentIndex)"
  )
    p &gt;
  .slider-nav
    button.divider(
      v-for="(_, index) in galleryImages",
      :key="index",
      :class="index === currentIndex ? 'active' : 'inactive'",
      @click="scroll(index)"
    )
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import LoadingAnimation from "../../../ui/LoadingAnimation/LoadingAnimation.vue";

export default defineComponent({
  name: "EventSeriesImageSlider",
  components: { LoadingAnimation },
  props: {
    galleryImages: {
      type: Array as PropType<{ imageId?: string; imageUri?: string }[]>,
      required: true,
    },
  },
  data: () => ({
    currentIndex: 0,
  }),
  methods: {
    scroll(to: number) {
      this.currentIndex = to;
      const slider: HTMLDivElement = this.$refs.slider as HTMLDivElement;

      slider.scroll({
        left: (slider.scrollWidth / 3) * to,
        behavior: "smooth",
      });
    },
  },
});
</script>

<style scoped>
.image-slider {
  @apply flex flex-col items-center justify-center;
  @apply max-h-80 relative;

  .placeholder {
    @apply h-48 w-full;
    @apply text-text-default font-bold text-lg;
    @apply flex justify-center items-center;
    background-color: #272727;
  }

  .images {
    @apply flex flex-row items-center justify-start;
    @apply overflow-hidden;

    width: 100%;
    scroll-snap-type: x proximity;

    .image {
      @apply flex-shrink-0;
      scroll-snap-align: center;
      width: 100%;

      .loading-placeholder {
        @apply flex justify-center items-center;
        @apply h-60 w-full;
      }

      &:hover {
        .navigator {
          @apply opacity-80;
        }
      }
    }
  }

  .navigator {
    @apply absolute p-3 h-10 w-10;
    @apply bg-background-default text-background-bright;
    @apply rounded-3xl;
    @apply flex justify-center items-center;
    @apply opacity-80;

    &.left {
      @apply left-1 hidden;
    }
    &.right {
      @apply right-1 hidden;
    }
  }

  .banner {
    @apply flex justify-center items-center;
    @apply bg-background-bright;
    @apply font-bold text-lg;
    @apply w-full h-60;

    & > p {
      @apply text-text-dark;
    }
  }

  .slider-nav {
    @apply flex justify-center items-center;
    @apply mt-5 mb-10;

    .divider {
      @apply h-1 w-7 bg-background-subliminal mx-1 rounded;
    }

    .divider.active {
      @apply bg-primary;
    }
  }
}
</style>
