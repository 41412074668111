<template lang="pug">
.flex.flex-col.items-center.justify-center
  img.h-12.w-12.justify-self-center(
    alt="Pingr Logo",
    src="../../../assets/logos/logo.png"
  )
  p.text-2xl.font-bold.tracking-widest(class="mt-2.5") PINGR
  p.text-sm.font-bold.tracking-wider(class="mt-1.5") FOR EVENTS
  p.text-sm.text-center.text-gray-400(class="mt-2.5", style="display: contents")
    slot
</template>

<script>
export default {
  name: "AuthenticationHeader",
};
</script>

<style scoped></style>
