<template lang="pug">
div
  img.avatar.roundedAvatar(v-show="!loading", :src="logoUri", alt="logo")
  LoadingAnimation.avatar(
    :is-checked="false",
    :is-primary="true",
    v-if="loading"
  )
</template>

<script lang="ts">
import {computed, defineComponent, toRefs} from "vue";
import { useQuery } from "@vue/apollo-composable";
import LoadingAnimation from "../../../ui/LoadingAnimation/LoadingAnimation.vue";
import { EventSeriesLogo_Result } from "./types";
import { eventSeriesLogo_getLogoUri } from "./queries";

export default defineComponent({
  name: "EventSeriesLogo",
  components: { LoadingAnimation },
  props: {
    eventSeriesId: { type: String, required: true },
  },
  setup(props) {
    const { eventSeriesId } = toRefs(props);

    const { result, loading, error } = useQuery<EventSeriesLogo_Result>(
      eventSeriesLogo_getLogoUri,
      {
        id: eventSeriesId,
      }
    );
    return {
      logoUri: computed(() => result.value?.loadLogo?.url),
      loading,
      error,
    };
  },
});
</script>

<style lang="postcss" scoped>
.avatar {
  @apply w-9 h-9;
}

.roundedAvatar {
  @apply rounded-3xl object-cover;
}
</style>
