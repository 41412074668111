import gql from "graphql-tag";

export const loadAllPrimaryTags = gql`
  query frontend_primaryTags_loadAllPrimaryTags {
    PrimaryTag {
      id
      name
    }
  }
`;
