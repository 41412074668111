<template lang="pug">
.rootClasses
  div(
    ref="trigger",
    :class="triggerClasses",
    aria-haspopup="true"
    @click.stop="onClick",
  )
    slot(name="trigger", :active="isActive")
  transition(name="fade")
    div(
      v-show="isActive",
      ref="contextMenu",
      :class="menuClasses",
      :aria-hidden="!isActive"
    )
      slot
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "UiContextMenu",
  configField: "uiContextMenu",
  provide() {
    return {
      uiContextMenu: this,
    };
  },
  emits: ["update:modelValue", "active-change", "change"],
  data() {
    return {
      isActive: false,
      bodyEl: {} as HTMLDivElement, // Used to append to body
    };
  },
  computed: {
    rootClasses() {
      return ["ui-context"];
    },
    triggerClasses() {
      return ["ui-context__trigger"];
    },
    menuClasses() {
      return ["ui-context__menu"];
    },
  },
  watch: {
    isActive(value) {
      this.$emit("active-change", value);
      this.$nextTick(() => {
        this.updateAppendToBody();
      });
    },
  },
  mounted() {
    this.$data.bodyEl = this.createAbsoluteElement(
      this.$refs.contextMenu as Element
    );
    this.updateAppendToBody();
    window.addEventListener("click", this.handleWindowClick, true);
    window.addEventListener("wheel", this.handleWheel, true);
  },
  beforeUnmount() {
    window.removeEventListener("click", this.handleWindowClick, true);
    window.removeEventListener("wheel", this.handleWheel, true);
  },
  methods: {
    handleWheel() {
      this.isActive = false;
    },
    handleWindowClick(event: MouseEvent) {
      if (event.target !== this.$refs.button) {
        this.isActive = false;
      }
    },
    onClick() {
      this.toggle();
    },
    /**
     * Toggle dropdown if it's not disabled.
     */
    toggle() {
      if (!this.isActive) {
        // if not active, toggle after clickOutside event
        // this fixes toggling programmatic
        this.$nextTick(() => {
          const value = !this.isActive;
          this.isActive = value;
          // Vue 2.6.x ???
          setTimeout(() => (this.isActive = value));
        });
      } else {
        this.isActive = !this.isActive;
      }
    },
    updateAppendToBody() {
      const contextMenu = this.$refs.contextMenu as HTMLDivElement;
      const trigger = this.$refs.trigger as HTMLDivElement;
      if (contextMenu && trigger) {
        const rect = trigger.getBoundingClientRect();
        let top = rect.top + window.scrollY;
        let left = rect.left + window.scrollX;
        if (
          window.screenY +
            rect.top +
            contextMenu.clientHeight +
            trigger.clientHeight >
          window.innerHeight
        ) {
          top -= contextMenu.clientHeight;
        } else {
          top += trigger.clientHeight;
        }
        left -= contextMenu.clientWidth - trigger.clientWidth;

        contextMenu.style.position = "absolute";
        contextMenu.style.top = `${top}px`;
        contextMenu.style.left = `${left}px`;
        contextMenu.style.zIndex = "9999";
      }
    },
    createAbsoluteElement(el: Element) {
      const root = document.createElement("div");
      root.style.position = "absolute";
      root.style.left = "0px";
      root.style.top = "0px";
      const wrapper = document.createElement("div");
      root.appendChild(wrapper);
      wrapper.appendChild(el);
      document.body.appendChild(root);
      return root;
    },
  },
});
</script>

<style scoped>
.ui-context {
  display: inline-flex;
  position: relative;
  vertical-align: top;
  &__trigger {
    width: 100%;
  }
  &__menu {
    position: absolute;
    left: 0;
    top: 100%;
    display: block;
    min-width: 12rem;
    z-index: 20;
    background-color: #272727;
    color: #fff;
    padding: 8px;
    border-radius: 5px;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
      0 0 0 1px rgba(10, 10, 10, 0.02);
    margin: 0;
    &--top-left {
      top: auto;
      bottom: 100%;
      right: 0;
      left: auto;
    }
    &--bottom-left {
      right: 0;
      left: auto;
    }
    &--top-right {
      top: auto;
      bottom: 100%;
    }
  }
}
</style>
