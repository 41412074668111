<template lang="pug">
main.event-table
  UiTable(
    :data="eventList",
    :loading="loading",
    :total="count",
    :default-sort="['startTime', 'desc']",
    :current-page="paginationOffset + 1",
    :rows-per-page="rowsPerPage",
    @page-change="pageChange",
    @update:rows-per-page="rowsPerPageChanged",
    @empty-table-row-clicked="addEventClicked(eventSeriesId)",
    @sort="setSortDirection",
    @click="(event: any) => clickedEvent(event.EventSeries.id, event.id)"
  )
    template(#caption)
      span.caption {{ title }}

    UiTableColumn(
      v-slot="props",
      label="Name",
      position="left",
      :sortable="true",
      field="title"
    )
      p(:class="{ lt: props.row.cancelled }") {{ props.row.title }}

    UiTableColumn(
      v-if="organizerId",
      v-slot="props",
      label="Series",
      position="left",
      :sortable="true",
      field="EventSeries.name"
    )
      .flex.items-center
        EventSeriesLogo.mr-3(:event-series-id="props.row.EventSeries.id")
        p(:class="{ lt: props.row.cancelled }") {{ props.row.EventSeries.name }}

    UiTableColumn(
      v-slot="props",
      label="Startdatum",
      position="left",
      :sortable="true",
      field="startTime"
    )
      p(:class="{ lt: props.row.cancelled }") {{ parseDate(props.row.startTime) }}

    UiTableColumn(
      v-slot="props",
      label="Enddatum",
      position="left",
      :sortable="true",
      field="endTime"
    )
      p(:class="{ lt: props.row.cancelled }") {{ parseDate(props.row.endTime) }}

    UiTableColumn(
      v-if="eventSeriesId"
      v-slot="props",
      label="Location",
      position="left",
      field="locationAlias",
    )
      p(:class="{ lt: props.row.cancelled }") {{ props.row.locationAlias }}

    UiTableColumn(
      label="Sponsored",
      position="left",
      field="locationAlias"
    )
      p(:class="{ lt: props.row.cancelled }") {{ props.row.Promotion.length > 0 ? "Ja" : "Nein" }}

    UiTableColumn(position="centered")
      template(#default="props")
        UiButton(
          v-if="!props.row.cancelled && isAllowedToPromoteEvents && !eventIsInPast(props.row.startTime) && props.row.Promotion.length === 0",
          style-ids="action",
          @click.stop="showPromotionModal(props.row)"
        ) Event bewerben

    UiTableColumn(position="centered")
      template(#default="props")
        UiContextMenu
          template(#trigger)
            span.text-sm.font-semibold.cursor-pointer &middot; &middot; &middot;
          UiContextMenuItem(
            @click="copyEvent(props.row.EventSeries.id, props.row.id)"
          ) Event kopieren
          UiContextMenuItem(
            v-if="!props.row.cancelled && isAllowedToPromoteEvents && !eventIsInPast(props.row.startTime) && props.row.Promotion.length === 0",
            @click="showPromotionModal(props.row)"
          ) Event bewerben

      template(#header)
        button.add-event-button(
          v-if="eventSeriesId",
          @click="eventSeriesId && addEventClicked(eventSeriesId)"
        ) + Add Event
    template(#empty-table) {{ "+ Veranstaltung hinzufügen" }}
SelectOrCreateEventSeriesModal(
  v-if="showCreateEventModal",
  @close="showCreateEventModal = false",
  @action-clicked="showCreateEventModal = false"
)
UiModal.modal-promotion(
  v-if="promotionModalVisible",
  @close="hidePromotionModal"
)
  template(#header)
    p.modal-promotion-title Bewerbung deiner Veranstaltung
  template(#content)
    div
      UiMessage.event-in-past-message(:active="true", :closable="false")
        .flex.flex-row
          p.modal-promotion-content-text {{ `Durch Bewerbung erhält deine Veranstaltung "${promotionModalEventTitle}" erhöhte Aufmerksamkeit bei den Nutzern in der App. Alle Details zur Bewerbung von Events findest du ` }} <a class="underline" href="https://pingr.de" target="_blank">hier</a>.
      p.mt-5.text-lg.font-bold.modal-promotion-content-text Zusammenfassung
      .summary
        .flex.flex-row
          p.ml-4 1x
          p.ml-4 Bewerbung der Veranstaltung "Test"
          p(style="margin-left: auto") {{ `${promotionPrice ? formatNumberValue(promotionPrice) : "error"} Credits` }}
        .flex.flex-row.mt-6
          p Guthaben danach:
          p(
            style="margin-left: auto",
            :class="{ 'text-background-highlight': !promotionModalButtonEnabled }"
          ) {{ `${(organizerBalance || organizerBalance === 0) && promotionPrice ? formatNumberValue(organizerBalance - promotionPrice) : "error"} Credits` }}
  template(#footer)
    .modal-promotion-footer
      UiButton.modal-promotion-button(
        @click="hidePromotionModal",
        style-ids="subliminal"
      ) Abbrechen
      UiButton(
        @click="promoteEventClicked",
        :style-ids="{ action: true }",
        :disabled="!promotionModalButtonEnabled"
      ) Veranstaltung bewerben
UiLoadingOverlay(v-if="isCreatingPromotion") Bewerbung wird angelegt...
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useMutation, useQuery } from "@vue/apollo-composable";
import UiTable from "../../../ui/Table/UiTable.vue";
import UiTableColumn from "../../../ui/Table/UiTableColumn.vue";
import EventSeriesLogo from "../../EventSeries/Logo/EventSeriesLogo.vue";
import UiContextMenu from "../../../ui/ContextMenu/UiContextMenu.vue";
import UiContextMenuItem from "../../../ui/ContextMenu/UiContextMenuItem.vue";
import {
  EventPromotionResponse,
  EventTableEvent,
  EventTableFetchPromotionDataResponse,
  EventTableResponse,
} from "./types";
import {
  eventTable_promoteEvent,
  eventTableFetchPromotionData,
  getAllEventsByEventSeriesId,
  getAllEventsOfAnOrganizer,
} from "./queries";
import { formatDate } from "../../../utils/dateFormatter";
import SelectOrCreateEventSeriesModal from "../../EventSeries/Modal/SelectOrCreateEventSeriesModal.vue";
import UiModal from "../../../ui/Modal/UiModal.vue";
import UiButton from "../../../ui/Button/UiButton.vue";
import UiLoadingOverlay from "../../../ui/LoadingOverlay/LoadingOverlay.vue";
import { useToast } from "vue-toastification";
import { useStore } from "vuex";
import { storeKey } from "../../../infrastructure/store/store";
import { isBefore } from "date-fns";
import UiMessage from "../../../ui/Message/UiMessage.vue";
import { authenticatedArea_organizer } from "../../../application/pages/authenticatedArea/queries";
import { formatNumber } from "../../../utils/numberFormatter";

export default defineComponent({
  name: "EventTable",
  components: {
    UiMessage,
    UiTable,
    UiTableColumn,
    EventSeriesLogo,
    UiContextMenu,
    UiContextMenuItem,
    SelectOrCreateEventSeriesModal,
    UiModal,
    UiButton,
    UiLoadingOverlay,
  },
  props: {
    // TODO: remove
    organizerId: {
      type: String,
      default: undefined,
    },
    eventSeriesId: {
      type: String,
      default: undefined,
    },
  },
  setup(props: {
    organizerId: string | undefined;
    eventSeriesId: string | undefined;
  }) {
    let error;
    let loading = ref(false);
    let result = ref<EventTableResponse | undefined>({} as EventTableResponse);
    let orderBy = ref<{ [key: string]: string }>({ startTime: "desc" });
    const offset = ref(0);
    const rowsPerPage = ref(10);
    const toast = useToast();
    const store = useStore(storeKey);
    const activeOrganizerId = store.getters.organizer;
    const isAllowedToPromoteEvents = computed(
      () => store.getters.isOrganizerManager && store.getters.isEventManager,
    );

    if (props.organizerId) {
      ({ result, loading, error } = useQuery<EventTableResponse>(
        getAllEventsOfAnOrganizer,
        () => ({
          organizerId: props.organizerId,
          orderBy: orderBy.value,
          limit: rowsPerPage.value,
          offset: offset.value * rowsPerPage.value,
        }),
        () => ({
          fetchPolicy: "cache-and-network",
          refetchWritePolicy: "overwrite",
        }),
      ));
    }

    if (props.eventSeriesId) {
      ({ result, loading, error } = useQuery<EventTableResponse>(
        getAllEventsByEventSeriesId,
        () => ({
          eventSeriesId: props.eventSeriesId,
          orderBy: orderBy.value,
          limit: rowsPerPage.value,
          offset: offset.value * rowsPerPage.value,
        }),
        () => ({
          fetchPolicy: "cache-and-network",
          refetchWritePolicy: "overwrite",
        }),
      ));
    }

    const setSortDirection = (field: string, sortDirection: any) => {
      const [last, ...paths] = field.split(".").reverse();
      orderBy.value = paths.reduce((acc, el) => ({ [el]: acc }), {
        [last]: sortDirection,
      });
    };

    const setOffset = (newOffset: number) => {
      offset.value = newOffset;
    };

    const setRowsPerPage = (newRowsPerPage: number) => {
      rowsPerPage.value = newRowsPerPage;
    };

    const { mutate: promoteEvent, loading: isCreatingPromotion } =
      useMutation<EventPromotionResponse>(eventTable_promoteEvent, {
        refetchQueries: [
          props.organizerId
            ? {
                query: getAllEventsOfAnOrganizer,
                variables: {
                  organizerId: props.organizerId,
                  orderBy: orderBy.value,
                  limit: rowsPerPage.value,
                  offset: offset.value * rowsPerPage.value,
                },
              }
            : {
                query: getAllEventsByEventSeriesId,
                variables: {
                  eventSeriesId: props.eventSeriesId,
                  orderBy: orderBy.value,
                  limit: rowsPerPage.value,
                  offset: offset.value * rowsPerPage.value,
                },
              },
          {
            query: authenticatedArea_organizer,
            variables: { id: activeOrganizerId },
          },
        ],
      });

    const { result: promotionDataResult } =
      useQuery<EventTableFetchPromotionDataResponse>(
        eventTableFetchPromotionData,
        {
          organizerId: activeOrganizerId,
          productId: "88f53f00-3cdf-4550-9c19-d72040d9b9ad",
        },
      );

    return {
      eventList: computed(() => result.value?.Event ?? []),
      count: computed(() => result.value?.Event_aggregate.aggregate.count ?? 0),
      loading,
      error: error,
      paginationOffset: offset,
      rowsPerPage,
      setSortDirection,
      setOffset,
      setRowsPerPage,
      organizerBalance: computed(
        () =>
          promotionDataResult.value?.Organizer_by_pk.OrganizerAccountBalance
            .balance ?? null,
      ),
      promotionPrice: computed(
        () => promotionDataResult.value?.Product_by_pk.price ?? null,
      ),
      promoteEvent,
      toast,
      isAllowedToPromoteEvents,
      isCreatingPromotion,
    };
  },
  data() {
    return {
      title: "ALL EVENTS",
      sortDirection: ["startTime", "desc"],
      showCreateEventModal: false,
      promotionModalVisible: false,
      promotionModalEventTitle: "",
      promotionModalEventId: "",
      promotionModalPromotionId: "",
    };
  },
  computed: {
    promotionModalButtonEnabled() {
      return (
        this.organizerBalance &&
        this.promotionPrice &&
        this.organizerBalance - this.promotionPrice >= 0
      );
    },
  },
  watch: {
    organizerId() {
      this.setOffset(0);
    },
  },
  methods: {
    copyEvent(eventSeriesId: string, eventId: string) {
      this.$router.push({
        path: "/event-series/" + eventSeriesId + "/events/create/",
        query: { sourceEvent: eventId },
      });
    },
    eventIsInPast(startTime: string) {
      if (startTime) {
        return isBefore(new Date(startTime), new Date());
      }
      return false;
    },
    showPromotionModal(event: EventTableEvent) {
      this.promotionModalEventTitle = event.title;
      this.promotionModalEventId = event.id;
      this.promotionModalVisible = true;
    },
    hidePromotionModal() {
      this.promotionModalVisible = false;
    },
    promoteEventClicked() {
      this.promoteEvent({ eventId: this.promotionModalEventId }, {})
        .then(() => {
          this.toast.success("Veranstaltung wird nun beworben!");
        })
        .catch(() => {
          this.toast.error("Veranstaltung kann nicht beworben werden.");
        });
      this.hidePromotionModal();
    },
    pageChange(pageNumber: number) {
      this.setOffset(pageNumber - 1);
    },
    clickedEvent(eventSeriesId: string, eventId: string) {
      this.$router.push(
        "/event-series/" + eventSeriesId + "/events/" + eventId + "/update",
      );
    },
    parseDate(date: string) {
      return formatDate(date, "dd.MM.yyyy HH:mm");
    },
    addEventClicked(eventSeriesId?: string) {
      if (eventSeriesId) {
        this.$router
          .push({ path: "/event-series/" + eventSeriesId + "/events/create" })
          .catch((err) => console.log(err));
      } else {
        this.showCreateEventModal = true;
      }
    },
    rowsPerPageChanged(rowsPerPage: number) {
      this.setRowsPerPage(rowsPerPage);
    },
    openContextMenu(event: MouseEvent) {
      (this.$refs.menu as typeof UiContextMenu).open(event);
    },
    formatNumberValue(value: number | string) {
      return formatNumber(value);
    },
  },
});
</script>
<style scoped>
.lt {
  @apply line-through;
}
.caption {
  @apply float-left text-base font-black mb-5;
}
.event-table {
  @apply flex w-full px-4 py-4;
}

.add-event-button {
  @apply text-primary font-bold;
}

.button-context {
  @apply font-black text-xl;
}
.cancel-event-item {
  @apply text-background-highlight;
}

.modal-promotion {
  :deep .modal {
    max-width: 60%;
  }
  @media (min-width: 1200px) {
    :deep .modal {
      max-width: 40%;
    }
  }
  @media (min-width: 1700px) {
    :deep .modal {
      max-width: 30%;
    }
  }
  &-title {
    @apply text-xl font-extrabold;
  }
  &-content {
    &-text {
      @apply select-text mb-3;
    }
    &-input {
      @apply w-8/12;
    }
  }
  &-button {
    @apply mr-4;
  }
  &-footer {
    @apply flex w-full justify-start;
  }
}
</style>
