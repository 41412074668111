<template lang="pug">
UiSelect(
  :rounded="rounded",
  v-model="selectedPrimaryTagId",
  @change="selectedPrimaryTagChanged",
  :validation-rules="{ required: true }",
  :disabled="disabled",
  name="Main Category",
  :style-ids="styleIds",
  :loading="loading || parentIsLoading"
)
  option(
    v-for="primaryTag in primaryTags",
    :value="primaryTag.id",
    :key="primaryTag.id",
    data-testid="primary-tag-option"
  ) {{ primaryTag.name }}
</template>

<script lang="ts">
import {computed, defineComponent} from "vue";
import UiSelect from "../../../ui/Select/UiSelect.vue";
import { STYLE_IDS_PROP_DEF } from "../../../ui/utils/style-ids";
import { useQuery } from "@vue/apollo-composable";
import { PrimaryTagSelectLoadAllPrimaryTagsResponse } from "./types";
import { loadAllPrimaryTags } from "./queries";

export default defineComponent({
  name: "PrimaryTagSelect",
  components: { UiSelect },
  props: {
    rounded: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    parentIsLoading: { type: Boolean, required: false, default: false },
    styleIds: STYLE_IDS_PROP_DEF,
  },
  emits: ["update:modelValue"],
  setup() {
    const { loading, result } =
      useQuery<PrimaryTagSelectLoadAllPrimaryTagsResponse>(loadAllPrimaryTags);

    return {
      primaryTags: computed(() => result.value?.PrimaryTag ?? []),
      loading,
    };
  },
  data() {
    return {
      selectedPrimaryTagId: this.modelValue,
    };
  },
  watch: {
    modelValue() {
      this.selectedPrimaryTagId = this.modelValue;
    },
  },
  methods: {
    selectedPrimaryTagChanged() {
      if (this.disabled) return;
      this.$emit("update:modelValue", this.selectedPrimaryTagId);
    },
  },
});
</script>

<style scoped></style>
