import { Point } from "geojson";

export interface AutocompletePrediction {
  description: string;
  /**
   * The distance in meters of the place from the {@link AutocompletionRequest#origin}.
   * @see {@link https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletePrediction.distance_meters Maps JavaScript API}
   */
  distance_meters?: number;
  id?: string;
  matched_substrings: PredictionSubstring[];
  place_id: string;
  reference: string;
  structured_formatting: {
    main_text: string;
    main_text_matched_substrings: PredictionSubstring[];
    secondary_text: string;
    secondary_text_matched_substrings?: PredictionSubstring[];
  };
  terms: PredictionTerm[];
  types: string[];
}

interface PredictionSubstring {
  length: number;
  offset: number;
}

interface PredictionTerm {
  offset: number;
  value: string;
}

export enum PlacesServiceStatus {
  INVALID_REQUEST = "INVALID_REQUEST",
  NOT_FOUND = "NOT_FOUND",
  OK = "OK",
  OVER_QUERY_LIMIT = "OVER_QUERY_LIMIT",
  REQUEST_DENIED = "REQUEST_DENIED",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  ZERO_RESULTS = "ZERO_RESULTS",
}

export interface AddressComponent {
  types: string[];
  long_name: string;
}

export interface MappedAddressComponent {
  streetNumber: string;
  streetName: string;
  city: string;
  postalCode: string;
}

export interface PlaceResult {
  address_components?: AddressComponent[];
  name?: string;
  formatted_address: string;
  geometry: {
    location: LatLng;
  };
}

interface LatLng {
  lat: () => number;
  lng: () => number;
}

export interface FormattedPlace {
  location: Point;
  locationAlias: string;
  addressComponents: MappedAddressComponent;
}
