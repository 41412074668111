import gql from "graphql-tag";

export const getTransactionsAndBalanceOfOrganizer = gql`
  query frontend_organizer_getTransactionsOfOrganizer(
    $organizerId: uuid!
    $orderBy: [OrganizerAccountTransactions_order_by!]
    $limit: Int
    $offset: Int
  ) {
    OrganizerAccountTransactions(
      offset: $offset
      limit: $limit
      where: { Organizer: { id: { _eq: $organizerId } } }
      order_by: $orderBy
    ) {
      action
      createdAt
      amount
      description
      id
    }
    OrganizerAccountTransactions_aggregate(
      where: { Organizer: { id: { _eq: $organizerId } } }
    ) {
      aggregate {
        count
      }
    }
    OrganizerAccountBalance_by_pk(organizerId: $organizerId) {
      balance
    }
  }
`;

export const insertDeposit = gql`
  mutation frontend_insertTransaction(
    $organizerId: uuid!
    $description: String!
    $amount: Int!
  ) {
    insert_OrganizerAccountTransactions_one(
      object: {
        organizerId: $organizerId
        description: $description
        amount: $amount
      }
    ) {
      id
    }
  }
`;
