<template lang="pug">
// Mousedown is used to prevent closing the modal if the click was started inside the modal and released outside the modal
.modal-backdrop(@mousedown="onMouseDown")
  .modal
    .modal-header(v-if="hasHeaderSlot")
      slot(name="header")
    section.modal-body(v-show="hasContentSlot")
      slot(name="content")
    .modal-footer(v-if="hasFooterSlot")
      slot(name="footer")
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "UiModal",
  emits: ["close"],
  computed: {
    hasHeaderSlot() {
      return !!this.$slots.header;
    },
    hasContentSlot() {
      return !!this.$slots.content;
    },
    hasFooterSlot() {
      return !!this.$slots.footer;
    },
  },
  methods: {
    onMouseDown(e: MouseEvent) {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains("modal-backdrop")
      ) {
        this.$emit("close");
      }
    },
  },
});
</script>

<style scoped>
.modal-backdrop {
  @apply absolute top-0 bottom-0 left-0 right-0;
  @apply flex justify-center items-center z-20;

  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  @apply absolute overflow-auto;
  @apply flex flex-col rounded overflow-hidden;
  @apply py-6 px-3;

  background: #141414;
  box-shadow: 2px 2px 20px 1px #000000;

  &-header,
  &-footer,
  &-body {
    @apply flex py-4 mx-8;
  }

  &-header {
    @apply justify-between border-border-default border-b;
  }

  &-footer {
    @apply justify-evenly;
  }
}
</style>
