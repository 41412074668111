<template lang="pug">
form.content(automcomplete="off", @submit="onSubmit")
  .section-header
    p Konfiguriere den Secondary Tag
    .section-divider
  label.label Primary Tag *
  UiSelect(
    v-model="secondaryTag.primaryTagId",
    :validation-rules="{ required: true }",
    placeholder="Primary Tag auswählen",
    name="Primary Tag"
  )
    option(v-for="primaryTag in primaryTags", :key="primaryTag.id" :value="primaryTag.id") {{ primaryTag.name }}
  .section-header
    p Übersetzung
    .section-divider
  label Englisch *
  UiTextInput(
    v-model="secondaryTag.translations['en']",
    style-ids="rounded-border hover-primary",
    name="en",
    :validation-rules="{ required: true, checkSecondaryNameCreate: mode === 'create', checkSecondaryNameUpdate: mode === 'update' }"
  )
  template(v-for="translation in translationCount", :key="translation")
    UiSelect.translation-select(
      v-model="select[translation]",
      placeholder="Sprache auswählen",
      @change="onUiSelectChange($event, translation)"
    )
      option(
        v-for="language in languages.filter((lang) => mode === 'create' ? (!Object.keys(secondaryTag.translations).includes(lang.language) || lang.language === translationMapping[translation]) : (lang.language !== 'en' && (!selectedLanguages.includes(lang.language) || lang.language === select[translation])))"
        :key="language"
        :value="language.language"
      ) {{ language.language }}

    UiTextInput.translation-input(
      v-model="secondaryTag.translations[translationMapping[translation]]",
      style-ids="rounded-border hover-primary",
      :name="`translation${translation}`",
      :disabled="!Object.keys(secondaryTag.translations).includes(translationMapping[translation])",
      :validation-rules="{ checkSecondaryNameCreate: mode === 'create', checkSecondaryNameUpdate: mode === 'update' }"
    )
      template(#icon)
        button(
          @click="onLanguageRemove(translationMapping[translation], translation)"
        ) x
  UiButton.justify-self-stretch.align-self-stretch(
    style-ids="primary",
    :disabled="translationCount >= languages.length - 1"
    @click="translationCount++",
  ) Übersetzung hinzufügen
  UiButton.submit-button(
    style-ids="primary",
    :disabled="isSubmitting || hasErrors"
    @click="onSubmit",
  ) {{ mode === "create" ? "Erstellen" : "Speichern" }}
</template>

<script lang="ts">
import { computed, defineComponent, reactive, Ref, ref, toRefs } from "vue";
import { useForm } from "vee-validate";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { useRouter } from "vue-router";
import { v4 } from "uuid";
import { TYPE, useToast } from "vue-toastification";
import UiTextInput from "../../../ui/TextInput/UiTextInput.vue";
import UiSelect from "../../../ui/Select/UiSelect.vue";
import UiButton from "../../../ui/Button/UiButton.vue";
import {
  secondaryTagFormUpsertSecondaryTag,
  secondaryTagFormMetaQuery,
  secondaryTagFormSecondaryTagsQuery,
} from "./queries";
import {
  SecondaryTagFormMetaResponse,
  SecondaryTagFormMutationResponse,
  SecondaryTagFormQueryResponse,
  SecondaryTagFormSecondaryTag,
} from "./types";

export default defineComponent({
  name: "SecondaryTagForm",
  components: {
    UiButton,
    UiSelect,
    UiTextInput,
  },
  props: {
    tagId: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: ["onSubmit", "onDelete"],
  setup(props) {
    const { tagId } = toRefs(props);
    const router = useRouter();
    const mode = ref(router.currentRoute.value.fullPath.split("/")[2]);
    const id = ref(v4());
    const toast = useToast();
    const translationCount = ref(0);
    const translationMapping = ref(["en"]);
    const select = ref({}) as Ref<{ [key: number]: string | undefined }>;
    const translationsToDelete = ref<string[]>([]);

    const { handleSubmit, isSubmitting, errors, validate } = useForm();

    const secondaryTag = reactive({
      id: mode.value === "create" ? id.value : tagId.value,
      translations: {
        en: "", // add english translation as it is required
      } as { [key: string]: string },
      primaryTagId: ref(undefined) as Ref<string | undefined>,
    });

    const parseData = (tag: SecondaryTagFormSecondaryTag) => {
      secondaryTag.id = tag.id;
      secondaryTag.primaryTagId =
        tag.PrimarySecondaryMappings[0]?.primary_id || undefined;

      const translations = {} as { [key: string]: string };
      for (const value of tag.Translations) {
        translations[value.language] = value.translation;
      }
      secondaryTag.translations = translations;
      translationCount.value = tag.Translations.length - 1;
      for (const [key, value] of Object.keys(secondaryTag.translations)
        .filter((lang) => lang !== "en")
        .entries()) {
        translationMapping.value[key + 1] = value;
        select.value[key + 1] = value;
      }
      validate();
    };

    const { mutate: upsert } = useMutation<SecondaryTagFormMutationResponse>(
      secondaryTagFormUpsertSecondaryTag
    );

    const { result: secondaryTagResult, onResult: onSecondaryTagResult } =
      useQuery<SecondaryTagFormQueryResponse>(
        secondaryTagFormSecondaryTagsQuery,
        () => ({ id: tagId.value }),
        () => ({ enabled: mode.value === "update" })
      );

    const secondaryTagResponse = computed(() => secondaryTagResult.value?.SecondaryTag_by_pk);

    if (secondaryTagResponse.value) {
      parseData(secondaryTagResponse.value);
    }

    onSecondaryTagResult((result) => {
      parseData(result.data.SecondaryTag_by_pk);
    });

    const { result: metaResult, loading: languageLoading } =
      useQuery<SecondaryTagFormMetaResponse>(secondaryTagFormMetaQuery);

    const languages = computed(() => metaResult.value?.Languages ?? []);

    const isTranslationButtonEnabled = computed(() => {
      return (
        languages.value.filter(
          (language) =>
            !Object.keys(secondaryTag.translations).includes(language.language)
        ).length === 0 || translationCount.value === languages.value.length - 1
      );
    });

    const onSubmit = handleSubmit(() => {
      let variables = {};
      if (mode.value === "create") {
        variables = {
          id: secondaryTag.id,
          primaryTagId: secondaryTag.primaryTagId,
          name: secondaryTag.translations["en"],
          translations: Object.keys(secondaryTag.translations).map((key) => ({
            language: key,
            translation: secondaryTag.translations[key],
          })),
          deleteTranslationLanguages: [],
        };
      } else if (mode.value === "update") {
        variables = {
          id: secondaryTag.id,
          primaryTagId: secondaryTag.primaryTagId,
          name: secondaryTag.translations["en"],
          translations: Object.keys(secondaryTag.translations).map((key) => ({
            language: key,
            translation: secondaryTag.translations[key],
          })),
          deleteTranslationLanguages: translationsToDelete.value || [],
        };
      }
      upsert(variables)
        .then(() => router.back())
        .catch((error) => {
          toast("Fehler beim Erstellen des Tags. Versuches es erneut!", {
            type: TYPE.ERROR,
          });
          throw error;
        });
    });

    const hasErrors = computed(() => {
      return Object.keys(errors.value).length !== 0;
    });

    const validateSecondaryTag = async () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 1500);
      });
    };

    const onLanguageRemove = (language: string, index: number) => {
      translationsToDelete.value.push(language);
      translationMapping.value.splice(index, 1);
      for (let i = index + 1; i <= Object.keys(select.value).length; i++) {
        const tmp = select.value[i];
        select.value[i] = undefined;
        select.value[i - 1] = tmp;
      }
      delete select.value[Object.keys(select.value).length];
      delete secondaryTag.translations[language];
      translationCount.value--;
    };

    const selectedLanguages = computed(() => {
      const selectedLanguages: string[] = ["en"];
      for (const key of Object.keys(select.value)) {
        if (select.value[Number(key)] !== null) {
          selectedLanguages.push(select.value[Number(key)] as string);
        }
      }
      return selectedLanguages;
    });

    const onUiSelectChange = (value: string, index: number) => {
      if (value) {
        // remove the selected language from the deleteObject if it is in there
        if (translationsToDelete.value.includes(value)) {
          translationsToDelete.value = translationsToDelete.value.splice(
            translationsToDelete.value.indexOf(value),
            1
          );
        }
        translationMapping.value[index] = value;
        if (!secondaryTag.translations[translationMapping.value[index]]) {
          secondaryTag.translations[translationMapping.value[index]] = "";
        }
      }
    };

    return {
      secondaryTag,
      primaryTags: computed(() => metaResult.value?.PrimaryTag),
      languageLoading,
      onSubmit,
      isSubmitting,
      onUiSelectChange,
      selectedLanguages,
      hasErrors,
      languages: computed(() => metaResult.value?.Languages),
      onLanguageRemove,
      select,
      isTranslationButtonEnabled,
      errors,
      translationCount,
      translationsToDelete,
      validateSecondaryTag,
      mode,
      translationMapping,
    };
  },
  data() {
    return {
      showDeleteOrganizerModal: false,
      deleteOrganizerModalInputValue: "",
      languageSelection: "",
      placeholder: "Sprache auswählen",
    };
  },
});
</script>

<style scoped>
.width-40 {
  @apply w-2/5;
}

.content {
  @apply grid grid-flow-row;
  @apply mx-4 my-4;
  @apply gap-x-24 gap-y-4;
  @apply w-8/12;
  grid-template-columns: 3fr 8fr;

  & > * {
    @apply self-center;
  }

  .section {
    &-header {
      @apply text-lg font-bold;
      grid-area: auto / 1 / auto / span 2;
    }

    &-divider {
      @apply h-0.5 bg-background-contrast mt-2;
      grid-area: auto / 1 / auto / span 2;
    }
  }
}

.delete-button {
  @apply text-background-highlight underline;
}

.submit-button {
  grid-area: auto / 1 / auto / span 2;
}

.translation-row {
  @apply flex justify-between;
  grid-area: auto / 1 / auto / span 2;

  .translation-select {
    flex: 1;
    @apply w-10;
  }

  .translation-input {
    flex: 2;
  }
}

.modal-delete-button {
  @apply mr-4;
}
.modal-delete-title {
  @apply text-xl font-extrabold;
}
.modal-delete-content {
  &-text {
    @apply select-text mb-3;
  }
  &-input {
    @apply w-8/12;
  }
}
.modal-delete-footer {
  @apply flex w-full justify-start;
}
.organizer-delete-modal::v-deep .modal {
  max-width: 50%;
}
</style>
