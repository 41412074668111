<template lang="pug">
label.ui-switch(
  ref="label",
  :class="{ 'cursor-pointer': !disabled }",
  @click="focus",
  @keydown.prevent.enter="clickLabel",
  @mousedown="isMouseDown = true",
  @mouseup="isMouseDown = false",
  @mouseout="isMouseDown = false",
  @blur="isMouseDown = false"
)
  input.ui-switch__input(
    ref="input",
    v-model="computedValue",
    type="checkbox",
    tabindex="0",
    :disabled="disabled"
    @click.stop,
  )
  span.ui-switch__label.ui-switch__label-left
    slot(name="left")
  span.ui-switch__check.ui-switch--rounded(
    :class="{ 'ui-switch__check--checked': newValue }"
  )
    span.ui-switch__check-switch.ui-switch--rounded
  span.ui-switch__label.ui-switch__label-right
    slot(name="right")
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "UiSwitch",
  configField: "switch",
  props: {
    modelValue: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      newValue: this.modelValue,
      isMouseDown: false,
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value: boolean) {
        this.newValue = value;
        this.$emit("update:modelValue", this.newValue);
      },
    },
  },
  watch: {
    /**
     * When v-model change, set internal value.
     */
    modelValue(value) {
      this.newValue = value;
    },
  },
  methods: {
    focus() {
      if (this.disabled) {
        return;
      }
      // MacOS FireFox and Safari do not focus when clicked
      (this.$refs.input as HTMLInputElement).focus();
    },
    clickLabel() {
      if (this.disabled) {
        return;
      }
      (this.$refs["label"] as HTMLLabelElement).click();
    },
  },
});
</script>

<style scoped lang="scss">
.ui-switch {
  @apply inline-flex items-center relative;

  &__label {
    &-left {
      @apply mr-2;
    }
    &-right {
      @apply ml-2;
    }
  }

  &__check-switch {
    @apply content-none	block transition-transform ease-out	origin-left shadow;
    height: 1.375rem;
    width: 1.375rem;
    background: #f5f5f5;
    will-change: transform;
  }

  &__check {
    @apply flex items-center shrink-0 bg-background-subliminal rounded-full ease-out duration-150;
    width: 2.75rem * 0.75;
    height: 1.375rem - 0.2rem;
    transition-property: background;

    &--checked {
      @apply bg-primary;
      .ui-switch__check-switch {
        transform: translate3d(50%, 0, 0);
      }
    }
  }

  &__input {
    @apply absolute left-0 opacity-0;
    outline: none;
    z-index: -1;
  }

  &--rounded {
    @apply rounded-full;
  }

  &--disabled {
    @apply opacity-50;
  }
}
</style>
