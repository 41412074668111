import store from "./store/store";
import { createRouter, createWebHistory } from "vue-router";
import AuthenticatedArea from "../application/pages/authenticatedArea/AuthenticatedArea.vue";
import Dashboard from "../application/pages/authenticatedArea/subpages/AdminDashboard.vue";
import Login from "../application/pages/login/Login.vue";
import EventUpdate from "../domain/Event/Update/EventUpdate.vue";
import OrganizerOverview from "../domain/Organizer/Overview/OrganizerOverview.vue";
import OrganizerCreate from "../domain/Organizer/Create/OrganizerCreate.vue";
import OrganizerUpdate from "../domain/Organizer/Update/OrganizerUpdate.vue";
import TagOverview from "../application/pages/authenticatedArea/subpages/TagOverview/index.vue";
import EventSeriesOverview from "../domain/EventSeries/Overview/Overview.vue";
import EventSeriesForm from "../domain/EventSeries/Form/index.vue";
import EventCreate from "../domain/Event/Create/EventCreate.vue";
import SecondaryTagForm from "../domain/SecondaryTag/Form/index.vue";
import { getAuth } from "firebase/auth";
import FirebaseUserForm from "../application/pages/authenticatedArea/subpages/AdminOverview/Form/index.vue";
import InitialLoadingPage from "../application/pages/InitialLoadingPage.vue";
import AdminOverview from "../application/pages/authenticatedArea/subpages/AdminOverview/index.vue";
import AdminForm from "../application/pages/authenticatedArea/subpages/AdminOverview/Form/index.vue";
import Scraper from "../application/pages/authenticatedArea/subpages/Scraper/Scraper.vue";

// define the routes
// nested routes for dashboard navigation
const routes = [
  {
    path: "/",
    component: AuthenticatedArea,
    meta: {
      title: "Pingr",
    },
    children: [
      { name: "Dashboard", path: "dashboard", component: Dashboard },
      { path: "event-series/create", component: EventSeriesForm },
      {
        path: "event-series/update/:eventSeriesId",
        component: EventSeriesForm,
        props: true,
      },
      { path: "event-series/:id", component: EventSeriesOverview },
      { path: "event-series/:id/events/create", component: EventCreate },
      {
        path: "event-series/:id/events/:eventId/update",
        component: EventUpdate,
      },
      { path: "organizers", component: OrganizerOverview },
      { path: "organizer/create", component: OrganizerCreate },
      { path: "organizer/update/:organizerId", component: OrganizerUpdate },
      { path: "secondary-tags", component: TagOverview },
      { path: "secondary-tags/create", component: SecondaryTagForm },
      {
        path: "users/update/:userId",
        name: "FirebaseUserForm",
        component: FirebaseUserForm,
        props: true,
      },
      {
        path: "secondary-tags/update/:tagId",
        component: SecondaryTagForm,
        props: true,
      },
      {
        path: "admins",
        component: AdminOverview,
        name: "AdminOverview",
      },
      {
        path: "admins/update/:id",
        component: AdminForm,
        name: "UpdateAdmin",
        props: true,
      },
      {
        path: "admins/create",
        component: AdminForm,
        name: "CreateAdmin",
      },
      {
        path: "scraper",
        component: Scraper,
        name: "Scraper",
      },
    ],
  },
  { path: "/login", component: Login },
  { path: "/", component: InitialLoadingPage, name: "initial-loading" },
];

// TODO: define correct history mode

const router = createRouter({
  routes,
  history: createWebHistory(),
});

// TODO: configure firebase auth persistence
// https://firebase.google.com/docs/auth/web/auth-state-persistence
// redirect if not logged in and user opens something else than /login
router.beforeEach((to, from, next) => {
  // TODO Check if this solution still works
  /*const forbiddenPaths = ["/users", "/organizer-overview"];

  // TODO: forbidden routes are callable :(
  // => use router guards for this
  // test to somehow catch forbidden routes if entered directly as url
  if (forbiddenPaths.includes(to.path) && !vuexUser.isAdmin) {
    next(false);
    console.log("Forbidden");
    return;
  }*/

  if (
    to.path !== "/login" &&
    !store.getters.initialLoading &&
    getAuth().currentUser === null
  ) {
    next({ path: "/login" });
  } else {
    next();
  }
});

export default router;
