import gql from "graphql-tag";

export const tagOverviewSecondaryTagsQuery = gql`
  query frontend_tagOverview_SecondaryTags(
    $timeNow: timestamptz
    $orderBy: [SecondaryTagTranslation_order_by!]
    $limit: Int
    $offset: Int
    $search: String
  ) {
    SecondaryTagTranslation(
      offset: $offset
      limit: $limit
      order_by: $orderBy
      where: { language: { _eq: en } }
    ) {
      id
      language
      translation
      SecondaryTag {
        id
        createdAt
        updatedAt
        EventSecondaryMappings_aggregate {
          aggregate {
            count
          }
        }
        SecondaryInterests_aggregate {
          aggregate {
            count(columns: userId)
          }
        }
      }
    }
    search_secondary_translations(
      offset: $offset
      limit: $limit
      order_by: $orderBy
      where: { language: { _eq: en } }
      args: { lang: "en", search: $search }
    ) {
      id
      language
      translation
      SecondaryTag {
        id
        createdAt
        updatedAt
        EventSecondaryMappings_aggregate {
          aggregate {
            count
          }
        }
        SecondaryInterests_aggregate {
          aggregate {
            count(columns: userId)
          }
        }
      }
    }
    SecondaryTag_aggregate {
      aggregate {
        count
      }
    }
    search_secondary_translations_aggregate(
      offset: $offset
      limit: $limit
      order_by: $orderBy
      where: { language: { _eq: en } }
      args: { lang: "de", search: $search }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const tagOverviewProposalQuery = gql`
  query frontend_tagOverview_getProposals(
    $offset: Int!
    $limit: Int!
    $orderBy: [SecondaryProposals_order_by!]
    $where: SecondaryProposals_bool_exp
  ) {
    SecondaryProposals(
      offset: $offset
      limit: $limit
      order_by: $orderBy
      where: $where
    ) {
      id
      name
      created_at
    }
    SecondaryProposals_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
