<template lang="pug">
.wrapper(ref="tooltip")
  transition(name="fade")
    .tooltip(v-show="isActive", ref="content")
      template(v-if="label")
        span.text-sm.font-semibold {{ label }}
      template(v-else-if="$slots.content")
        slot(name="content")
  .tooltip-trigger(
    ref="trigger",
    :style="triggerStyle",
    @mouseenter="setActiveState(true)",
    @mouseleave="setActiveState(false)"
  )
    slot(ref="slot")
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "UiTooltip",
  props: {
    position: {
      type: String,
      default: "is-top",
      validator: (value: string) => {
        return ["is-top", "is-bottom", "is-left", "is-right"].includes(value);
      },
    },
    label: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      isActive: false,
      triggerStyle: {},
    };
  },
  watch: {
    isActive() {
      this.updateAppendToBody();
    },
  },
  mounted() {
    this.updateAppendToBody();
  },
  methods: {
    setActiveState(state: boolean) {
      this.isActive = state;
    },
    updateAppendToBody() {
      const tooltip = this.$refs.tooltip as HTMLDivElement;
      if (tooltip) {
        const rect = tooltip.getBoundingClientRect();
        // TODO Consider prop position (top, bottom, left, right) when calculating top and left value
        const maxWidth = 260;
        const padding = 2;
        let top = window.scrollY + rect.height + rect.y + padding;
        let left = window.scrollX + rect.x + rect.width + padding;

        const wrapper = this.$refs.content as HTMLDivElement;
        wrapper.style.position = "absolute";
        wrapper.style.top = `${top}px`;
        wrapper.style.left = `${left}px`;
        wrapper.style.maxWidth = `${maxWidth}px`;
        wrapper.style.zIndex = this.isActive ? "99" : "-1";
        this.triggerStyle = {
          zIndex: this.isActive ? "100" : undefined,
        };
      }
    },
  },
});
</script>

<style scoped>
.tooltip {
  background-color: #272727;
  padding: 8px;
  border-radius: 4px;
}
</style>
