<template lang="pug">
.w-full
  .hidden(ref="slot")
    slot
  table(:class="tableClasses")
    caption(v-if="$slots.caption")
      slot(name="caption")
    thead(v-if="columns.length")
      tr
        th(
          v-for="(column, index) in columns",
          :key="column.newKey + ':' + index + 'header'",
          :style="column.style",
          :class="thClasses(column)",
          @click.stop="sort(column, null, $event)"
        )
          template(v-if="column.hasHeaderSlot")
            UiSlotComponent(
              :component="column",
              scoped,
              name="header",
              tag="span",
              :props="{ column, index }"
            )
          template(v-else)
            .flex
              span {{ column.label }}
              template(v-if="column.sortable && currentSortColumn === column")
                template(v-if="isAsc")
                  img.ml-3.w-3(
                    src="../../assets/icons/arrow-up-solid.svg",
                    alt="arrow-up"
                  )
                template(v-else)
                  img.ml-3.w-3(
                    src="../../assets/icons/arrow-down-solid.svg",
                    alt="arrow-down"
                  )
    tbody
      template(v-if="loading")
        tr(v-for="row in rowsPerPage", :key="row")
          template(v-for="colindex in columns", :key="colindex")
            td
              .placeholder-item
      template(v-else)
        template(v-for="(row, index) in data", :key="index")
          tr(@click="selectRow(row)")
            UiSlotComponent(
              v-for="(column, colindex) in columns",
              :key="column.newKey + index + ':' + colindex",
              v-bind="column.tdAttrs && column.tdAttrs(row, column)",
              :component="column",
              scoped,
              name="default",
              tag="td",
              :class="tdClasses(row, column)",
              :data-label="column.label",
              :props="{ row, column, index, colindex }",
              @click="$emit('cell-click', row, column, index, colindex, $event)"
            )
        tr(v-if="data.length === 0")
          td.ui-table__td(
            :colspan="columns.length",
            @click="$emit('emptyTableRowClicked', $event)"
          )
            span
              slot(name="empty-table")
  template(v-if="paginated && !loading")
    UiTablePagination(
      v-bind="$attrs",
      :paginated="paginated",
      :total="total",
      :rows-per-page="rowsPerPage",
      :current="newCurrentPage",
      root-class="ui-table__pagination",
      @page-change="(pageNumber) => $emit('page-change', pageNumber)",
      @update:rows-per-page="(newRowsPerPage) => $emit('update:rowsPerPage', newRowsPerPage)",
      @update:current-page="newCurrentPage = $event"
    )
</template>

<script>
// TODO: add typescript support
// TODO: pre-style caption
import { defineComponent } from "vue";
import UiSlotComponent from "../utils/UiSlotComponent";
import UiTablePagination from "./UiTablePagination.vue";

// TODO: pre-style slots
// - e.g. the button is default, we could only add the text via slot
// - the caption can also be pre-styled
export default defineComponent({
  name: "UiTable",
  components: { UiSlotComponent, UiTablePagination },
  configField: "uiTable",
  provide() {
    return {
      uiTable: this,
    };
  },
  inheritAttrs: false,
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: false,
      default: true,
    },
    striped: {
      type: Boolean,
      default: true,
    },
    paginated: {
      type: Boolean,
      default: true,
    },
    /** Total number of table data (backend-pagination) */
    total: {
      type: [Number, String],
      default: 0,
    },
    currentPage: {
      type: [Number, String],
      default: 1,
    },
    rowsPerPage: {
      type: [Number, String],
      default: 10,
    },
    hoverable: {
      type: Boolean,
      default: true,
    },
    /** Sets the default sort column and order — e.g. ['first_name', 'desc']
     * TODO: fix annotation
     */
    defaultSort: {
      type: [String, Array],
      default: "",
    },
    /**
     * Sets the default sort column direction on the first click
     * @values asc, desc
     */
    defaultSortDirection: {
      type: String,
      default: "asc",
    },
  },
  emits: [
    "page-change",
    "cell-click",
    "click",
    "filters-change",
    "sort",
    "update:rowsPerPage",
    "emptyTableRowClicked",
  ],
  data() {
    return {
      defaultSlots: [],
      sequence: 1,
      currentSortColumn: {},
      isAsc: true,
      newCurrentPage: this.currentPage,
    };
  },
  computed: {
    columns: function () {
      return this.defaultSlots.filter(
        (vnode) => vnode && vnode.$data && vnode.$data.isTableColumn
      );
    },
    tableClasses() {
      return [
        { "ui-table": true },
        {
          "ui-table--striped": this.striped,
        },
        {
          "ui-table--hoverable": this.hoverable && !this.loading,
        },
      ];
    },
  },
  watch: {
    currentPage(newValue) {
      this.newCurrentPage = newValue;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initSort();
    });
  },
  methods: {
    thClasses(column) {
      return {
        ["ui-table__th--" + column.position]: true,
        ["ui-table__th--current-sort"]: column === this.currentSortColumn,
        ["ui-table__th--sortable"]: column.sortable,
      };
    },
    tdClasses(_, column) {
      return [
        {
          ["ui-table__td"]: true,
          ["ui-table__td--" + column.position]: true,
        },
      ];
    },
    /**
     * Row click listener.
     * @property {Object} row clicked row
     * @property {number} index index of clicked row
     */
    selectRow(row, index) {
      this.$emit("click", row, index);
    },
    /**
     * Sort the column.
     * Toggle current direction on column if it's sortable
     * and not just updating the prop.
     */
    sort(column, event = null) {
      if (!column || !column.sortable) return;
      this.isAsc = column === this.currentSortColumn ? !this.isAsc : true;
      this.$emit("sort", column.field, this.isAsc ? "asc" : "desc", event);
      this.currentSortColumn = column;
    },
    /**
     * Initial sorted column based on the default-sort prop.
     */
    initSort() {
      if (!this.defaultSort) return;
      let sortField = "";
      let sortDirection = this.defaultSortDirection;
      if (Array.isArray(this.defaultSort)) {
        sortField = this.defaultSort[0];
        if (this.defaultSort[1]) {
          sortDirection = this.defaultSort[1];
        }
      } else {
        sortField = this.defaultSort;
      }
      const sortColumn = this.columns.filter(
        (column) => column.field === sortField
      )[0];
      if (sortColumn) {
        this.isAsc = sortDirection.toLowerCase() !== "desc";
        this.currentSortColumn = sortColumn;
      }
    },
    _addColumn(column) {
      if (typeof window !== "undefined") {
        this.$nextTick(() => {
          this.defaultSlots.push(column);
        });
      }
    },
    _removeColumn(column) {
      this.$nextTick(() => {
        this.defaultSlots = this.defaultSlots.filter(
          (d) => d.newKey !== column.newKey
        );
      });
    },
    _nextSequence() {
      return this.sequence++;
    },
  },
});
</script>

<style scoped lang="postcss">
.ui-table {
  @apply table w-full border-separate;
  /* TODO Add to tailwind utilities (https://github.com/tailwindlabs/tailwindcss/issues/380#issuecomment-860887878) */
  border-spacing: 0;

  thead {
    th {
      @apply py-2 text-sm;
      /* TODO Define border button style (at least for colors) */
    }
    th:not(.ui-table__th--current-sort):not(.ui-table__th--sortable:hover) {
      /* TODO Define border button style (at least for colors) */
      border-bottom: 1px solid gray;
    }
    th:first-child {
      @apply pl-2.5;
    }
    th:last-child {
      @apply pr-2.5;
    }
  }

  tbody {
    td {
      @apply py-4 text-sm h-20;
      /* TODO Define border button style (at least for colors) */
    }
    td:first-child {
      @apply pl-2.5;
    }
    td:last-child {
      @apply pr-2.5;
    }
  }

  &--hoverable {
    tbody {
      tr:nth-child(2n + 2):hover,
      tr:hover {
        /* TODO Define hover color */
        backdrop-filter: brightness(400%);
      }
    }
  }

  &--striped {
    tbody {
      tr:nth-child(2n + 2):not(hover) {
        /* TODO Define striped background color */
        backdrop-filter: brightness(200%);
      }
    }
  }

  &__th {
    &--left {
      @apply text-left;
    }
    &--centered {
      @apply text-center;
    }
    &--right {
      @apply text-right;
    }
    &--current-sort {
      @apply cursor-pointer;
      /* TODO Define border button style (at least for colors) */
      border-bottom: 1px solid #ffa900;
    }
    &--sortable {
      @apply cursor-pointer;
      &:hover {
        /* TODO Define border button style (at least for colors) */
        border-bottom: 1px solid #ffa900;
      }
    }
  }

  &__td {
    &--left {
      @apply text-left;
    }
    &--centered {
      @apply text-center;
    }
    &--right {
      @apply text-right;
    }
  }
}
.placeholder-item {
  @apply rounded-md h-2.5	w-2/5	mt-2.5 relative overflow-hidden bg-hover shadow-md;
}
.placeholder-item::before {
  @apply block absolute	top-0	h-full;
  content: "";
  left: -150px;
  width: 150px;
  background: linear-gradient(
    to right,
    transparent 0%,
    #555 50%,
    transparent 100%
  );
  animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}
@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}
</style>
