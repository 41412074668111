<template lang="pug">
.wrapper
  img(:src="image.imageUri", v-if="!imageLoading")
  LoadingAnimation(v-if="imageLoading")
  a.close(@click.prevent="$emit('onDelete')")
</template>

<script lang="ts">
import { defineComponent, PropType, ref, toRefs, watch } from "vue";
import LoadingAnimation from "../../../ui/LoadingAnimation/LoadingAnimation.vue";

export default defineComponent({
  name: "EventSeriesImagePreview",
  components: { LoadingAnimation },
  props: {
    image: {
      type: Object as PropType<{ imageId?: string; imageUri?: string }>,
      required: true,
    },
  },
  emits: ["onDelete"],
  setup(props) {
    const { image } = toRefs(props);

    const imageLoading = ref(true);

    watch(image, (current, previous) => {
      // if nothing really changes we can skip
      if (current === previous) return;

      if (current.imageUri) {
        imageLoading.value = false;
      } else {
        imageLoading.value = true;
      }
    });

    if (image.value.imageUri) {
      imageLoading.value = false;
    }

    return {
      imageLoading,
    };
  },
  data: () => ({
    show: false,
  }),
});
</script>

<style scoped>
.wrapper {
  @apply border border-double border-background-bright;
  @apply h-24 w-40;
  @apply flex justify-center items-center;
  @apply relative;

  & > img {
    @apply h-full w-full;
    @apply object-contain;
  }

  .close {
    @apply w-6 h-6 bg-background-highlight z-10;
    @apply absolute -top-3 -right-3;
    @apply flex justify-center items-center;
    @apply rounded-xl;

    background-color: #555;

    &::after {
      content: "×";
    }

    &:hover {
      @apply cursor-pointer;
    }
  }
}
</style>
