<template lang="pug">
// TODO: EVENT SERIES NAMES MAX LINES TO 1. END LINE WITH ... IF EXCEEDS FIRST LINE
section.grid-container
  .logo-section
    button(@click="navigateTo('dashboard')")
      img(alt="Pingr Logo", src="../../../assets/logos/logo_white.webp")
  header.header
    .flex.justify-between.items-center.h-full
      .flex.items-center
        OrganizerSelect.ml-10(
          v-if="isEventManager",
          @select="onOrganizerSelect"
        )
        p.whitespace-nowrap(v-if="isEventManager") Eingetragene Events:
        .flex.flex-col.whitespace-nowrap.mr-6(v-if="isEventManager")
          p Diesen Monat: {{ eventStatisticsMonth === null ? "Loading" : eventStatisticsMonth }}
          p Dieses Jahr: {{ eventStatisticsYear === null ? "Loading" : eventStatisticsYear }}
        template(v-if="activeOrganizer && organizer")
          span.ml-2.whitespace-nowrap Deine Credits:
          span.whitespace-nowrap.text-primary.font-medium.ml-3 {{ formatNumberValue(organizer.OrganizerAccountBalance.balance) }}
          span.ml-6.whitespace-nowrap Credits verdienen:
          template(v-if="!isCreatingReferralLink")
            img.ml-3.header-icon.link-icon(
              src="../../../assets/nav/link-solid.svg"
            )
            p.text-primary(v-if="organizer.referralLink") {{ organizer.referralLink }}
            span.ml-6.whitespace-nowrap.text-primary.font-medium.underline.cursor-pointer(
              @click="organizer && organizer.referralLink ? copyReferralLink() : createReferralLinkForOrganizer()"
            ) {{ organizer && organizer.referralLink ? "Link kopieren" : "Link generieren" }}
            UiTooltip(
              :label="'Hilf uns User zu generieren. Generiere einen Link und teile ihn z.B. auf Social Media. Mit jedem User, der durch diesen Link generiert wird, erhältst du ' + organizer.referralCredits + ' Credits.'"
            )
              img.ml-3.header-icon(
                src="../../../assets/nav/circle-question-solid.svg"
              )
          template(v-else)
            LoadingAnimation.ml-3(size="md", :isPrimary="true")
      .flex.items-center.mr-6
        UiButton(
          v-if="!isEventManager && !isAdminManager && !isTagManager && !isOrganizerManager",
          style-ids="primary-border",
          @click="toast('Not yet implemented', { type: TYPE.ERROR })"
        )
          .flex
            p Get Pro
            img.ml-3(
              alt="Get Pro",
              src="../../../assets/icons/premium-crown.png"
            )
        UiButton.ml-5(
          style-ids="primary",
          :disabled="!activeOrganizer",
          @click="onCreateEvent"
        ) Create Event
  nav.side-menu
    .menu.main-menu
      a.menu-item.home(
        :class="{ active: currentNavigationPath === 'dashboard' }"
        @click="navigateTo('dashboard')",
      ) Dashboard
    .menu.event-menu(v-if="activeOrganizer")
      .event-menu-item(
        v-for="eventSeries in eventSeriesList",
        :key="eventSeries.id"
        :class="{ active: currentNavigationPath.startsWith('event-series/' + eventSeries.id) || currentNavigationPath === 'event-series/update/' + eventSeries.id }",
        @click="navigateTo('event-series/' + eventSeries.id)"
      )
        .flex.items-center.justify-start
          EventSeriesLogo.ml-6(:event-series-id="eventSeries.id")
          a.ml-8 {{ eventSeries.name }}
        .edit-button(@click="onEventSeriesEdit($event, eventSeries.id)") Edit
      a.menu-item.event-menu-last-item(
        :class="{ active: currentNavigationPath === 'event-series/create', 'event-menu-last-item-active': currentNavigationPath === 'event-series/create' }"
        @click="navigateTo('event-series/create')",
      ) Veranstaltungsreihe hinzufügen

    .menu.settings-menu
      a.menu-item.settings(
        :class="{ active: currentNavigationPath === 'settings' }"
        @click="toast('Not yet implemented', { type: TYPE.ERROR })",
      ) Einstellungen
      a.menu-item.help(
        :class="{ active: currentNavigationPath === 'help' }"
        @click="toast('Not yet implemented', { type: TYPE.ERROR })",
      ) Hilfe
      a.menu-item.feedback(
        :class="{ active: currentNavigationPath === 'feedback' }"
        @click="toast('Not yet implemented', { type: TYPE.ERROR })",
      ) Feedback
      a.menu-item.logout(@click="showModal = true") Exit
    template(
      v-if="isOrganizerManager || isAdminManager || isEventManager || isTagManager || isScraperManager"
    )
      .menu.admin-menu
        p.admin-panel-title Admin Area
        a.menu-item.all-organizer(
          v-if="isOrganizerManager",
          @click="navigateTo('organizers')",
          :class="{ active: currentNavigationPath === 'organizers' }"
        ) Alle Organizer
        a.menu-item.tags(
          v-if="isTagManager",
          @click="navigateTo('secondary-tags')",
          :class="{ active: currentNavigationPath === 'secondary-tags' }"
        ) Tags verwalten
        a.menu-item.users(
          v-if="isAdminManager",
          @click="navigateTo('admins')",
          :class="{ active: currentNavigationPath === 'admins' }"
        ) Admins verwalten
        a.menu-item.scraper(
          v-if="isScraperManager",
          @click="navigateTo('scraper')",
          :class="{ active: currentNavigationPath === 'scraper' }"
        ) Scraper
  main.content
    router-view
  UiModal(v-if="showModal", @close="showModal = false")
    template(#header)
      p DO YOU REALLY WANT TO LOGOUT?
    template(#footer)
      UiButton(@click="showModal = false", style-ids="subliminal") Cancel
      UiButton.ml-8(@click="logout", style-ids="highlight") Logout
  SelectOrCreateEventSeriesModal(
    v-if="showCreateEventModal",
    @close="showCreateEventModal = false",
    @actionClicked="showCreateEventModal = false"
  )
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { getAuth } from "firebase/auth";
import { useStore } from "vuex";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { TYPE, useToast } from "vue-toastification";
import {
  EventSeriesDataResult,
  OrganizerDataResult,
} from "../../../infrastructure/store/types";
import { authenticatedAreaFetchEventSeries } from "../../../infrastructure/store/states/queries";
import { storeKey } from "../../../infrastructure/store/store";
import UiModal from "../../../ui/Modal/UiModal.vue";
import OrganizerSelect from "../../../domain/Organizer/Select/OrganizerSelect.vue";
import UiButton from "../../../ui/Button/UiButton.vue";
import EventSeriesLogo from "../../../domain/EventSeries/Logo/EventSeriesLogo.vue";
import SelectOrCreateEventSeriesModal from "../../../domain/EventSeries/Modal/SelectOrCreateEventSeriesModal.vue";
import { useRouter } from "vue-router";
import {
  authenticatedArea_adminEventStatistics,
  authenticatedArea_createReferralLink,
  authenticatedArea_organizer,
} from "./queries";
import {
  AdminEventStatisticsResponse,
  CreateReferralLinkResponse,
} from "./types";
import { getStartOfMonth, getStartOfYear } from "../../../utils/dateFormatter";
import UiTooltip from "../../../ui/Tooltip/UiTooltip.vue";
import LoadingAnimation from "../../../ui/LoadingAnimation/LoadingAnimation.vue";
import { formatNumber } from "../../../utils/numberFormatter";

export default defineComponent({
  name: "AuthenticatedArea",
  components: {
    LoadingAnimation,
    UiTooltip,
    UiModal,
    EventSeriesLogo,
    OrganizerSelect,
    SelectOrCreateEventSeriesModal,
    UiButton,
  },
  setup() {
    const enabled = ref(false);
    const organizerId = ref("");
    const store = useStore(storeKey);
    const toast = useToast();
    const isOrganizerManager = ref(store.getters.isOrganizerManager);
    const isAdminManager = ref(store.getters.isAdminManager);
    const isTagManager = ref(store.getters.isTagManager);
    const isEventManager = ref(store.getters.isEventManager);
    const isScraperManager = ref(store.getters.isScraperManager);
    const activeOrganizer = ref(store.getters.organizer);
    const userId = ref(store.getters.user.id);
    const router = useRouter();

    if (!isEventManager.value && activeOrganizer.value) {
      organizerId.value = activeOrganizer.value;
      enabled.value = true;
    }

    const { result } = useQuery<EventSeriesDataResult>(
      authenticatedAreaFetchEventSeries,
      () => ({
        id: organizerId.value,
      }),
      () => ({
        enabled: enabled.value,
        fetchPolicy: "cache-and-network",
      })
    );

    // load event statistics
    const { result: eventStatistics } = useQuery<AdminEventStatisticsResponse>(
      authenticatedArea_adminEventStatistics,
      () => ({
        id: userId.value,
        startOfMonth: getStartOfMonth().toISOString(),
        startOfYear: getStartOfYear().toISOString(),
      }),
      () => ({
        enabled: isEventManager.value && userId.value,
        fetchPolicy: "cache-and-network",
      })
    );

    const { result: organizerResult } = useQuery<OrganizerDataResult>(
      authenticatedArea_organizer,
      () => ({
        id: organizerId.value,
      }),
      () => ({
        enabled: enabled.value,
        fetchPolicy: "cache-and-network",
      })
    );

    const { mutate: createReferralLink, loading: isCreatingReferralLink } =
      useMutation<CreateReferralLinkResponse>(
        authenticatedArea_createReferralLink,
        {
          refetchQueries: [
            {
              query: authenticatedArea_organizer,
              variables: {
                id: organizerId.value,
              },
            },
          ],
        }
      );

    const currentNavigationPath = computed(() => {
      return router.currentRoute.value.path.substring(1);
    });

    return {
      store: useStore(storeKey),
      toast,
      TYPE,
      isOrganizerManager,
      isAdminManager,
      isScraperManager,
      isEventManager,
      isTagManager,
      organizerId,
      activeOrganizer,
      enableEventSeriesQuery: enabled,
      eventSeriesList: computed(
        () => result.value?.Organizer_by_pk.EventSeries ?? []
      ),
      referralLink: computed(
        () => organizerResult.value?.Organizer_by_pk.referralLink ?? ""
      ),
      organizer: computed(() => organizerResult.value?.Organizer_by_pk ?? null),
      currentNavigationPath,
      eventStatisticsMonth: computed(
        () => eventStatistics.value?.month.aggregate.count ?? null
      ),
      eventStatisticsYear: computed(
        () => eventStatistics.value?.year.aggregate.count ?? null
      ),
      createReferralLink,
      isCreatingReferralLink,
    };
  },
  data: () => ({
    showModal: false,
    showCreateEventModal: false,
  }),
  methods: {
    createReferralLinkForOrganizer() {
      this.createReferralLink(
        { organizerId: this.organizerId },
        {
          refetchQueries: [
            {
              query: authenticatedArea_organizer,
              variables: { id: this.organizer!.id },
            },
          ],
        }
      ).catch(() => {
        this.toast("Fehler beim Generieren des Links.", { type: TYPE.ERROR });
      });
    },
    copyReferralLink() {
      navigator.clipboard.writeText(this.organizer!.referralLink);
      this.toast("Link wurde kopiert.", { type: TYPE.SUCCESS });
    },
    navigateTo(path: string) {
      // TODO: use named routes!!!
      // TODO Remove activeNavigationItem variable from store

      if (this.$route.path !== "/" + path) {
        // TODO: proper error handling
        this.$router
          .push({ path: "/" + path })
          .catch((err) => this.toast("Fehler beim Navigieren"));
      }
    },
    logout() {
      this.store.dispatch("logout");
      getAuth().signOut();
    },
    async onOrganizerSelect(selected: string) {
      // todo: error handling
      this.store.dispatch("setOrganizer", selected);
      this.activeOrganizer = selected;
      this.organizerId = selected;
      this.enableEventSeriesQuery = true;
    },
    onEventSeriesEdit(event: MouseEvent, eventSeriesId: string) {
      event.stopPropagation();
      this.navigateTo(`event-series/update/${eventSeriesId}`);
    },
    onCreateEvent() {
      this.showCreateEventModal = true;
    },
    formatNumberValue(value: number | string) {
      return formatNumber(value);
    },
  },
});
/*isSelectSeriesVisible = false;
  isLogoutModalVisible = false;

  async onOrganizerSelect(organizer: PublicOrganizer) {
    // await this.$store.dispatch("setOrganizer", organizer);
    console.log("fix Dashboard - onOrganizerSelect");
  }

  openCreateEventDialog(eventSeriesId: string) {
    console.log("fix Dashboard - openCreateEventDialog");
    /*this.$store.dispatch(
      "setSelectedNavigationItem",
      "eventseries/" + eventSeriesId
    );*
    this.$router
      .push({ path: "/newevent/" + eventSeriesId })
      .catch((err) => console.log(err));
    this.closeModal();
  }

  showLogoutModal() {
    this.isLogoutModalVisible = true;
  }

  closeLogoutModal() {
    this.isLogoutModalVisible = false;
  }

  showModal() {
    this.isSelectSeriesVisible = true;
  }

  closeModal() {
    this.isSelectSeriesVisible = false;
  }
}*/
</script>

<style scoped>
.edit-button {
  @apply text-xs mr-4 z-20;
}
.grid-container {
  @apply grid gap-x-0 gap-y-0;
  @apply bg-background-contrast h-full;
  @apply select-none;

  grid-template-columns: 320px 1fr;
  grid-template-rows: 60px 1fr;
  /*grid-column-gap: 0;
  grid-row-gap: 2px;*/
  /*height: 100vh;*/

  .logo-section {
    @apply flex justify-center items-center;
    @apply border-b border-border-default p-9;

    grid-area: 1 / 1 / 2 / 2;

    & > button {
      @apply w-3/5;
    }

    & > img {
      @apply w-full;
    }
  }

  .side-menu {
    @apply border-r border-border-default overflow-auto;

    grid-area: 2 / 1 / 3 / 2;

    .menu {
      @apply flex flex-col justify-start border-background-input border-b;

      /*padding-bottom: 15px;
      padding-top: 10px;
      border-bottom: 2px solid #1b1f25;*/
      &-item {
        @apply flex items-center;
        @apply font-medium text-sm py-4 pl-20 h-16;
        @apply bg-no-repeat bg-position-nav;
        transition: all 0.2s ease;
        background-size: 1.3rem;

        &:hover {
          @apply bg-hover cursor-pointer;
        }
      }

      .active {
        @apply text-primary;

        &:hover {
          @apply bg-background-contrast;
        }
      }

      .home.active {
        background-image: url("../../../assets/nav/house-solid-hover-active.svg");
      }
    }

    .admin-panel-title {
      @apply text-center rounded-sm self-center;
      @apply bg-background-highlight w-2/5 my-4 py-0.5;
    }

    .main-menu {
      .home {
        @apply bg-no-repeat bg-transparent bg-position-nav;
        background-image: url("../../../assets/nav/house-solid.svg");

        &:hover {
          /* todo: do not use different icons, just color them! */
          background-image: url("../../../assets/nav/house-solid-hover-active.svg");

          @apply bg-hover;
        }
      }
    }
    .event-menu {
      &-item {
        @apply flex flex-row justify-between items-center p-0 h-16;
        @apply cursor-pointer;

        &:hover {
          @apply bg-hover;
        }
      }

      &-last-item {
        @apply bg-position-nav bg-no-repeat;

        background-image: url("../../../assets/nav/plus-solid.svg");
        &:hover {
          /* todo: do not use different icons, just color them! */
          background-image: url("../../../assets/nav/plus-solid-hover-active.svg");
        }
        &-active {
          /* todo: do not use different icons, just color them! */
          background-image: url("../../../assets/nav/plus-solid-hover-active.svg");
        }
      }
    }

    .admin-menu {
      .all-organizer {
        background-image: url("../../../assets/nav/user-astronaut-solid.svg");

        &:hover {
          /* todo: do not use different icons, just color them! */
          background-image: url("../../../assets/nav/user-astronaut-solid-hover-active.svg");
        }
      }
      .all-organizer.active {
        background-image: url("../../../assets/nav/user-astronaut-solid-hover-active.svg");
      }
      .tags {
        background-image: url("../../../assets/nav/tag-solid.svg");
        &:hover {
          /* todo: do not use different icons, just color them! */
          background-image: url("../../../assets/nav/tag-solid-hover-active.svg");
        }
      }
      .tags.active {
        background-image: url("../../../assets/nav/tag-solid-hover-active.svg");
      }
    }

    .settings-menu {
      .settings {
        background-image: url("../../../assets/nav/sliders-solid.svg");

        &:hover {
          /* todo: do not use different icons, just color them! */
          background-image: url("../../../assets/nav/sliders-solid-hover-active.svg");
        }
      }

      .settings.active {
        background-image: url("../../../assets/nav/sliders-solid-hover-active.svg");
      }

      .help {
        background-image: url("../../../assets/nav/circle-question-solid.svg");

        &:hover {
          /* todo: do not use different icons, just color them! */
          background-image: url("../../../assets/nav/circle-question-solid-hover-active.svg");
        }
      }

      .help.active {
        background-image: url("../../../assets/nav/circle-question-solid-hover-active.svg");
      }

      .feedback {
        background-image: url("../../../assets/nav/message-solid.svg");

        &:hover {
          /* todo: do not use different icons, just color them! */
          background-image: url("../../../assets/nav/message-solid-hover-active.svg");
        }
      }

      .feedback.active {
        background-image: url("../../../assets/nav/message-solid-hover-active.svg");
      }

      .logout {
        background-image: url("../../../assets/nav/door-closed-solid.svg");
        &:hover {
          /* todo: do not use different icons, just color them! */
          background-image: url("../../../assets/nav/door-open-solid-hover-active.svg");
        }
      }
    }
  }
}

.header {
  @apply border-b border-border-default;

  p {
    @apply font-medium ml-4;
  }
}

.content {
  @apply bg-background-default w-full;

  grid-area: 2 / 2 / 3 / 3;
  overflow: auto;
}
.header-icon {
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
}
.link-icon {
  filter: invert(59%) sepia(76%) saturate(1226%) hue-rotate(1deg)
    brightness(105%) contrast(103%);
}

/* TODO: translate to tailwind after the select component has to be refactored */
/*.select-wrapper {
  position: relative;
}*/
</style>
