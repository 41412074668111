import gql from "graphql-tag";

export const validationSecondaryNameCreate = gql`
  query frontend_validation_secondaryNameExists($name: String!) {
    count: SecondaryTag_aggregate(
      where: { Translations: { translation: { _ilike: $name } } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const validationSecondaryNameUpdate = gql`
  query frontend_validation_secondaryNameExists($name: String!) {
    count: SecondaryTag_aggregate(
      where: {
        Translations: {
          _and: { translation: { _ilike: $name } }
          translation: { _neq: $name }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const validationAdminUserCreate = gql`
  query frontend_validation_secondaryNameExists($email: String!) {
    AdminUser_aggregate(where: { email: { _eq: $email } }) {
      aggregate {
        count
      }
    }
  }
`;

export const validationOrganizerUserCreate = gql`
  query frontend_validation_secondaryNameExists($email: String!) {
    OrganizerUser_aggregate(where: { email: { _eq: $email } }) {
      aggregate {
        count
      }
    }
  }
`;
